import {
  AppBar,
  Box,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import GenerateBreadcrumbs from "components/GenerateBreadcrumbs";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutApi } from "redux/features/Auth/loginSlice";
import {
  appBar,
  logoAdminName,
  mainNavbar,
  mainNavbarInnerBox,
} from "styles/AppContainer/NavbarStyle";
import Logo from "../../assets/svg/light/Logo.svg";
import dummyPic from "../../assets/images/profileAvatar.png";
import { useNavigate } from "react-router-dom";
import profileSettingIcon from "assets/svg/light/profile-setting-icon.svg";
import privacyIcon from "assets/svg/light/privacy-icon.svg";
import myteamIcon from "assets/svg/light/myteam-icon.svg";
import SelectField from "components/common/FormComponents/SelectField";
import MainLayoutContext from "contexts/MainLayoutContext";
import { getLocationsList } from "redux/features/Locations/locationsSlice";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .navbar-select-dropdown": {
      "& .MuiGrid-root.MuiGrid-container": {
        width: "auto !important",
        marginRight: "10px",
      },
    },
  },

  navSearch: {
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      backgroundColor: "#ffffff",
      border: "1px solid #E1E1E1",
      borderRadius: "15px",
      height: "30px",
    },
  },
  navIcons: {
    color: "#1C4588",
  },
  logOut: {
    "& .MuiList-root.MuiList-padding.MuiMenu-list": {
      paddingTop: "1px",
      paddingBottom: "10px",
    },
    "& p.MuiTypography-root": {
      fontSize: "14px",
      fontWeight: "400",
    },
    "& .MuiMenuItem-root": {
      paddingLeft: "25px",
      paddingRight: "25px",
      paddingTop: "10px",
      paddingBottom: "10px",
      "&:last-child": {
        paddingLeft: "35px",
      },
    },
  },
}));

const Navbar = () => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setActiveLocationId } = useContext(MainLayoutContext) || "";

  const [displayName, setDisplayName] = useState('');
  const [anchorElUser, setAnchorElUser] = useState(null);

  const { locations, isLocationsLoading } = useSelector((state) => state.locations);
  const { firstName, lastName, username, photo } = useSelector((state) => state.auth);

  useEffect(() => {
    let displayName = username;
    if (firstName) {
      displayName = `${firstName} ${lastName ?? ''}`
    }

    setDisplayName(displayName)
  }, [firstName, lastName, username])

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogoutAndClose = () => {
    dispatch(logoutApi());
    handleCloseUserMenu();
  };

  const settings = [
    {
      title: "Profile Settings",
      icon: profileSettingIcon,
      onClick: () => navigate("/settings?tab=1"),
    },
    {
      title: "My Team",
      icon: myteamIcon,
      onClick: () => navigate("/settings?tab=2"),
    },
    {
      title: "Privacy and Security",
      icon: privacyIcon,
      onClick: () => navigate("/settings?tab=3"),
    }
  ];

  useEffect(() => {
    dispatch(getLocationsList());
  }, [dispatch]);

  useEffect(() => {
    if (locations?.length > 0) setActiveLocationId(locations[0]?.value)
  }, [locations])


  function setLocation(_, v) {
    setActiveLocationId(v?.value)
  }

  return (
    <>
      <AppBar sx={appBar} className="navbar">
        <Box sx={mainNavbar(theme)} className={classes.root}>
          <Toolbar sx={{ paddingLeft: "10px !important" }}>
            <Box sx={mainNavbarInnerBox}>
              <Box sx={logoAdminName(theme)}>
                <img
                  src={Logo}
                  data-testid="nephrolyist-logo"
                  alt="logo"
                  style={{ marginRight: "30px", width: "42px", height: "42px" }}
                />
                <GenerateBreadcrumbs id={"bread-crumbs-id"} />
              </Box>
              <Box sx={{ flexGrow: 0, width:"20%", display: "flex", alignItems: "center" }}>
                <Grid container spacing={2}>
                  <SkeletonWrapper
                    condition={!isLocationsLoading}
                    props={[SkeletonPropsStyleGenerator("98%", "40px", "150px")]}
                  >
                    <SelectField
                      required
                      options={locations}
                      disableClearable={true}
                      value={locations?.[0]}
                      loading={isLocationsLoading}
                      onChange={setLocation}
                      name="locations"
                      label={""}
                      cols={12}
                      inputLabel="Locations"
                    />
                  </SkeletonWrapper>
                </Grid>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  className={classes.logOut}
                  open={Boolean(anchorElUser)}
                  data-testid="menu-logout-id"
                  onClick={handleCloseUserMenu}
                >
                  <Box sx={{ display: "flex", alignItems: "center", padding: "20px" }}>
                    <img
                      src={photo !== null ? photo : dummyPic}
                      alt=""
                      style={{ width: "20px", height: "20px", marginRight: "10px" }}
                    />
                    <Typography data-testid={`navbar-display-name`} variant="h2">{displayName}</Typography>
                  </Box>
                  <Divider variant="middle" />
                  {settings.map((setting, index) => (
                    <MenuItem key={index} onClick={() => setting.onClick()}>
                      <Box display="flex">
                        <img
                          src={setting.icon}
                          alt=""
                          style={{ marginRight: "5px" }}
                        />
                        <Typography
                          data-testid={`${setting.title}-button-id`}
                          textAlign="center"
                        >
                          {setting.title}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                  <Divider variant="middle" />
                  <MenuItem
                    data-testid={`Logout-button-id`}
                    onClick={() => handleLogoutAndClose()}
                  >
                    <span style={{ color: "#EF4444", fontWeight: "500" }}>
                      Log Out
                    </span>
                  </MenuItem>
                </Menu>

                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Box
                    sx={{
                      borderRadius: "100px",
                      width: "62px",
                      height: "63px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={dummyPic}
                      alt="Profile"
                      data-testid="patient-picture"
                      style={{
                        width: "46px",
                        height: "46px",
                        borderRadius: "100px",
                        backgroundSize: "contain",
                        objectFit: "cover",
                        objectPosition: "50% 0",
                      }}
                    />
                  </Box>
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </>
  );
};

export default Navbar;
