import { Box, Grid } from "@mui/material";
import { frequencyOptions } from "assets/static";
import Button from "components/common/Button/Button";
import InputField from "components/common/FormComponents/InputField";
import SelectField from "components/common/FormComponents/SelectField";
import CustomModal from "components/common/Modal/CustomModal";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  clearMedicinesListData,
  updatePrescriptionsByPatientId,
} from "redux/features/Medications/MedicinesSlice";
import {
  clearOrderMedicationsData,
  getMedicationById,
} from "redux/features/Medications/OrderMedicationsSlice";
import { OrderMedicationsSchema } from "validations/Modals/orderMedications.schema";
import PharmacyModal from "./PharmacyModal";
import { helper } from "utils/helper";
import Text from "components/common/Typography/Text";

const PlanMedicationModal = ({
  setSelectedPrescription,
  selectedPrescription,
  getMedicines,
  medicinesData,
  medicinesIsLoading,
  setShowMedicationModal,
  clearMedicationsStates,
  abortMedicationsCurrentRequest,
}) => {
  const dispatch = useDispatch();
  let { patientId } = useParams();
  const [isModalOpen, setIsOpenModal] = useState(false);
  const [prescName, setPrescName] = useState("")
  const { account_id } = useSelector((state) => state.auth);
  const { getSpecificPatientData } = useSelector((state) => state.specificPatient);
  const { pharmacyOptions, pharmacyIsLoading } = useSelector((state) => state.pharmacy);
  const { updateMedicineLoading, updateMedicineSuccess } = useSelector((state) => state.medicines);
  const { orderMedicationsList } = useSelector((state) => state.orderMedications);
  const { encounterByAppointmentData } = useSelector((state) => state.encounter);

  const formik = useFormik({
    initialValues: {
      patient_id: selectedPrescription?.id ? selectedPrescription?.patient_id : patientId,
      prescribed_on: selectedPrescription?.id ? selectedPrescription?.prescribed_on : new Date() ?? null,
      discontinued_on: selectedPrescription?.id ? selectedPrescription?.discontinued_on : null,
      dispensable_drug_id: selectedPrescription?.label ? selectedPrescription?.value : selectedPrescription?.id ? selectedPrescription?.dispensable_drug_id : "",
      drug_name: selectedPrescription?.label ? selectedPrescription?.label : selectedPrescription ? selectedPrescription?.drug_name : "",
      instructions: selectedPrescription?.id ? selectedPrescription?.instructions : "",
      dosage: selectedPrescription?.id ? selectedPrescription?.dosage : "",
      refills: selectedPrescription?.id ? selectedPrescription?.refills : "",
      quantity: selectedPrescription?.id ? selectedPrescription?.quantity : "",
      encounter_id: selectedPrescription?.id
        ? selectedPrescription?.encounter_id
        : encounterByAppointmentData?.id,
      pharmacy_id: selectedPrescription?.id ? selectedPrescription?.pharmacy_id : "",
      'sent_to_pharmacy': selectedPrescription?.id ? selectedPrescription?.sent_to_pharmacy : false,
      'order_error_message': selectedPrescription?.id ? selectedPrescription?.order_error_message : '',
      'account_id': selectedPrescription?.id ? selectedPrescription?.account_id : account_id,
      'dispense_unit_id': selectedPrescription?.id ? selectedPrescription?.dispense_unit_id : 4,
    },
    validationSchema: OrderMedicationsSchema,
    onSubmit: (values) => {
      if (values.drug_name === '') {
        toast.error("Please add Medication")
      } else {
        dispatch(
          /* This api is designed in such a way that previous meds should be
           passed in with the new one to add a new med.*/
          selectedPrescription?.id ? updatePrescriptionsByPatientId({ ...values, encounter_id: encounterByAppointmentData?.id }, patientId, selectedPrescription?.id) : updatePrescriptionsByPatientId({ ...values, encounter_id: encounterByAppointmentData?.id }, patientId)
        );
      }
    },
  });

  useEffect(() => {
    setPrescName(selectedPrescription?.id ? selectedPrescription?.drug_name : selectedPrescription?.label)
    formik.setFieldValue("dispensable_drug_id", selectedPrescription?.value ? selectedPrescription?.value : "")
    formik.setFieldValue("drug_name", selectedPrescription?.label ? selectedPrescription?.label : "")
    formik.setValues({
      patient_id: selectedPrescription?.id ? selectedPrescription?.patient_id : patientId,
      prescribed_on: selectedPrescription?.id ? selectedPrescription?.prescribed_on : new Date() ?? null,
      discontinued_on: selectedPrescription?.id ? selectedPrescription?.discontinued_on : null,
      dispensable_drug_id: selectedPrescription?.label ? selectedPrescription?.value : selectedPrescription?.id ? selectedPrescription?.dispensable_drug_id : "",
      drug_name: selectedPrescription?.label ? selectedPrescription?.label : selectedPrescription ? selectedPrescription?.drug_name : "",
      instructions: selectedPrescription?.id ? selectedPrescription?.instructions : "",
      dosage: selectedPrescription?.id ? selectedPrescription?.dosage : "",
      refills: selectedPrescription?.id ? selectedPrescription?.refills : "",
      quantity: selectedPrescription?.id ? selectedPrescription?.quantity : "",
      encounter_id: selectedPrescription?.id
        ? selectedPrescription?.encounter_id
        : encounterByAppointmentData?.id,
      pharmacy_id: selectedPrescription?.id ? selectedPrescription?.pharmacy_id : "",
      'sent_to_pharmacy': selectedPrescription?.id ? selectedPrescription?.sent_to_pharmacy : false,
      'order_error_message': selectedPrescription?.id ? selectedPrescription?.order_error_message : '',
      'account_id': selectedPrescription?.id ? selectedPrescription?.account_id : account_id,
      'dispense_unit_id': selectedPrescription?.id ? selectedPrescription?.dispense_unit_id : 4,
    });
  }, [selectedPrescription?.id]);

  useEffect(() => {
    selectedPrescription?.label && setPrescName(selectedPrescription?.label)
  }, [selectedPrescription?.label])

  useEffect(() => {
    selectedPrescription?.id !== null &&
      orderMedicationsList?.length &&
      dispatch(getMedicationById(selectedPrescription?.id));
  }, [selectedPrescription?.id]);

  useEffect(() => {
    if (updateMedicineSuccess) {
      toast.success(selectedPrescription?.id ? "Prescription Updated" : "Prescription Added");
      clearMedicationsStates && clearMedicationsStates()
      dispatch(clearOrderMedicationsData());
      setSelectedPrescription(null);
      setShowMedicationModal(false);
      setShowMedicationModal(false);
    }
  }, [updateMedicineSuccess]);

  function setMedicineName(e, v) {
    if (v?.label) {
      formik.setFieldValue("drug_name", v?.label)
      formik.setFieldValue("dispensable_drug_id", v?.value)
    }
    setPrescName(v?.label)
  }

  useEffect(() => {
    abortMedicationsCurrentRequest();
    return () => {
      abortMedicationsCurrentRequest();
    };
  }, [])

  return (
    <Box>
      <CustomModal
        fullWidth
        maxWidth="md"
        setOpen={setIsOpenModal}
        open={isModalOpen}
        title="Add Pharmacy"
      >
        <PharmacyModal setIsOpenModal={setIsOpenModal} />
      </CustomModal>
      <Box paddingX={2} paddingY={2} >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={2}
          sx={{ marginTop: "1px" }}
        >
          <SelectField
            required
            {...(selectedPrescription?.id ? { key: selectedPrescription?.drug_name } : {})}
            formik={formik}
            defaultValue={
              selectedPrescription?.id || selectedPrescription?.label ?
                {
                  label: selectedPrescription?.label ?? selectedPrescription?.drug_name,
                  value: selectedPrescription?.value ?? selectedPrescription?.dispensable_drug_id,
                } : {}
            }
            label="Medication"
            onClear={() =>
              medicinesData?.length
                ? (dispatch(clearMedicinesListData()))
                : null
            }
            onSearch={getMedicines}
            extendedSearchOptions={["description"]}
            showMoreInLabel={{ show: true, value: "description" }}
            onChange={setMedicineName}
            loading={medicinesIsLoading}
            options={medicinesData}
            cols={6}
            placeholder="Search for a Medication"
            name={"dispensable_drug_id"}
          />
          <InputField
            required
            formik={formik}
            label="Quantity"
            placeholder="Enter Quantity"
            name={"quantity"}
          />
          <InputField
            formik={formik}
            name={"instructions"}
            label="Additional Instructions"
            placeholder="Add Instructions Here"
            xs={8}
          />
          <InputField
            required
            formik={formik}
            label="Refills"
            placeholder="Enter Refills"
            name={"refills"}
          />
        </Grid>
        <Grid container spacing={2} >
          <SelectField
            cols={6}
            {...(selectedPrescription?.id ? { key: selectedPrescription?.pharmacy_id } : {})}
            name={"pharmacy_id"}
            defaultValue={
              selectedPrescription?.id && pharmacyOptions
                ? pharmacyOptions?.find(
                  ({ value }) => value === selectedPrescription?.pharmacy_id
                )
                : {}
            }
            required
            options={pharmacyOptions}
            isLoading={pharmacyIsLoading}
            formik={formik}
            label={"Search Pharmacy"}
            placeholder="Search for a Pharmacy"
            sx={{ flex: 1 }}
          />

          <Grid item xs={6}>
            <Button
              onClick={() => setIsOpenModal(true)}
              text="+"
              borderRadius="5px"
              backgroundColor="#315FFF"
              color="#fff"
              fontWeight={400}
              fontSize={"19px"}
              btnColor="#fff"
              sx={{ height: "37px", marginTop: "30px", minWidth: "37px", }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Button
          text={"Cancel"}
          onClick={() => {
            setSelectedPrescription && setSelectedPrescription(null);
            setShowMedicationModal && setShowMedicationModal(false);
            dispatch(clearOrderMedicationsData());
          }}
          sx={{ marginRight: "10px", width: "150px" }}
          border={"1px solid #272A40"}
          backgroundColor="#FFFFFF"
          borderRadius="10px"
          color="#272A40"
          btnColor="#272A40"
        />
        <Button
          isLoading={updateMedicineLoading}
          onClick={() => formik.submitForm()}
          text={"Save Medication"}
          sx={{ width: "150px" }}
          backgroundColor="#315FFF"
          borderRadius="10px"
          color="#fff"
          btnColor="#fff"
        />
      </Box>
    </Box>
  );
};

export default PlanMedicationModal;
