import { Box, useTheme } from "@mui/material";
import Button from "components/common/Button/Button";
import Text from "components/common/Typography/Text";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { useNavigate } from "react-router-dom";
import { flexColumn, flexRowCenterCenter, justifyContentCenter } from "styles";
import {
  cardButtonStyling,
  cardDateTimeStatusStyling,
  cardNameDiseaseImageBoxStyling,
  cardReasonWrapperStyling,
  cardResponsiveStyling,
  cardVisitReasonBoxStyling,
} from "styles/Dashboard/SchedulePatientsStyle";
import circleleftarrow from "../../../assets/svg/light/checkout-icon.svg";
import correct from "../../../assets/svg/colored/correct.svg";
import correctBox from "../../../assets/svg/colored/correctBox.svg";
import wrong from "../../../assets/svg/colored/wrong.svg";
import PHQformModal from "../Modal/PHQformModal";
import { checkNullOrUndefinedString } from "utils/helper";
import Avatar from "@mui/material/Avatar";
import { useSwiper } from "swiper/react";

const Cards = ({
  id,
  time,
  status,
  img,
  name,
  disease,
  description,
  lastVisitDate,
  isActive,
  patientId,
  isReviewLater,
  appointmentId,
  index,
  dob = "",
  openPastVisit
}) => {
  let theme = useTheme();
  let swiper = useSwiper();
  const navigate = useNavigate();
  const [statusImg, setStatusImg] = useState("");
  const [_, setFontSize] = useState(16);

  useEffect(() => {
    const handleResize = () => {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      const newFontSize = vw / 20;
      setFontSize(newFontSize);
    };

    // Initial font size calculation
    handleResize();

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // handling checkin-status images
  useEffect(() => {
    // checking all the status in lowercase
    if (status.toLowerCase() == "checked out") {
      setStatusImg(circleleftarrow);
    } else if (status.toLowerCase() == "cancelled") {
      setStatusImg(wrong);
    } else if (status.toLowerCase() == "scheduled") {
      setStatusImg(correctBox);
    } else {
      setStatusImg(correct);
    }
  }, []);

  return (
    <Box
      id={id}
      className="main-card-body"
      sx={{
        ...cardResponsiveStyling(status, theme, isActive),
      }}
      onClick={() => { swiper.slideTo(index) }}
    >
      <Box
        sx={{
          flex: 1,
          [`@media  (min-height:500px) and (max-height:630px)`]: {
            flex: "unset",
          },
        }}
      >
        <Box sx={cardDateTimeStatusStyling}>
          <Text classes={"card-time"} variant="h3">
            <span data-testid="card-time">{moment(time).format("H:mm")}</span>
          </Text>
          <Box sx={{ ...flexRowCenterCenter }}>
            <img
              src={statusImg}
              data-testid="checkedin-status-img"
              className="status-image"
              alt=""
              style={{ zIndex: 1 }}
            />
            <Text classes={"checkin-status"} variant="h3" marginLeft="10px">
              <span
                data-testid="checkedin-status"
                style={{ textTransform: "capitalize" }}
              >
                {status}
              </span>
            </Text>
          </Box>
        </Box>
        <Box
          sx={cardNameDiseaseImageBoxStyling}
          className="patient-profile-box"
        >
          <Box style={{ ...flexColumn, ...justifyContentCenter }}>
            <Text
              variant="h1"
              classes="patient-name"
              color={`${isActive ? "oceanBlue" : "black"}`}
              margin="0px"
            >
              <span data-testid={"card-name"}>
                {checkNullOrUndefinedString(name)}
              </span>
            </Text>
            <Box sx={{ marginTop: "5px", width: "50%", display: "flex", alignItems: "center" }}>
              <Text
                classes={"visit-heading"}
                variant="formLabel"
                padding="none"
              >
                <i>Age:</i>
              </Text>
              <Text
                classes={"visit-date"}
                variant="h3"
                padding="none"
                fontWeight="700"
                color="black"
                sx={{ marginLeft: "5px" }}
              >
                {dob}
              </Text>
            </Box>

            <Text
              classes={"card-disease"}
              variant="h2"
              margin="5px 0px 0px 0px"
            >
              <span data-testid={"card-disease"}>{disease}</span>
            </Text>
          </Box>
          <Avatar variant="rounded" data-testid="patient-img"></Avatar>
        </Box>
        <Box sx={cardReasonWrapperStyling}>
          <Box sx={{ flex: "2" }}>
            <Text classes={"reason-heading"} variant="formLabel">
              <i>Reason for the visit:</i>
            </Text>
            <Box className="visit-reason-box" sx={cardVisitReasonBoxStyling}>
              <Text
                classes={"visit-reason"}
                variant="h3"
                color="black"
                letterSpacing="0.5px"
              >
                <span data-testid="card-reason-for-visit">{description}</span>
              </Text>
            </Box>
          </Box>
          <Box sx={{ height: { lg: "60px", md: "40px" }, display: "flex" }}>
            <Box
              onClick={() => openPastVisit(patientId)}
              sx={{ width: "50%" }}
            >
              <Text
                classes={"visit-heading"}
                variant="formLabel"
                padding="none"
              >
                <i>Last Visit:</i>
              </Text>
              <Text
                classes={"visit-date"}
                variant="h3"
                padding="none"
                fontWeight="700"
                color="black"
              >
                <span data-testid="card-date-visit">
                  {lastVisitDate
                    ? moment(lastVisitDate).format("YYYY/MM/DD")
                    : "N/A"}
                </span>
              </Text>
            </Box>
          </Box>
        </Box>
        <Box className="button-box" sx={{ height: "10%" }}>
          <Button
            id="start-appointment"
            backgroundColor={
              isActive
                ? theme?.palette?.common?.blue
                : theme?.palette?.common?.black
            }
            btnColor={theme?.palette?.common?.white}
            sx={cardButtonStyling(theme, isActive)}
            onClick={() => {
              localStorage.setItem("appointment_img", img);
              localStorage.setItem(
                "appointment_time",
                moment(time).format("H:mm")
              );
              navigate(
                isReviewLater &&
                  status?.toLowerCase() !== "Checked Out"?.toLowerCase()
                  ? `/schedule/patient/${patientId}/appointment/${appointmentId}?review_later=${true}`
                  : `/schedule/patient/${patientId}/appointment/${appointmentId}`
              );
            }}
            text={"View Patient Profile"}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default memo(Cards);
