import {
  FormControl,
  Grid,
  InputAdornment,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { useEffect, useState } from "react";
import Text from "../Typography/Text";

const ErrorTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    "& .MuiTooltip-arrow": {
      color: theme.palette.error.light,
    },
  },
}));

const InputField = ({
  // Cannot provide both value and defaultValue
  // Set this to make this a controlled component
  value,
  // Set this to make this an uncontrolled component
  defaultValue,
  // The label for the input field. This will be displayed above the input field.
  label,
  // The label for the input field. This will be displayed inside the input field (and will float when the input field is focused).
  inputLabel,
  // The name of the input field. This will be used to identify the input field in the form.
  name,
  min,
  max,
  disabled = false,
  iconDetails = { allowIcon: false, iconName: null },
  positionLeft = false,
  positionRight = false,
  onKeyDown,
  type,
  onChange,
  cols,
  xs = 6,
  sm = 6,
  md = 6,
  lg = 6,
  flex = 0,
  formik,
  showError = false,
  errorMessage,
  autoFocus = false,
  onFocus,
  required = false,
  placeholder,
  fontWeight = "400",
  multiline,
  passwordVisible,
  setPasswordVisible,
  border = "1px solid #E1E1E1",
  borderRadius = "10px",
  className,
  labelVariant = "bodyS",
  rows,
  ...props
}) => {
  let { allowIcon, iconName } = iconDetails;
  const togglePaswordIcon = () => {
    setPasswordVisible &&
      setPasswordVisible((prevPasswordVisible) => !prevPasswordVisible);
  };

  const [fieldError, setFieldError] = useState(
    formik?.errors[name] || errorMessage
  );
  const [fieldTouched, setFieldTouched] = useState(
    formik?.touched[name] || showError
  );

  // Inner value is used to store the value of the input field when no onChange is provided
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  useEffect(() => {
    // Function to get nested values with dot notation or index lookup EX: a.b[0].c or a.b[2][name]
    const getNestedValue = (obj, path) => {
      if (!obj || !path) return null;
      return path.split(/[\.\[\]]/).filter(x => !!x).reduce((acc, part) => {
        if (acc && part) {
          if (part.match(/\d+/)) {
            return acc[parseInt(part)];
          } else {
            return acc[part];
          }
        }
        return null;
      }, obj);
    };

    setFieldError(getNestedValue(formik?.errors, name) || errorMessage);
    setFieldTouched(getNestedValue(formik?.touched, name) || showError);
  }, [formik, showError]);

  const handleChange = (event) => {
    let { value } = event.target;

    if (formik) {
      formik.handleChange(event);
    } else if (onChange) {
      onChange(event);
    } else {
      setInnerValue(value);
    }

    if (props.maxLength && value.length > props.maxLength) {
      return;
    }
  };

  const getSpacing = () => {
    if (cols) {
      return { xs: cols, sm: cols, md: cols, lg: cols };
    } else {
      return { xs, sm, md, lg };
    }
  }

  const handleKeyUp = (event) => {
    if (event.ctrlKey && event.key === "Backspace") {
      // Handle Ctrl + Backspace key combination
      if (onChange) {
        onChange({
          target: {
            name: name,
            value: event.target.value,
          },
        });
      }
    }
  };

  return (
    <Grid item {...getSpacing()} flex={flex} >
      {label && (
        <Text variant={labelVariant} fontWeight={fontWeight} marginY={1}>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </Text>
      )}
      <ErrorTooltip arrow title={fieldTouched && fieldError ? fieldError : ''} placement="right">
        <FormControl fullWidth variant="outlined" className={className}>
          <TextField
            id={`${name}`}
            name={name}
            autoFocus={autoFocus}
            defaultValue={defaultValue}
            value={innerValue}
            type={type}
            disabled={disabled}
            multiline={multiline}
            label={inputLabel}
            rows={rows}
            data-testid={name}
            onKeyDown={onKeyDown}
            onKeyUp={handleKeyUp}
            onFocus={onFocus}
            size="small"
            color={fieldError && fieldTouched ? 'error' : 'info'}
            sx={{ border: border, borderRadius: borderRadius }}
            InputProps={{
              autoComplete: "off",
              startAdornment: allowIcon && positionLeft && (
                <InputAdornment
                  position="start"
                  sx={{
                    cursor: "pointer",
                    paddingX: "1px",
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}
                  onClick={togglePaswordIcon}
                >
                  <img style={{ marginRight: "3px" }} src={iconName} data-testid={`input-field-left-icon`} />
                </InputAdornment>
              ),
              endAdornment: allowIcon && positionRight && (
                <InputAdornment
                  position="end"
                  sx={{
                    cursor: "pointer",
                    marginLeft: "0px",
                    marginRight: "0px",
                    marginTop: "-50px",
                  }}
                >
                  <img data-testid={`input-field-right-icon`} src={iconName} />
                </InputAdornment>
              ),
            }}
            placeholder={placeholder}
            inputProps={{
              'data-testid': `input-${name}`,
              maxLength: props.maxLength,
              minLength: props.minLength,
              min: min,
              max: max,
            }}
            {...(type === "date" ? { InputLabelProps: { shrink: true } } : {})}
            onChange={handleChange}
            {...formik?.getFieldProps(name)}
          />
        </FormControl>
      </ErrorTooltip>
    </Grid>
  );
};

export default InputField;
