import React, { useRef, useState } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import Button from "components/common/Button/Button";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SelectField from "components/common/FormComponents/SelectField";
import { clearMedicinesListData, getMedicinesList } from "redux/features/Medications/MedicinesSlice";
import { debounce } from "utils/debouncer";
import { useParams } from "react-router-dom";
import Text from "components/common/Typography/Text";
import { helper } from "utils/helper";
import {
  addPatientMedications,
  clearAddUpdateDeleteMedicationsData,
  getPatientMedications,
} from "redux/features/Medications/MedicationsSlice";

const AddMedicatonModal = ({ setIsOpenModal, clearMedicationsStates }) => {

  const theme = useTheme();
  const dispatch = useDispatch();
  let { patientId } = useParams();

  const medicationsAbortControllerRef = useRef(null);

  const [medications, setMedications] = useState({});

  const { account_id } = useSelector((state) => state.auth);
  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient
  );
  const { medicinesData, medicinesIsLoading } = useSelector(
    (state) => state.medicines
  );
  const { addingMedicationInProgress, addedMedicationSuccessfully } =
    useSelector((state) => state.medications);

    function abortMedicationsCurrentRequest() {
      if (medicationsAbortControllerRef.current) {
        medicationsAbortControllerRef.current.abort();
      }
    }

  const getMedicines = debounce((value) => {
    if (value?.length > 0) {

      // Cancel previous request if it exists
      abortMedicationsCurrentRequest();
      
      // Create a new AbortController
      const medicationsAbortController = new AbortController();
      medicationsAbortControllerRef.current = medicationsAbortController;

      dispatch(getMedicinesList(value, patientId, medicationsAbortController.signal));
    }
  }, 400);

  const onMedicationSelect = (e, v) => {
    /* This api medications is designed in such a way that previous meds should be
        passed as single med , api is not accepting the multiple meds*/
    if (v?.label !== undefined) {
      const payload = {
        patient_id: patientId,
        drug_name: v?.label,
        instructions: "",
        duplicate_id: null,
        start_date: null,
        end_date: null,
        account_id: account_id,
        start_date: new Date(),
      };
      setMedications(payload);
    }
  };

  const onSaveMedication = () => {
    if (medications?.drug_name) {
      dispatch(addPatientMedications(patientId, medications));
    } else {
      toast.error("Please select a medication to save");
    }
  };
  useEffect(() => {
    if (addedMedicationSuccessfully) {
      clearMedicationsStates();
      dispatch(getPatientMedications(patientId));
      dispatch(clearAddUpdateDeleteMedicationsData());
      toast.success("Medication Added");
      setIsOpenModal(false);
    }
  }, [addedMedicationSuccessfully]);

  useEffect(() => {
    return () => {
      abortMedicationsCurrentRequest();
    };
  }, [])

  return (
    <Box>
      <Box padding={2}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={2}
          sx={{ marginTop: "1px" }}
        >
          <SelectField
            label={"Search Medications"}
            cols={12}
            name="medicines"
            placeholder="Search"
            border="1px solid #000"
            onSearch={getMedicines}
            onChange={onMedicationSelect}
            extendedSearchOptions={["description"]}
            showMoreInLabel={{ show: true, value: "description" }}
            loading={medicinesIsLoading}
            options={medicinesData}
            onClear={() =>
              medicinesData?.length
                ? (dispatch(clearMedicinesListData()))
                : null
            }
          />
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Button
          text={"Back"}
          onClick={() => {
            setIsOpenModal && setIsOpenModal(false);
          }}
          sx={{ marginRight: "10px", width: "117px" }}
          border={"1px solid #272A40"}
          borderRadius="10px"
          backgroundColor="#FFFFFF"
          color="#272A40"
          btnColor="#272A40"
        />
        <Button
          isLoading={addingMedicationInProgress}
          onClick={() => onSaveMedication()}
          sx={{ width: "148px" }}
          text={"Save Medication"}
          borderRadius="10px"
          backgroundColor={theme.palette.common.blue}
          color="#fffs"
          btnColor="#fff"
        />
      </Box>
    </Box>
  );
};

export default AddMedicatonModal;
