export let healthsummaryMain = () => {
  return {
    borderRadius: "24px",
    backgroundColor: "#ffffff",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    position: "relative",
  };
};

export let riskAssesmentHeadingStyle = {
  display: "flex",
  flexWrap: "wrap",
  maxHeight: "150px",
};

export let healthSummaryFieldsBoxStyle = (healthSummarySuccess) => {
  return {
    display: "flex",
    alignItems: "center",
    marginBottom: `${healthSummarySuccess ? "20px" : "0px"}`,
  };
};
