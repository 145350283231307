import { useQuery } from "@tanstack/react-query";
import { searchPerscriptions } from "apiClients/perscriptions";
import { useEffect, useState } from "react";
import { debounce, DEBOUNCE_DELAY } from "utils/debouncer";
import SelectField from "./SelectField";



export const MedicationSelectInput = ({ patientId, name, value }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [innerLoading, setInnerLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const search = debounce((term) => {
        setSearchTerm(term);
    }, DEBOUNCE_DELAY)

    const { data: { results } = {}, isLoading } = useQuery({
        queryKey: ["medications", patientId, searchTerm],
        queryFn: async ({ signal }) => await searchPerscriptions(patientId, searchTerm, signal),
        enabled: !!searchTerm && searchTerm.length > 2,
    })

    useEffect(() => {
        // Let the event loop finish before setting the inner loading to false.
        setInnerLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        if (results) {
            setSearchResults(mapResults(results));
        }
    }, [results]);

    const startSearch = (value) => {
        // If the value is less than 2 characters, we don't want to search so we don't need to show the loading spinner.
        setInnerLoading(value.length > 2);
        setSearchResults([]);
    }

    const mapResults = (values) => (
        (values ?? []).map((s, idx) => ({
            key: s.dispensable_drug_id + idx,
            label: s.drug_name,
            value: s.dispensable_drug_id,
            description: s.purpose,
        })));

    return (
        <SelectField
            value={value}
            inputLabel="Medication"
            cols={6}
            name={name}
            placeholder="Search"
            onSearch={(value) => {
                startSearch(value);
                search(value);
            }}
            enableFiltering={false}
            loading={innerLoading}
            options={searchResults}
        />
    );
}