import { Box } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Text from "components/common/Typography/Text";
import SkeletonWrapper from "../Skeleton/SkeletonWrapper";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";

const TimerBox = ({ getSpecificPatientSuccess }) => {
  const [viewWidth, setViewWidth] = useState(window.innerWidth);
  const [viewHeight, setViewHeight] = useState(window.innerHeight);
  const { pathname } = useLocation();
  const { patientId: currentPatientId } = useParams();
  const [storedPatientId, setStoredPatientId] = useState(
    localStorage.getItem("patientId")
  );
  const [timerState, setTimerState] = useState(() => {
    const storedState = localStorage.getItem("timerState");
    const lastTime = moment(JSON.parse(localStorage.getItem("lastVisitTime")));
    // Current time
    const currentTime = moment();
    const diffInSeconds = Math.abs(currentTime.diff(lastTime, "seconds"));

    if (storedState) {
      const parsedState = JSON.parse(storedState);
      // Adjust seconds based on counterclockwise and forwardTimerActive
      let adjustedSeconds = parsedState.seconds;
      if (parsedState.forwardTimerActive) {
        adjustedSeconds += diffInSeconds; // Add seconds
      } else {
        adjustedSeconds -= diffInSeconds; // Subtract seconds
      }
      // Ensure adjustedSeconds is non-negative
      //if adjusted Seconds are in the negative then add adjusted seconds by

      adjustedSeconds = Math.max(0, adjustedSeconds);

      return {
        ...parsedState,
        seconds:
          adjustedSeconds < 0 ? Math.abs(adjustedSeconds) : adjustedSeconds,
        ...(adjustedSeconds < 0 ? { forwardTimerActive: true } : {}),
      };
    } else {
      // Default state if no stored state found
      return {
        seconds: 15 * 60,
        counterclockwise: false,
        backgroundColor: "#D9FFE5",
        text: "Visit ends in:",
        forwardTimerActive: false,
        timeSpentOnOtherPages: {
          clockwise: 0,
          forward: 0,
        },
      };
    }
  });

  useEffect(() => {
    // Check if the patientId has changed
    if (
      localStorage.getItem("patientId") &&
      currentPatientId &&
      localStorage.getItem("patientId") !== currentPatientId
    ) {
      // Reset the timer if the patientId has changed
      setStoredPatientId(currentPatientId);
      setTimerState({
        seconds: 15 * 60,
        counterclockwise: false,
        backgroundColor: "#D9FFE5",
        text: "Visit ends in:",
        forwardTimerActive: false,
        timeSpentOnOtherPages: {
          clockwise: 0,
          forward: 0,
        },
      });

      // Store the current patientId in localStorage
      currentPatientId && localStorage.setItem("patientId", currentPatientId);
    }
  }, [currentPatientId]);

  useEffect(() => {
    // Store timer state in localStorage and if path is scd
    if (/^\/schedule\/patient\/\d+\/appointment\/\d+$/i.test(pathname)) {
      localStorage.setItem("timerState", JSON.stringify(timerState));
      localStorage.setItem("lastVisitTime", JSON.stringify(new Date()));
    }
  }, [timerState]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentPatientId) {
        if (timerState.seconds > 0 && !timerState.forwardTimerActive) {
          setTimerState((prevState) => ({
            ...prevState,
            seconds: prevState.seconds - 1,
          }));
        } else {
          setTimerState((prevState) => ({
            ...prevState,
            counterclockwise: false,
            backgroundColor: "#f5c2c2",
            text: "Next Patient Waiting",
            forwardTimerActive: true,
          }));
          clearInterval(intervalId);
        }
      } else {
        // Increment time spent on other pages
        setTimerState((prevState) => ({
          ...prevState,
          timeSpentOnOtherPages: {
            ...prevState.timeSpentOnOtherPages,
            clockwise: prevState.timeSpentOnOtherPages.clockwise + 1,
          },
        }));
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timerState.seconds, timerState.forwardTimerActive, currentPatientId]);

  useEffect(() => {
    if (currentPatientId) {
      if (timerState.forwardTimerActive) {
        const forwardTimerId = setInterval(() => {
          setTimerState((prevState) => ({
            ...prevState,
            seconds: prevState.seconds + 1,
          }));
          // Customize the logic here for any additional actions during the forward timer
        }, 1000);

        return () => clearInterval(forwardTimerId);
      }
    } else {
      // Increment time spent on other pages
      setTimerState((prevState) => ({
        ...prevState,
        timeSpentOnOtherPages: {
          ...prevState.timeSpentOnOtherPages,
          forward: prevState.timeSpentOnOtherPages.forward + 1,
        },
      }));
    }
  }, [timerState.forwardTimerActive, currentPatientId]);

  useEffect(() => {
    // Store the current patientId in localStorage
    currentPatientId && localStorage.setItem("patientId", currentPatientId);
  }, [currentPatientId]);

  useEffect(() => {
    const handleResize = () => {
      setViewWidth(window.innerWidth);
      setViewHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const remainingSeconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  return (
    <SkeletonWrapper
      id={"patient-detail-header-name"}
      condition={getSpecificPatientSuccess}
      sx={{ margin: "20px" }}
      props={[SkeletonPropsStyleGenerator("100px", "45px")]}
    >
      <Box
        sx={{
          backgroundColor: timerState.backgroundColor ?? "#D9FFE5",
          borderRadius: "30px",
          padding: "5px 8px",
          marginLeft: "10px",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <AccessTimeIcon
            sx={{
              marginRight: "5px",
              width: "16px",
              height: "16px",
            }}
            data-testid={"scd-header-access-time-icon"}
          />{" "}
          <Text variant="bodyXs">
            <div data-testid="timer-box" className="time-box">
              <>
                {/^\/schedule\/patient\/\d+\/appointment\/\d+$/i.test(
                  pathname
                ) ? (
                  <div
                    data-testid="scd-timer"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "3px",
                      borderRadius: "10px",
                      minWidth: "auto",
                    }}
                  >
                    <div>
                      {timerState.forwardTimerActive
                        ? "Next Patient Waiting "
                        : "Visit ends in: "}
                      &nbsp;
                    </div>
                    <div>
                      {timerState.counterclockwise
                        ? formatTime(15 * 60 + timerState.seconds)
                        : formatTime(
                            timerState.forwardTimerActive
                              ? timerState.seconds
                              : timerState.seconds
                          )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            </div>
          </Text>
        </Box>
      </Box>
    </SkeletonWrapper>
  );
};

export default TimerBox;
