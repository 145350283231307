import { Box, Divider } from "@mui/material";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getHealthSummaryList } from "redux/features/HealthSummary/HealthSummarySlice";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import {
  healthSummaryFieldsBoxStyle,
} from "styles/SCD/healthSummaryStyle";
import { getSpecificAppointmentDetails } from "redux/features/Appointments/AppointmentsSlice";
import Editor from "components/Editor/Editor";
import { submitFeedbackAPI } from "redux/features/Feedback/feedbackSlice";
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import InputField from "components/common/FormComponents/InputField";
import CustomModal from "components/common/Modal/CustomModal";
import Button from "components/common/Button/Button";

const HealthSummary = ({ smartSummaryfeedback, setsmartSummaryFeedback }) => {
  let dispatch = useDispatch();
  let { patientId, appointmentId } = useParams();
  const { healthSummarySuccess } = useSelector(
    (state) => state.healthSummary
  );
  const { userId } = useSelector(state => state.auth)
  const { encounterByAppointmentData } = useSelector((state) => state.encounter);
  const { appointmentData } = useSelector((state) => state.specificAppointment);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackNote, setFeedbackNote] = useState("")

  /*
  Polling is implemented to get the appointment smart summary text every 30 seconds
  until smart summary is retrieved. it is implemented becuase sometimes smart summary 
   text is not available at the time of appointment retrieval
  */
  useEffect(() => {
    let timeOut;
    if (!appointmentData?.smart_summary_text) {
      timeOut = setTimeout(() => {
        dispatch(getSpecificAppointmentDetails(appointmentId));
      }, 30000);
    }
    return () => clearTimeout(timeOut)
  }, [appointmentData])

  useEffect(() => {
    dispatch(getHealthSummaryList(patientId, appointmentId));
  }, [patientId]);

  const handleFeedbackStatus = (feedback) => {
    setFeedbackNote("")
    setShowFeedbackModal(false)
    setsmartSummaryFeedback(feedback)
    const payload = {
      "feedback": feedbackNote ?? "",
      "item_for_feedback": "smart.summary",
      "thumbs_up": feedback,
      "user_id": userId,
      "item_id": encounterByAppointmentData?.id ?? ""
    }
    dispatch(submitFeedbackAPI(payload));
  }

  return (
    <Box sx={{ padding: "13px 20px !important", borderRadius: "24px", maxHeight: "96vh", overflowY: "auto" }}>
      <Box>
        <SkeletonWrapper
          multipleCount={3}
          id={"skeleton-smart-summary"}
          condition={appointmentData?.smart_summary_text}
          props={[SkeletonPropsStyleGenerator("100%", "40px")]}
        >
          <Text
            lineHeight="1.3"
            data-testid="scd-health-patient-summary"
            variant="bodyM"
            minWidth="125px"
          >
            <Editor data={appointmentData?.smart_summary_text} editable={false} />
          </Text>
        </SkeletonWrapper>
      </Box>
      <Divider sx={{ marginY: "10px", color: "#ccc" }} />
      <Box
        sx={{
          display: "flex",
          marginTop: "5px",
        }}
      >
        <Box
          flexBasis="100%"
        >
          <Box sx={healthSummaryFieldsBoxStyle(healthSummarySuccess)}>
            <Text
              data-testid="scd-health-summary-label-last-hosp"
              variant="propertyLabel"
              minWidth="125px"
            >
              Last Hospitalization
            </Text>
            <Box>
              <SkeletonWrapper
                id={"skeleton-health-summary-last-hosp"}
                condition={true}
                props={[SkeletonPropsStyleGenerator("7vmax", "40px", "150px")]}
              >
                <Text
                  data-testid="scd-health-summary-value-last-hosp"
                  variant="bodyXs"
                >NA</Text>
              </SkeletonWrapper>
            </Box>
          </Box>
          <Box sx={{ ...healthSummaryFieldsBoxStyle(healthSummarySuccess), marginTop: "5px" }}>
            <Text
              data-testid="scd-health-summary-label-reason"
              variant="propertyLabel"
              minWidth="125px"
            >
              Reason for Visit
            </Text>
            <Box>
              <SkeletonWrapper
                id={"skeleton-health-summary-reason"}
                condition={true}
                props={[SkeletonPropsStyleGenerator("14vmax", "40px", "150px")]}
              >
                <Text
                  data-testid="scd-health-summary-value-reason"
                  variant="bodyXs"
                >
                  {encounterByAppointmentData?.visit_reason}
                </Text>
              </SkeletonWrapper>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginRight: "15px", position: "absolute", top: -40, right: 12 }}>
        <ThumbUpIcon onClick={() => handleFeedbackStatus(true)} sx={{ color: (smartSummaryfeedback === "" || smartSummaryfeedback === undefined) ? "#ccc" : (smartSummaryfeedback ? "#246EFD" : "#ccc"), cursor: "pointer" }} />
        <ThumbDownIcon onClick={() => setShowFeedbackModal(true)} sx={{ color: (smartSummaryfeedback === "" || smartSummaryfeedback === undefined) ? "#ccc" : (smartSummaryfeedback ? "#ccc" : "#246EFD"), marginLeft: "14px", cursor: "pointer" }} />
      </Box>
      <CustomModal
        fullWidth
        maxWidth="md"
        title="Smart Summary Feedback"
        open={showFeedbackModal}
        setOpen={() => {
          handleFeedbackStatus(false)
        }}
        actions={[
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              text={"Cancel"}
              onClick={() => handleFeedbackStatus(false)}
              sx={{ marginRight: "10px", width: "150px" }}
              border={"1px solid #272A40"}
              backgroundColor="#FFFFFF"
              borderRadius="10px"
              color="#272A40"
              btnColor="#272A40"
            />
            <Button
              onClick={() => handleFeedbackStatus(false)}
              text={"Save"}
              sx={{ width: "150px" }}
              backgroundColor="#315FFF"
              borderRadius="10px"
              color="#fff"
              btnColor="#fff"
            />
          </Box>
        ]}
      >
        <Box paddingY={2} paddingX={2}>
          <Text variant="h3" marginY={1}>Feedback</Text>
          <InputField
            rows={4}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            flex={1}
            multiline
            name={"scd-assesment-notes"}
            labelVariant="formLabel"
            value={feedbackNote}
            onChange={(e) => setFeedbackNote(e.target.value)}
          />
        </Box>
      </CustomModal>
    </Box>
  );
};
export default HealthSummary;
