import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import threedots from "assets/svg/light/threedots.svg";
import {
  deletePatientMedications,
  updatePatientMedications,
} from "redux/features/Medications/MedicationsSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Text from "components/common/Typography/Text";

const Accordian = ({ currentMed, isActive, classes, title, data, id, removeMedicationFromtheState }) => {
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);
  // To track if a specific medication is being deleted;
  const [isDeleting, setIsDeleting] = useState(false)
  const { deletingMedicationInProgress, deletedMedicationSuccessfully } =
    useSelector((state) => state.medications);

  const handleMenuOpen = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMedsDelete = () => {
    setIsDeleting(true);
    dispatch(deletePatientMedications(patientId, id));
  };

  useEffect(() => {
    if (isDeleting && deletedMedicationSuccessfully) {
      handleMenuClose();
      removeMedicationFromtheState(id, isActive)
      setIsDeleting(false)
    }
  }, [deletedMedicationSuccessfully]);

  const updateMedication = () => {
    const payload = {
      ...currentMed,
      end_date: new Date(),
    }
    dispatch(updatePatientMedications(patientId, id, payload));
    setAnchorEl(null);
  }

  return (
    <Box data-testid="accordion-ui-medications-mainbox">
      <Accordion
        expanded={expanded}
        onClick={(_) => setExpanded(!expanded)}
        data-testid="accordion-ui-medications-details"
        sx={{ boxShadow: "none" }}
        className={classes?.accordionRoot}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          sx={{
            background: isActive
              ? "#E5EBFF !important"
              : "rgb(240, 240, 240) !important",
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-content`}
          id={`panel-header`}
          data-testid={`panel-header-medications-details`}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text
              id="today-heading"
              fontWeight="600"
              sx={{ margin: "0px" }}
              variant="h3"
              color={"black"}
            >
              <span data-testid="accordian-title-medications-details">
                {title}
              </span>
            </Text>
            <img
              data-testid="medication-delete-menu-open-id"
              style={{ marginRight: "4px", width: "15px", height: "15px" }}
              src={threedots}
              onClick={(event) => {
                event.stopPropagation(); // This stops the event from bubbling up to the Accordion
                handleMenuOpen(event);
              }}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails data-testid="accordion-details">
          {data?.key && data}
        </AccordionDetails>
      </Accordion>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        data-testid="menu-medications"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          "& .MuiMenuItem-root": {
            fontSize: "0.875rem",
            paddingTop: "5px",
            paddingBottom: "5px",
          },
        }}
      >
        <MenuItem
          data-testid="delete-option-for-medications"
          onClick={handleMedsDelete}
        >
          Delete
          {deletingMedicationInProgress && (
            <CircularProgress
              sx={{ marginLeft: "4px" }}
              size={"20px"}
              color="inherit"
            />
          )}
        </MenuItem>
        {currentMed && <MenuItem onClick={updateMedication}>Inactive</MenuItem>}
      </Menu>
    </Box>
  );
};

export default Accordian;
