import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    isQueLoading: false,
    questionsList: [],
    isSaveLoading: false,
    saved: false,
}

const name = "phq"

export const locationsSlice = createSlice({
    name,
    initialState,
    reducers: {
        getQuestions: (state, action) => {
            state.isQueLoading = true;
        },
        getQuestionsSuccess: (state, { payload }) => {
            state.isQueLoading = false;
            state.questionsList = payload?.responses?.map((obj) => {
                return {
                    ...obj,
                    data: [
                        { label: "Option A", score: 0 },
                        { label: "Option B", score: 1 },
                        { label: "Option B", score: 2 },
                        { label: "Option B", score: 3 },
                    ],
                }
            });
        },
        getQuestionsFail: (state, action) => {
            state.isQueLoading = false;
            state.questionsList = [];
        },
        //for saving phq9 form
        saveQuestions: (state, action) => {
            state.isSaveLoading = true;
        },
        saveQuestionsSuccess: (state, { payload }) => {
            state.isSaveLoading = false;
            state.saved = true
        },
        saveQuestionsFail: (state, action) => {
            state.isSaveLoading = false;
            state.saved = false
        },
        clearSavedStates:(state)=>{
            state.isSaveLoading = false;
            state.saved = false
        }
    },
})

export const { getQuestionsFail,clearSavedStates } = locationsSlice.actions

export default locationsSlice.reducer

export const getQuestionsList = (appointmentId) => ({
    payload: {
        apiName: name,
        method: "get",
        url: `/api/phq_assessments/get_form/${appointmentId}/`,
    },
    type: "getQuestions",
});

export const postQuestionsAns = (data) => ({
    payload: {
        apiName: name,
        method: "POST",
        data,
        url: `/api/phq_responses/save_form/`,
    },
    type: "saveQuestions",
});