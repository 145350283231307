import { Box } from "@mui/material";
import CustomModal from "components/common/Modal/CustomModal";
import Text from "components/common/Typography/Text";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLabsResults } from "redux/features/Patients/ViewLabResultsSlice";
import VitalsIcon from "../../../../../assets/svg/light/timeIcon.svg";
import LabResultDetail from "./LabResultDetail";
import CustomTooltip from "components/common/CustomTooltip/CustomTooltip";
import { isOutOfRange } from "utils/isOutofRange";
import { checkNullOrUndefinedString } from "utils/helper";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";

const LabResults = ({ setReportHeigh }) => {
  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient
  );
  const dispatch = useDispatch();
  const { patientId } = useParams();

  const [openVitals, setOpenVitals] = useState(false);
  const [formattedLabsData, setFormattedLabsData] = useState([]);
  const [_, setLeftSideHeight] = useState("auto");
  const leftSideRef = useRef(null);
  const { labResults, labsResultsSuccess } = useSelector(
    (state) => state.resultsLabs
  );

  const getFormattedLabs = () => {
    let formattedData = [];
    labResults?.[0]?.lab_tests?.map((labTests) => {
      labTests?.lab_observations.map((details) => {
        formattedData.push({
          code: details?.name,
          val: `${details?.value} ${details?.unit}`,
          at_risk: details?.in_range,
          range: `${details?.reference_range} ${details?.unit}`,
        });
      });
    });
    setFormattedLabsData(formattedData);
  };

  useEffect(() => {
    getFormattedLabs();
    if (leftSideRef.current) {
      const height = leftSideRef.current.clientHeight;
      setLeftSideHeight(`${height}px`);
    }
  }, [labResults]);

  useEffect(() => {
    patientId && dispatch(getLabsResults(patientId));
  }, [dispatch, patientId]);

  useEffect(() => {
    const count = [...formattedLabsData].filter((data) =>
      isOutOfRange(data?.val, data?.range)
    )?.length;
    if (setReportHeigh) {
      setReportHeigh(count);
    }
  }, [formattedLabsData]);

  return (
    <Box
      sx={{
        marginTop: "35px",
        height: "40vh"
      }}
    >
      <Box
        data-testid="scd-labs-modal-btn"
        sx={{
          width: "29px",
          height: "29px",
          cursor: "pointer",
          position: "absolute",
          right: "10px",
          top: "12px",
        }}
        onClick={() => {
          setOpenVitals(true);
        }}
      >
        <img src={VitalsIcon} alt="" />
      </Box>
      <Box sx={{ padding: "2px", height: "100%" }}>
        <SkeletonWrapper
          condition={labsResultsSuccess}
          multipleCount={5}
          id="lab-res-details-skeleton"
          props={[SkeletonPropsStyleGenerator("auto", "40px", "150px")]}
        >
          {formattedLabsData?.length ? (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
                maxHeight: "40vh",
                overflowY: "auto",
                height: "100%",
                width: "100%",
              }}
            >
              {formattedLabsData?.map(({ code, val, at_risk, range }, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text
                    data-testid={`scd-labs-label-${code}`}
                    variant="h3"
                    minWidth="70px"
                    sx={{
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      flex: 1,
                    }}
                  >
                    {code}:
                  </Text>
                  <CustomTooltip
                    tooltipText={
                      <Box sx={{ padding: "5px" }}>
                        <Box>
                          <Text variant="h3">
                            <strong>{code} Reference Range</strong>
                          </Text>
                        </Box>
                        <Box sx={{ marginTop: "5px" }}>
                          <Text variant="formLabel">
                            {checkNullOrUndefinedString(range)}
                          </Text>
                        </Box>
                      </Box>
                    }
                    arrow
                  >
                    <Text
                      data-testid={`scd-labs-value-${val}`}
                      variant="bodyS"
                      marginLeft="10px"
                      marginRight="10px"
                      color={at_risk === "at_risk" ? "redLight" : ""}
                      sx={{ cursor: "context-menu" }}
                    >
                      {/* added this check to ensure that if we got the range then display range otherwise simply display val */}
                      {/* sometime we may get the range e.g Epithelial Cell :  "value": [ "20.0", "25.0" ] */}
                      {checkNullOrUndefinedString(
                        Array.isArray(val) && val.length > 1
                          ? `${val.join('-')}`
                          : val
                      )}
                    </Text>
                  </CustomTooltip>
                </Box>
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "250px",
                flex: 1,
              }}
            >
              No data found
            </Box>
          )}
        </SkeletonWrapper>
        <CustomModal
          open={openVitals}
          setOpen={setOpenVitals}
          title={"Previous Lab Results"}
          fullWidth
          maxWidth="lg"
        >
          <LabResultDetail
            patientName={
              (getSpecificPatientData?.first_name
                ? getSpecificPatientData?.first_name
                : "") +
              " " +
              (getSpecificPatientData?.last_name
                ? getSpecificPatientData?.last_name
                : "")
            }
          />
        </CustomModal>
      </Box>
    </Box >
  );
};

export default LabResults;
