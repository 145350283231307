import { Typography } from "@mui/material";
import { createCustomTheme } from "styles/theming/theme";

const Text = ({
  id,
  children,
  classes,
  variant = "h2",
  center = false,
  color,
  marginY = 0,
  sx = {},
  ...props
}) => {
  const theme = createCustomTheme();

  const colors = {
    blue: theme?.palette?.themeBlue?.primary,
    lightBlue: theme?.palette?.common?.blue,
    oceanBlue: "#315FFF",
    lightGreen: "#5EC4B2",
    orange: "#FF9314",
    grey: theme?.palette?.text?.primary,
    white: theme?.palette?.common?.white,
    black: theme?.palette?.common?.black,
    red: "#C41313",
    redLight: "#F23F3F",
  };

  const textColor = colors[color] || "unset";

  return (
    <Typography
      data-testid={id}
      variant={variant}
      align={center ? "center" : undefined}
      className={classes}
      sx={() => {
        if (color) {
          return { color: textColor, ...sx };
        } else return { ...sx };
      }}
      {...props}
      my={marginY}
    >
      {children}
    </Typography>
  );
};

export default Text;
