import { Box, Grid } from "@mui/material";
import HorizontalTabs from "components/common/Tabs/HorizontalTabs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getVitalsList } from "redux/features/Vitals/VitalsSlice";
import LabResults from "./LabResults/LabResults";
import LabTrends from "./LabResults/LabTrends";
import VitalTrends from "./Vitals/VitalTrends";
import Vitals from "./Vitals/Vitals";
import { isOutOfRange } from "utils/isOutofRange";

const VitalLabMedWrapper = () => {
  let dispatch = useDispatch();
  let { patientId } = useParams();
  const [vitals, setVitals] = useState(false);
  const [isGraphDisplay, setIsGraphDisplay] = useState(1);
  const [isVital, setIsVital] = useState(true);
  const [childrenArrayIndex, setChildrenArrayIndex] = useState(0);
  const [activeSubTab, setActiveSubTab] = useState("Latest");
  const leftSideRef = useRef(null);
  const [formattedLabsData, setFormattedLabsData] = useState([]);
  const [leftSideHeight, setLeftSideHeight] = useState("auto");

  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient
  );
  const [count, setCount] = useState(0);
  const { healthSummaryData } = useSelector((state) => state.healthSummary);

  const { labResults, labsResultsSuccess, isLabResultsLoading } = useSelector(
    (state) => state.resultsLabs
  );

  const getFormattedLabs = () => {
    let formattedData = [];
    labResults?.[0]?.observations?.map((details) => {
      formattedData.push({
        code: details?.test_name,
        val: `${details?.value} ${details?.unit}`,
        range: `${details?.normal_range} ${details?.unit}`,
      });
    });
    setFormattedLabsData(formattedData);
  };
  useEffect(() => {
    getFormattedLabs();
    if (leftSideRef.current) {
      const height = leftSideRef.current.clientHeight;
      setLeftSideHeight(`${height}px`);
    }
  }, [labResults]);

  const patientName =
    getSpecificPatientData?.first_name +
    " " +
    getSpecificPatientData?.last_name;

  useEffect(() => {
    // Calculate the number of items with redLight

    const count = [...formattedLabsData].filter((data) =>
      isOutOfRange(data?.val, data?.range)
    )?.length;
    setCount(count);
  }, [formattedLabsData]);

  // to get the Vitals value
  useEffect(() => {
    dispatch(getVitalsList(patientId));
  }, [patientId]);
  const handleClick = () => {
    setChildrenArrayIndex(0);
    setVitals(!vitals);
  };

  const [reportHeigh, setReportHeigh] = useState(count);

  useEffect(() => {
    setReportHeigh(count);
  }, [count]);

  const handleChildrenIndex = (index) => {
    setChildrenArrayIndex(index);
    setIsGraphDisplay(1);
  };

  const childrenArray = [
    {
      title: "Vitals",
      tab: activeSubTab === "Latest" ? <Vitals /> : <VitalTrends />,
    },
    {
      title: "Lab Results ",
      tab:
        activeSubTab === "Latest" ? (
          <LabResults
            setReportHeigh={setReportHeigh}
            patientName={patientName}
            isOutOfRange={isOutOfRange}
          />
        ) : (
          <LabTrends />
        ),
      right: true,
      isNumber: true,
      number: reportHeigh,
    },
  ];
  const vitalArray = [
    { title: "Latest", tab: <></> },
    { title: "Trends", tab: <VitalTrends /> },
  ];

  return (
    <Box
      style={{ position: "relative", height: "100%" }}
      sx={
        vitals
          ? {
              background: vitals ? "#ffffff" : "transparent",
              padding: "10px",
              borderRadius: "10px",
            }
          : {}
      }
    >
      {vitals ? (
        <HorizontalTabs childrenArray={vitalArray} defaultValue={0} />
      ) : (
        <Box style={{ height: "100%" }}>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "24px",
              height: "100%",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <HorizontalTabs
              isDisplay={isGraphDisplay === 1}
              handleChildrenIndex={handleChildrenIndex}
              childrenArray={childrenArray}
              defaultValue={0}
              setFlag={setIsVital}
              borderTab
            />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          top: "14px",
          right: "20px",
        }}
      >
        {childrenArrayIndex === 2 ? null : (
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                marginRight: "20px",
                fontSize: "14px",
                fontWeight: "600",
                cursor: "pointer",
                paddingBottom: "12px",
                borderBottom:
                  activeSubTab === "Latest" ? "2px solid #1976d2" : "",
              }}
              onClick={() => setActiveSubTab("Latest")}
            >
              Latest
            </Box>
            <Box
              sx={{
                marginRight: "10px",
                fontSize: "14px",
                fontWeight: "600",
                cursor: "pointer",
                paddingBottom: "12px",
                borderBottom:
                  activeSubTab === "Trends" ? "2px solid #1976d2" : "",
              }}
              data-testid="historical-tab-shifter-id"
              onClick={() => setActiveSubTab("Trends")}
            >
              {" "}
              Trends{" "}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VitalLabMedWrapper;
