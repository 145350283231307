function removeSnakeCasingAndCapitalize(str) {
    return str.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
  }
export function convertJsonToMarkDown (data){
    let mainMarkDownString = ``
    const addH3 = (key) => mainMarkDownString += `### ${removeSnakeCasingAndCapitalize(key)}:\n`
    const addList = (value) => mainMarkDownString += `- ${value} \n`
    const addKeyValueString = (key, value) => mainMarkDownString += `**${removeSnakeCasingAndCapitalize(key)}:** ${value} \n`
    const addTable = (value) => {
        let tableHeader = ``
        let tableSpacer = ``
        for (const [key, _] of Object.entries(value?.[0])) {
            tableHeader += `| ${removeSnakeCasingAndCapitalize(key)} `
            tableSpacer += `| --- `
        }
        mainMarkDownString += `${tableHeader} | \n${tableSpacer}| \n`
        for (let obj of value) {
            for (const [_, value] of Object.entries(obj)) {
                mainMarkDownString += `| ${value} `
            }
            mainMarkDownString += `| \n`
        }
    }

    function checkTypesAndAdd(key, value){
        if(typeof(value) === 'string'){
            addKeyValueString(key, value)
        }
        else if (Array.isArray(value)){
            addH3(key)
            if (typeof (value?.[0]) === "string") {
                value.forEach((val) => addList(val))
            }
            else if(typeof (value?.[0]) === "object"){
                addTable(value)
            }
        }
        else if (typeof(value) === 'object' && value !== null){
            addH3(key)
            for (const [innerKey, innerValue] of Object.entries(value)) {
                addKeyValueString(innerKey, innerValue)
            }
        }
    }
    
    for (const [key, value] of Object.entries(data)) {
        checkTypesAndAdd(key, value)
    }
    return mainMarkDownString;
}