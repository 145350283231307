import { Box, CircularProgress, Tab, Tabs, useTheme } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { flexRowCenterCenter } from "styles";
import {
  labtrendsMainWrapper,
  trendsLabsButtonsStyle,
  trendsMainLabsTabBoxStyle,
  vitalTrendsUnitStyle,
} from "styles/SCD/trendsStyle";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const LabTrends = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [labsData, setLabsData] = useState([]);
  const [isActive, setIsActive] = useState("");
  const [tabsNames, setTabsNames] = useState([]);
  const [isActiveUnit, setIsAcitveUnit] = useState("");

  const { labResults, isLabResultsLoading } = useSelector(
    (state) => state.resultsLabs
  );

  useEffect(() => {
    let allLabsData = [];
    let requiredValsAccordingDates = [];

    labResults?.forEach(({ lab_tests }) => {
      // This array is defined to gather the codes according to dates
      let temp = [];
      lab_tests?.map(({ lab_observations }) => {
        lab_observations?.map((details) => {
          allLabsData.push({
            code: details?.name,
            val: details?.value,
            unit: details?.unit,
          });
          temp.push({ code: details?.name });
        });
        requiredValsAccordingDates.push(temp);
      })
    });
    setLabsData(allLabsData);

    // Tabs length can be different in dates so max is getting filtered
    setTabsNames(
      requiredValsAccordingDates.reduce((maxArray, currentArray) => {
        return currentArray.length > maxArray.length ? currentArray : maxArray;
      }, [])
    );
  }, [labResults]);


  useEffect(() => {
    if (labsData.length > 0) {
      // Calculating first active tab and unit dynamically
      setIsActive(labsData[0]?.code);
      setIsAcitveUnit(labsData[0]?.unit);

      // Map the datetime values and format them using moment
      const formattedDates = labResults?.map((s) =>
        moment(s?.datetime).format("YYYY/MM/DD")
      );

      // Sort the formatted dates from oldest to latest
      const sortedDates = formattedDates?.sort(
        (a, b) =>
          moment(a, "MM/DD/YYYY").valueOf() - moment(b, "MM/DD/YYYY").valueOf()
      );
      // Calculating dates
      setDate(sortedDates);
    }
  }, [labsData.length]);

  useEffect(() => {
    // Making data record for selected tab values
    let requiredVals = [];
    labsData?.forEach((s) => {
      if (s.code === isActive) {
        requiredVals.push(s.val);
      }
    });
    setData(requiredVals);
    setIsAcitveUnit(labsData.find((s) => s.code === isActive)?.unit);
  }, [labsData.length, isActive]);

  useEffect(() => { }, [labsData.length]);

  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  let graphData = {
    series: [
      {
        name: "mg/dL",
        data: data,
      },
    ],
    options: {
      chart: {
        height: 250,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "straight",
      },
      xaxis: {
        categories: date,
      },
      yaxis: {
        tickAmount: 4,
        min: 0.6,
        max: Math.ceil(Math.max(...data)) + 5,
      },
    },
  };

  return !isLabResultsLoading ? (
    labsData?.length > 0 ? (
      <Box
        data-testid="lab-trends-id"
        sx={labtrendsMainWrapper}
        className="labtrends_main_wrapper"
      >
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          orientation="vertical"
          scrollButtons
          allowScrollButtonsMobile
          sx={trendsMainLabsTabBoxStyle}
          className="lab-trends-tabs"
        >
          {tabsNames?.map((s, i) => (
            <Tab
              key={i}
              {...a11yProps(i)}
              label={s?.code}
              sx={trendsLabsButtonsStyle(isActive, s?.code, theme)}
              onClick={() => setIsActive(s?.code)}
            />
          ))}
        </Tabs>
        <small data-testid={"labs-trends-unit"} style={vitalTrendsUnitStyle}>
          {" "}
          {isActiveUnit}{" "}
        </small>
        <Box id={"chart"}>
          <ReactApexChart
            options={graphData.options}
            series={graphData.series}
            type="line"
            height={400}
          />
        </Box>
      </Box>
    ) : (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // adjust if needed for full-height centering
          minHeight: '40vh', // optional for minimum height
        }}
      >
        No data found
      </Box>
    )
  ) : (
    <Box sx={{ ...flexRowCenterCenter, height: "40vh" }}>
      <CircularProgress data-testid="loader-id" />
    </Box>
  );
};
export default LabTrends;
