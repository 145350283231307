import { Box, List, ListItem, ListItemIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TermMedsModal from "components/common/Modal/TermsMedsModal";
import { useEffect, useState } from "react";
import { useDispatch} from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logoutApi } from "redux/features/Auth/loginSlice";
import "styles/index.css";
import threepeoplebold from "../../assets/svg/light/scd_inactive_icon.svg";
import threepeople from "../../assets/svg/light/scd_active_icon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .MuiButtonBase-root.MuiListItem-root": {
      paddingLeft: "10px !important",
    },
    "& .MuiListItemIcon-root": {
      width: "50px",
      height: "100%",

      "& .MuiSvgIcon-root": {
        width: "100%",
        height: "35px",
      },
    },
  },
  icon: { width: 50, height: 50 },
}));

function Sidebar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(() => {
    return localStorage.getItem("active") || "Users";
  });

  const upperArray = [
    {
      id: "users",
      icon: threepeople,
      iconBold: threepeoplebold,
      path: "/",
      iconName: "Users",
    },
  ];

  useEffect(() => {
    localStorage.setItem("active", active);
  }, [active]);

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/schedule") {
      setActive("Users");
    } else if (location.pathname === "/financials") {
      setActive("Financials");
    } else if (location.pathname === "/settings") {
      setActive("");
    }
  }, [location]);

  const handlePaths = (path, iconName) => {
    if (iconName === "encyclopedia") {
      setOpen(true);
    } else {
      navigate(path);
      setActive(iconName);
    }
  };
  const handleLogout = () => dispatch(logoutApi());

  return (
    <Box className={classes.root}>
      <List sx={{ alignContent: "center" }}>
        {upperArray.map(
          ({ icon, iconBold, path, iconName = "", id }, index) => {
            return (
              <ListItem
                button
                key={index}
                data-testid={`sidebar-icon-${id}-id`}
                onClick={() => {
                  iconName === "power"
                    ? handleLogout()
                    : handlePaths(path, iconName);
                }}
                sx={{
                  position: "relative",
                  marginTop: "10px",
                }}
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={active !== iconName ? iconBold : icon}
                    data-testid={id}
                    height={"65%"}
                    width={"65%"}
                    alt=""
                  />
                </ListItemIcon>
                <Box
                  sx={{
                    position: "absolute",
                    right: "0px",
                    height: "60px",
                    border: active === iconName ? "3px solid #3045B3" : "",
                    borderRadius: "20px",
                  }}
                  data-testid={`${id}-active-bar`}
                >
                  {" "}
                </Box>
              </ListItem>
            );
          }
        )}
      </List>
      <TermMedsModal open={open} setOpen={setOpen} />
    </Box>
  );
}

export default Sidebar;
