import { Box, Grid, Radio } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "components/common/Button/Button";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearSavedStates,
  getQuestionsList,
  postQuestionsAns,
} from "redux/features/PHQ/phqSlice";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";

const useStyles = makeStyles((theme) => ({
  borderBottom: {
    borderRadius: "1px",
    padding: "0px 10px",
  },
  radioColorPrimary: {
    color: "#4f46e5 !important", // Change radio color to #315FFF
  },
  txtCenter: {
    textAlign: "center",
  },
  col: {
    textAlign: "center",
    paddingLeft: "0px",
    paddingRight: "0px",
    height: "auto",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  col1: {
    paddingLeft: "0px",
    paddingRight: "0px",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  col3: {
    paddingLeft: "10px",
    paddingRight: "10px",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    "& .MuiSvgIcon-root": {
      height: 16,
      borderRadius: "30px",
      width: 16,
    },
  },
}));

const PHQFormModal = ({ appointmentId, setOpenPhqModal, patientId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { questionsList, isQueLoading, isSaveLoading, saved } = useSelector(
    (state) => state.phq
  );
  const [totalScore, setTotalScore] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (rowIndex, optionIndex, id) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[rowIndex] = {
      question: id,
      response: optionIndex,
    };
    setSelectedOptions(newSelectedOptions);
  };

  const calculateTotalScore = () => {
    let totalScores = 0;
    selectedOptions.forEach(({ response }, rowIndex) => {
      if (response !== -1) {
        let score = selectedOptions?.[rowIndex]?.response;
        if (score) {
          totalScores += score;
        }
      }
    });
    return totalScores;
  };

  useEffect(() => {
    setTotalScore(calculateTotalScore());
  }, [selectedOptions]);

  useEffect(() => {
    const newSelectedOptions = [];
    questionsList?.map(({ question, response }, rowIndex) => {
      newSelectedOptions[rowIndex] = {
        question: question,
        response,
      };
    });
    setSelectedOptions(newSelectedOptions);
  }, [questionsList]);

  useEffect(() => {
    dispatch(getQuestionsList(appointmentId));
    return () => {
      setTotalScore(0);
      dispatch(clearSavedStates());
    };
  }, []);

  const handleSubmit = () => {
    const payload = {
      patient_id: patientId,
      responses: selectedOptions?.filter((v) => v != null && v != -1),
    };
    dispatch(postQuestionsAns(payload));
  };

  useEffect(() => {
    saved && setTotalScore(0);
    saved && setOpenPhqModal(false);
    saved && toast.success("Form Saved!");
  }, [saved]);

  return (
    <div>
      <SkeletonWrapper
        condition={!isQueLoading}
        multipleCount={1}
        props={[SkeletonPropsStyleGenerator("100%", "44px", "150px")]}
      >
        <div className={classes.borderBottom}>
          <Grid container>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Box
                sx={{
                  padding: "10px 0px",
                  fontSize: "11px",
                  lineHeight: "25px",
                }}
                data-testid={"phq-header-1"}
              >
                Over the last 2 weeks, how often have you been bothered by any
                of the following problems?
                <div style={{ fontWeight: 700 }}>
                  (Use “✔” to indicate your answer)
                </div>
              </Box>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} className={classes.col3}>
              <Box
                data-testid={"phq-header-2"}
                sx={{
                  padding: "10px 0px",
                  fontSize: "11px",
                  fontWeight: "700",
                  lineHeight: "25px",
                }}
              >
                {" "}
                Not at all (0)
              </Box>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} className={classes.col}>
              <Box
                data-testid={"phq-header-3"}
                sx={{
                  padding: "10px 0px",
                  fontSize: "11px",
                  fontWeight: "700",
                  lineHeight: "25px",
                }}
              >
                Several days (1)
              </Box>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} className={classes.col}>
              <Box
                data-testid={"phq-header-4"}
                sx={{
                  padding: "10px 0px",
                  fontSize: "11px",
                  fontWeight: "700",
                  lineHeight: "25px",
                }}
              >
                More than half the days (2)
              </Box>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} className={classes.col}>
              <Box
                data-testid={"phq-header-5"}
                sx={{
                  padding: "10px 0px",
                  fontSize: "11px",
                  fontWeight: "700",
                  lineHeight: "25px",
                }}
              >
                Nearly every day (3)
              </Box>
            </Grid>
          </Grid>
        </div>
      </SkeletonWrapper>
      <SkeletonWrapper
        id={"phq-9-loading"}
        condition={!isQueLoading}
        multipleCount={9}
        props={[SkeletonPropsStyleGenerator("100%", "44px", "150px")]}
      >
        {questionsList?.map((item, rowIndex) => (
          <div key={rowIndex} className={classes.borderBottom}>
            <Grid container>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <div
                  data-testid={`phq-question-id-${rowIndex + 1}`}
                  style={{
                    padding: "10px 0px",
                    paddingLeft: "5px",
                    lineHeight: "25px",
                    fontSize: "11px",
                  }}
                >
                  <span style={{ fontWeight: 700 }}>{rowIndex + 1}.</span>{" "}
                  {item?.question_text}
                </div>
              </Grid>
              {item?.data?.map((val, optionIndex) => (
                <Grid
                  key={optionIndex}
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  className={optionIndex === 0 ? classes.col3 : classes.col1}
                >
                  <Radio
                    data-testid={`phq-que-${rowIndex + 1}-option-id-${optionIndex + 1}`}
                    classes={{
                      root: classes.root,
                      colorPrimary: classes.radioColorPrimary,
                    }}
                    value={val.label}
                    checked={
                      selectedOptions[rowIndex]?.response === optionIndex
                    }
                    onChange={() =>
                      handleCheckboxChange(
                        rowIndex,
                        optionIndex,
                        item?.question
                      )
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      </SkeletonWrapper>
      <Box
        data-testid={"phq-val-id"}
        sx={{
          borderTop: "1px solid #ccc",
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          padding: "10px",
          lineHeight: "25px",
          fontSize: "11px",
          fontWeight: 700,
        }}
      >
        Total Score = {totalScore}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        <Button
          text={"Cancel"}
          onClick={() => setOpenPhqModal(false)}
          sx={{ marginRight: "10px", width: "150px" }}
          border={"1px solid #272A40"}
          borderRadius="10px"
          backgroundColor="#FFFFFF"
          color="#272A40"
          btnColor="#272A40"
        />
        <Button
          text={"Save "}
          disabled={isQueLoading}
          isLoading={isSaveLoading}
          onClick={() => {
            handleSubmit();
          }}
          sx={{ width: "150px" }}
          borderRadius="10px"
          backgroundColor="#315FFF"
          color="#fff"
          btnColor="#fff"
        />
      </Box>
    </div>
  );
};

export default PHQFormModal;
