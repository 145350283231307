import TrendTableChart from "pages/Financials/FinancialGraphs/EsrdPatients/TrendTableChart";
import arrowIconUp from "../../assets/svg/colored/arrowIconDown.svg";
import cardImage from "../../assets/svg/colored/cardicon.svg";

export let financialsCardData = [
    {
        cardImage: cardImage,
        text: "Revenue vs Expense",
        value: "$0/$0",
        // value: "$4,018,133.17/$3,609,658.73",
        trendIcon: arrowIconUp,
    },
];

export const rosChipArray = [
    {label: "Nausea", size: "small", variant: "outlined"},
    {label: "Shortness of breath", size: "small", variant: "outlined"},
    {label: "Headaches", size: "small", variant: "outlined"},
];
export const icdChipArray = [
    {label: "E83.30", size: "small", variant: "outlined"},
    {label: "N18.9", size: "small", variant: "outlined"},
    {label: "D63.1", size: "small", variant: "outlined"},
    {label: "E09.22", size: "small", variant: "outlined"},
];
export const keyPerformanceIndicatorsData = [
    {
        keyName: "Active Patient",
    },
    {
        keyName: "ESRD",
    },
    {
        keyName: "New Patient",
    },
    {
        keyName: "Expected Revenue",
    },
];
export let myTeamData = [
    {
        Title: "Practice Administrator",
        members: [
            {
                name: "Dr John Doe (me)",
                role: "Nephrologist",
            },
        ],
    },
    {
        Title: "Doctors",
        members: [
            {
                name: "Dr Liam Johnson",
                role: "Nephrologist",
            },
            {
                name: "Dr Emily Johnson",
                role: "Nephrologist",
            },
            {
                name: "Dr Lily Brook",
                role: "Nephrologist",
            },
            {
                name: "Dr Amando Smith",
                role: "Nephrologist",
            },
        ],
    },
    {
        Title: "Medical Assistants",
        members: [
            {
                name: "Ethan Thompson",
                role: "Nurse",
            },
            {
                name: "Sophia Martinez",
                role: "Nurse",
            },
        ],
    },
    {
        Title: "Front Desk Representative",
        members: [
            {
                name: "Dr Isabella Martinez Harris Turner",
                role: "Front Desk Representative",
            },
            {
                name: "Lucas Turner",
                role: "Front Desk Representative",
            },
        ],
    },
];

export let EditTeamFieldsData = [
    {
        element: "input",
        type: "text",
        label: "First Name",
        name: "first_name",
        placeHolder: "First Name",
    },
    {
        element: "input",
        type: "text",
        label: "Last Name",
        name: "last_name",
        placeHolder: "Last Name",
    },
    {
        element: "input",
        type: "date",
        label: "DOB",
        name: "birthdate",
        max: new Date().toISOString().split("T")[0],
        placeHolder: "1999/01/01",
    },
    {
        element: "input",
        type: "text",
        label: "National Provider ID",
        name: "national_provider_identifier",
        placeHolder: "National Provider ID",
    },
    {
        element: "input",
        type: "string",
        label: "Phone Number",
        name: "phone_number",
        placeHolder: "+1 (123) 123-1234",
    },
];


let widthForFields = {
    width: {lg: "45%", sm: "100%"},
    marginRight: {lg: "10px"},
};
export let profileInformationData = [
    {
        element: "input",
        type: "text",
        sx: {marginRight: "100px", ...widthForFields},
        label: "First Name",
        name: "first_name",
        placeHolder: "First Name",
    },
    {
        element: "input",
        type: "text",
        sx: {...widthForFields},
        label: "Last Name",
        name: "last_name",
        placeHolder: "Last Name",
    },
    {
        element: "input",
        type: "date",
        sx: {marginTop: "5px", ...widthForFields},
        label: "DOB",
        name: "birthdate",
        max: new Date().toISOString().split("T")[0],
        placeHolder: "1999/01/01",
    },
    {
        element: "input",
        type: "text",
        sx: {marginTop: "5px", ...widthForFields},
        label: "National Provider ID",
        name: "national_provider_identifier",
        placeHolder: "National Provider ID",
    },
    {
        element: "input",
        type: "text",
        sx: {marginTop: "5px", ...widthForFields},
        label: "Phone Number",
        name: "phone_number",
        placeHolder: "+1 (123) 123-1234",
    },
];
export let clinicHospitalData = [
    {
        element: "select",
        label: "Clinic",
        sx: {width: {lg: "96%", sm: "100%"}},
        name: "clinic",
        defaultValue: "ABC Kidney Institute",
        placeHolder: "Select Location",
    },
    {
        element: "input",
        type: "text",
        sx: {width: {lg: "47%", sm: "100%"}, marginRight: {lg: "10px"}},
        label: "Address 1",
        name: "address-one",
        placeHolder: "Address 1",
    },
    {
        element: "input",
        type: "text",
        sx: {width: {lg: "47%", sm: "100%"}, marginRight: {lg: "10px"}},
        label: "Address 2",
        name: "address-two",
        placeHolder: "Address 1",
    },
    {
        element: "input",
        type: "text",
        sx: {width: {lg: "31%", sm: "100%"}, marginRight: {lg: "10px"}},
        label: "City",
        name: "city",
        placeHolder: "Los Angeles",
    },
    {
        element: "input",
        sx: {width: {lg: "31%", sm: "100%"}, marginRight: {lg: "10px"}},
        label: "State",
        name: "Californa",
        defaultValue: "California",
        placeHolder: "California",
    },
    {
        element: "input",
        type: "Number",
        sx: {width: {lg: "31%", sm: "100%"}, marginRight: {lg: "10px"}},
        label: "Zip Code",
        name: "number",
        placeHolder: "434",
    },
];
export let titleOptions = [
    {label: "Dr.", value: "Dr."},
    {label: "Mr.", value: "Mr."},
    {label: "Ms.", value: "Ms."},
];

export let changePasswordUserSelectorStateGenerator = (
    message,
    successOrFail,
    loading
) => {
    return {
        changePasswordMessage: message,
        changePasswordSuccess: successOrFail,
        isChangePasswordLoading: loading,
    };
};

export let healthSummaryStaticData = [
    {
        id: 260,
        optimal_start: "Yes",
        last_hospitalization: "2023-11-01",
        ros: "Swelling in ankles maybe",
        assessment_severity: 9,
        reason:
            "increased fatigue, swelling in my extremities, and changes in urination patterns.",
        ckd_status: "CKD-2",
        yes_only_fields: ["DM", "PVD", "CIRRHOSIS"],
        "yes-field-keys": ["DM", "PVD", "CIRRHOSIS"],
    },
];

export let vitalsStaticData = [
    {
        patient_id: 322,
        date_time: "2022-01-28T04:03:00.000000Z",
        height: 23,
        weight: 93,
        systolic: 71,
        diastolic: 75,
        bmi: 99,
        urine_output: 47,
        pain_assessment: 41,
        edema_assessment: "Voluptas architecto",
        other_observations: "Libero qui vel autem",
        temperature: 29,
        pulse: 93,
        respiration_rate: 60,
        spo2: 45,
        account_id: 1,
    },
    {
        patient_id: 374,
        date_time: "2023-12-20T18:03:17.216815Z",
        height: 170,
        weight: 180,
        systolic: 130,
        diastolic: 110,
        bmi: 25.8,
        urine_output: 800,
        pain_assessment: 2,
        edema_assessment: "mild swelling in ankles",
        other_observations: "no flank pain",
        temperature: 98.6,
        pulse: 80,
        respiration_rate: 12,
        spo2: 98,
        account_id: 1,
    },
];
export let labResultsStaticData = [
    {
        id: 2654,
        date_time: "2024-01-20T16:48:11.272062Z",
        lab_provider: null,
        original_file_url:
            "https://nephro-media.s3.us-west-2.amazonaws.com/patients/complete-blood-count.pdf",
        practitioner_id: 3,
        patient_id: 49,
        observations: [
            {
                test_name: "Na+",
                test_code: "Na+",
                test_description: "Sodium",
                value: 141.0,
                unit: "mmol/L",
                normal_range: "135-145",
                gender: "M",
            },
            {
                test_name: "Hgb",
                test_code: "Hb",
                test_description: "Hemoglobin",
                value: 8.8,
                unit: "g/L",
                normal_range: "6-14",
                gender: "M",
            },
            {
                test_name: "BUN",
                test_code: "BUN",
                test_description: "Blood Urea Nitrogen",
                value: 15.5,
                unit: "mg/dL",
                normal_range: "7-17",
                gender: "M",
            },
            {
                test_name: "Chol",
                test_code: "Chol",
                test_description: "Cholestrol",
                value: 230.0,
                unit: "mg/dL",
                normal_range: "150-240",
                gender: "M",
            },
            {
                test_name: "UACR",
                test_code: "UACR",
                test_description: "urine albumin-creatinine ratio",
                value: 25.0,
                unit: "mg/g",
                normal_range: "10-30",
                gender: "M",
            },
            {
                test_name: "K+",
                test_code: "K+",
                test_description: "Potassium",
                value: 4.8,
                unit: "mmol/L",
                normal_range: "3.5-5.2",
                gender: "M",
            },
            {
                test_name: "Cr",
                test_code: "Cr",
                test_description: "Creatinine",
                value: 1.2,
                unit: "mg/dL",
                normal_range: "0.66-1.25",
                gender: "M",
            },
            {
                test_name: "LDL",
                test_code: "LDL",
                test_description: "Low Density Lipid",
                value: 180.0,
                unit: "mg/dL",
                normal_range: "80-200",
                gender: "M",
            },
            {
                test_name: "WBC",
                test_code: "WBC",
                test_description: "White Blood Cells",
                value: 9.2,
                unit: "x10^9/L",
                normal_range: "4.5-11",
                gender: "M",
            },
            {
                test_name: "eGFR",
                test_code: "eGFR",
                test_description: "estimated Glomerular Giltration Rate",
                value: 105.0,
                unit: "mL/min",
                normal_range: "0-120",
                gender: "M",
            },
            {
                test_name: "HPO4",
                test_code: "HPO4",
                test_description: "Phosphorus",
                value: 10.1,
                unit: "mmol/L",
                normal_range: "8.5-10.2",
                gender: "M",
            },
            {
                test_name: "RBC",
                test_code: "RBC",
                test_description: "Red Blood Cells",
                value: 3.92,
                unit: "x10^9/L",
                normal_range: "4.3-5.9",
                gender: "M",
            },
            {
                test_name: "Plt",
                test_code: "plt",
                test_description: "platelet Cells",
                value: 264.0,
                unit: "x10^9/L",
                normal_range: "150-400",
                gender: "M",
            },
        ],
    },
    {
        id: 2567,
        date_time: "2023-12-27T13:43:09.175956Z",
        lab_provider: null,
        original_file_url:
            "https://nephro-media.s3.us-west-2.amazonaws.com/patients/complete-blood-count.pdf",
        practitioner_id: 3,
        patient_id: 49,
        observations: [
            {
                test_name: "Na+",
                test_code: "Na+",
                test_description: "Sodium",
                value: 141.0,
                unit: "mmol/L",
                normal_range: "135-145",
                gender: "M",
            },
            {
                test_name: "Hgb",
                test_code: "Hb",
                test_description: "Hemoglobin",
                value: 8.8,
                unit: "g/L",
                normal_range: "6-14",
                gender: "M",
            },
            {
                test_name: "BUN",
                test_code: "BUN",
                test_description: "Blood Urea Nitrogen",
                value: 15.5,
                unit: "mg/dL",
                normal_range: "7-17",
                gender: "M",
            },
            {
                test_name: "Albumin",
                test_code: "Albumin",
                test_description: "Albumin",
                value: 7.3,
                unit: "g/dl",
                normal_range: "3.4-5.4",
                gender: "F",
            },
            {
                test_name: "Chol",
                test_code: "Chol",
                test_description: "Cholestrol",
                value: 230.0,
                unit: "mg/dL",
                normal_range: "150-240",
                gender: "M",
            },
            {
                test_name: "UACR",
                test_code: "UACR",
                test_description: "urine albumin-creatinine ratio",
                value: 25.0,
                unit: "mg/g",
                normal_range: "10-30",
                gender: "M",
            },
            {
                test_name: "K+",
                test_code: "K+",
                test_description: "Potassium",
                value: 4.8,
                unit: "mmol/L",
                normal_range: "3.5-5.2",
                gender: "M",
            },
            {
                test_name: "HcT",
                test_code: "HcT",
                test_description: "HcT",
                value: 34.3,
                unit: "%",
                normal_range: "35-50",
                gender: "F",
            },
            {
                test_name: "Cr",
                test_code: "Cr",
                test_description: "Creatinine",
                value: 0.96,
                unit: "mg/dL",
                normal_range: "0.66-1.25",
                gender: "M",
            },
            {
                test_name: "Glucose (Random)",
                test_code: "Glucose (Random)",
                test_description: "Glucose (Random)",
                value: 230.0,
                unit: "mg/dl",
                normal_range: "100-200",
                gender: "F",
            },
            {
                test_name: "LDL",
                test_code: "LDL",
                test_description: "Low Density Lipid",
                value: 180.0,
                unit: "mg/dL",
                normal_range: "80-200",
                gender: "M",
            },
            {
                test_name: "HCO3",
                test_code: "HCO3",
                test_description: "HCO3",
                value: 30.0,
                unit: "mmol/L",
                normal_range: "22-29",
                gender: "F",
            },
            {
                test_name: "WBC",
                test_code: "WBC",
                test_description: "White Blood Cells",
                value: 9.2,
                unit: "x10^9/L",
                normal_range: "4.5-11",
                gender: "M",
            },
            {
                test_name: "eGFR",
                test_code: "eGFR",
                test_description: "estimated Glomerular Giltration Rate",
                value: 105.0,
                unit: "mL/min",
                normal_range: "0-120",
                gender: "M",
            },
            {
                test_name: "HPO4",
                test_code: "HPO4",
                test_description: "Phosphorus",
                value: 10.1,
                unit: "mmol/L",
                normal_range: "8.5-10.2",
                gender: "M",
            },
            {
                test_name: "RBC",
                test_code: "RBC",
                test_description: "Red Blood Cells",
                value: 3.14,
                unit: "x10^9/L",
                normal_range: "4.3-5.9",
                gender: "M",
            },
            {
                test_name: "Plt",
                test_code: "plt",
                test_description: "platelet Cells",
                value: 264.0,
                unit: "x10^9/L",
                normal_range: "150-400",
                gender: "M",
            },
        ],
    },
];

export let medicationsStaticData = [
    {
        patient_id: 314,
        prescribed_on: "2021-09-24T19:59:00.000000Z",
        discontinued_on: "1988-10-04T20:06:00.000000Z",
        drug_name: "Axel Barton",
        status_ind: "0",
        instructions: "Take 3 times a day",
        account_id: 1,
    },
    {
        patient_id: 314,
        prescribed_on: "2021-09-24T19:59:00.000000Z",
        discontinued_on: "1988-10-04T20:06:00.000000Z",
        drug_name: "Axel Barton",
        status_ind: "0",
        instructions: "Sunt non natus paria",
        account_id: 1,
    },
    {
        patient_id: 314,
        prescribed_on: "2021-09-24T19:59:00.000000Z",
        discontinued_on: "1988-10-04T20:06:00.000000Z",
        drug_name: "Axel Barton",
        status_ind: "0",
        instructions: "Sunt non natus paria",
        account_id: 1,
    },
    {
        patient_id: 314,
        prescribed_on: "1994-05-03T11:43:00.000000Z",
        discontinued_on: "1973-09-28T05:56:00.000000Z",
        drug_name: "Brendan Walton",
        status_ind: "0",
        instructions: "Ut voluptate non qui",
        account_id: 1,
    },
];

export let getSpecificPatientStaticData = {
    id: 374,
    first_name: "Ger",
    last_name: "Han",
    gender: "Female",
    birthdate: "01-16-1958",
    address: null,
    city: "LAVA HOT SPRINGS",
    state: "ID",
    zipcode: "83246",
    patient_status: null,
    primary_insurance: null,
    fhir_resource_id: "8da27e51-da33-4eb0-847a-76a68aa21461",
    account_id: 1,
    url: "/api/patients/374/",
    medications: [],
    photo: "https://nephro-media.s3.us-west-2.amazonaws.com/patients/374.jpg",
    phone_number: "208 904 5555",
};
export let medicationsActiveStaticData = [
    {
        id: 1,
        patient_id: 483,
        prescribed_on: "2023-12-26T10:44:00.901891Z",
        discontinued_on: "2023-12-26T10:44:00.901891Z",
        drug_name: "Emodium (450 mg)",
        status_ind: "1",
        instructions: "two pills in the evening",
        account_id: 1,
        dispense_quantity: 5,
    },
];

export let medicationsInActiveStaticData = [
    {
        id: 2,
        patient_id: 483,
        prescribed_on: "2023-12-26T09:31:40.576220Z",
        discontinued_on: "2023-12-26T09:31:40.576220Z",
        drug_name: "Panadol",
        status_ind: "0",
        instructions: "two pills in the morning",
        account_id: 1,
        dispense_quantity: 5,
    },
    {
        id: 3,
        patient_id: 483,
        prescribed_on: "1987-02-11T10:52:00.000000Z",
        discontinued_on: "2009-09-19T19:25:00.000000Z",
        drug_name: "Raja Schmidt",
        status_ind: "0",
        instructions: "Numquam harum volupt",
        dispense_quantity: 5,
    },
];
export let labTrendsStaticData = [
    {
        id: 2468,
        date_time: "2023-12-27T13:43:09.175956Z",
        testing_lab_name: "Expresslab",
        practitioner_id: 3,
        patient_id: 524,
        observations: [
            {
                test_name: "LDL",
                test_code: "LDL",
                test_description: "Low Density Lipid",
                value: 180,
                unit: "mg/dL",
                normal_range: "80-200",
                gender: "F",
            },
            {
                test_name: "Chol",
                test_code: "Chol",
                test_description: "Cholestrol",
                value: 230,
                unit: "mg/dL",
                normal_range: "150-240",
                gender: "F",
            },
            {
                test_name: "Ca+",
                test_code: "Ca+",
                test_description: "Calcium",
                value: 10.1,
                unit: "mmol/L",
                normal_range: "8.5-10.2",
                gender: "F",
            },
            {
                test_name: "K+",
                test_code: "K+",
                test_description: "Potassium",
                value: 4.8,
                unit: "mmol/L",
                normal_range: "3.5-5.2",
                gender: "F",
            },
            {
                test_name: "Na+",
                test_code: "Na+",
                test_description: "Sodium",
                value: 141,
                unit: "mmol/L",
                normal_range: "135-145",
                gender: "F",
            },
            {
                test_name: "UACR",
                test_code: "UACR",
                test_description: "urine albumin-creatinine ratio",
                value: 25,
                unit: "mg/g",
                normal_range: "10-30",
                gender: "F",
            },
            {
                test_name: "eGFR",
                test_code: "eGFR",
                test_description: "estimated Glomerular Giltration Rate",
                value: 105,
                unit: "mL/min",
                normal_range: "0-120",
                gender: "F",
            },
            {
                test_name: "BUN",
                test_code: "BUN",
                test_description: "Blood Urea Nitrogen",
                value: 15.5,
                unit: "mg/dL",
                normal_range: "7-17",
                gender: "F",
            },
            {
                test_name: "Cr",
                test_code: "Cr",
                test_description: "Creatinine",
                value: 1.2,
                unit: "mg/dL",
                normal_range: "0.66-1.25",
                gender: "F",
            },
            {
                test_name: "Plt",
                test_code: "plt",
                test_description: "platelet Cells",
                value: 330,
                unit: "x10^9/L",
                normal_range: "150-400",
                gender: "F",
            },
            {
                test_name: "WBC",
                test_code: "WBC",
                test_description: "White Blood Cells",
                value: 9.2,
                unit: "x10^9/L",
                normal_range: "4.5-11",
                gender: "F",
            },
            {
                test_name: "RBC",
                test_code: "RBC",
                test_description: "Red Blood Cells",
                value: 4.9,
                unit: "x10^9/L",
                normal_range: "4.3-5.9",
                gender: "F",
            },
            {
                test_name: "Hgb",
                test_code: "Hb",
                test_description: "Hemoglobin",
                value: 8.8,
                unit: "g/L",
                normal_range: "6-14",
                gender: "F",
            },
        ],
    },
];

export let staticIcdCodesData = [
    {
        id: 35,
        icd_code: "Z95828",
        description: "Enterohemorrhagic Escherichia coli infection",
        url: "/api/icds/35/",
        account_id: 1,
    },
];
export let staticCptCodesData = [
    {
        id: 43,
        cpt_code: "99239",
        description: "Hospital Discharge Day Management >",
        url: "/api/cptcodes/43/",
        account_id: 1,
    },
    {
        id: 49,
        cpt_code: "99251",
        description: "Initial Inpatient Consult New/Estab Pt 2",
        url: "api/cptcodes/49/",
        account_id: 1,
    },
];

export let initialStaticCptForAllEncounter = {
    id: 24,
    cpt_code: "99214",
    description: "Office Outpatient Visit",
    url: "/api/cptcodes/24/",
    account_id: 1,
};

export let encounterByAppointmentStaticData = (signCloseId) => {
    return {
        id: 802,
        soap_notes: "This is the notes for the cease Encounter",
        patient_id: 542,
        location_id: 1,
        practitioner_id: 9,
        primary_payer_id: 1,
        secondary_payer_id: 1,
        fhir_resource_id: "1",
        visit_type: "asd",
        outcome: "outcome",
        assessment_notes: "These notes are for my smart notes.",
        plan_note: null,
        appointment_id: 802,
        icds: staticIcdCodesData,
        cpts: staticIcdCodesData,
        datetime: "2023-12-30T12:33:52.615000Z",
        sign_close_ind: signCloseId,
        url: "/api/encounters/802/",
        account_id: 1,
    };
};

export let staticAppointmentData = {
    "id": 4721994,
    "patient_id": 53723,
    "user_id": null,
    "visit_reason": "Medical; Fol min -",
    "last_visit": "2024-10-04T17:58:09.816960Z",
    "location_id": 68,
    "practitioner_name": null,
    "checkin_status": "booked",
    "patient_type": null,
    "datetime": "2024-10-31T21:30:00.000000Z",
    "url": "http://127.0.0.1:8000/api/appointments/abc/",
    "account_id": 1,
    "smart_summary_text": "### Lark Mint",
    "smart_summary_data": null,
    "smart_checklist_data": null,
    "etl_last_updated": "2024-09-19T18:25:51.903000Z"
}
export const ckdVals = {
    "CKD-1": {num: 6, time: "months"},
    "CKD-2": {num: 5, time: "months"},
    "CKD-3": {num: 3, time: "months"},
    "CKD-4": {num: 2, time: "months"},
    "CKD-5": {num: 1, time: "months"},
};

export const frequencyOptions = [
    {label: "Once a day", value: "Once a day"},
    {label: "Twice a day", value: "Twice a day"},
    {label: "Thrice a day", value: "Thrice a day"},
];

export let staticMedicineData = [
    {
        id: 10,
        drug_name: "Antiplatelet Agents",
        purpose:
            "stop cells in the blood (platelets) from sticking together and forming a clot",
        account_id: 1,
    },
    {
        id: 9,
        drug_name: "Insulin or Oral Antidiabetic Medications",
        purpose:
            "To help lower blood glucose levels (and your A1c) to a healthy range",
        account_id: 1,
    },
    {
        id: 8,
        drug_name: "Antihypertensive Medications",
        purpose: "Used to treat hypertension (high blood pressure)",
        account_id: 1,
    },
    {
        id: 7,
        drug_name: "Statins",
        purpose: "lower cholesterol",
        account_id: 1,
    },
    {
        id: 6,
        drug_name: "Calcitriol or Vitamin D Analogues",
        purpose:
            "hyperproliferative diseases, immune dysfunction, endocrine disorders, and metabolic bone diseases",
        account_id: 1,
    },
    {
        id: 5,
        drug_name: "Phosphate Binders",
        purpose:
            "Used to reduce positive phosphate balance and to lower serum phosphate levels for people with chronic kidney disease (CKD)",
        account_id: 1,
    },
    {
        id: 4,
        drug_name: "Erythropoiesis-Stimulating Agents (ESAs)",
        purpose: "Stimulate the bone marrow to make red blood cells",
        account_id: 1,
    },
    {
        id: 2,
        drug_name: "Angiotensin II Receptor Blockers (ARBs)",
        purpose: "Used to treat high blood pressure and heart failure.",
        account_id: 1,
    },
    {
        id: 1,
        drug_name: "Angiotensin-Converting Enzyme Inhibitors (ACE Inhibitors)",
        purpose: "Used to treat and manage hypertension",
        account_id: 1,
    },
];

export const staticPrescriptionsData = [
    {
        "id": "2db41880-e02f-4f95-acce-8fac26b008b0",
        "patient_id": 2142,
        "prescribed_on": "2024-09-27T12:30:37.461000Z",
        "discontinued_on": null,
        "drug_name": "Abacavir Sulfate Oral Solution 20 MG/ML",
        "dosage": "",
        "dispensable_drug_id": 38548,
        "dispense_unit_id": 4,
        "instructions": "dsad",
        "encounter_id": 1530,
        "pharmacy_id": 5,
        "sent_to_pharmacy": false,
        "order_error_message": "",
        "account_id": 1
    }
]

export let graphStaticApiFullFormValue = {
    BPS: "systolic",
    BPD: "diastolic",
    HR: "pulse",
    RR: "respiration_rate",
    WT: "weight",
    Temp: "temperature",
    Spo2: "spo2",
};

export let vitalGraphStaticColors = [
    "#8e44ad",
    "#e74c3c",
    "#e67e22",
    "#1abc9c",
    "#34495e",
    "#0652DD",
    "#FDA7DF",
];
export let graphStaticColors = {
    BPS: "#8e44ad",
    BPD: "#e74c3c",
    HR: "#e67e22",
    RR: "#1abc9c",
    WT: "#34495e",
    Temp: "#0652DD",
    Spo2: "#FDA7DF",
};

export const condtionalStyling = (incoming) => {
    return {
        justifyContent: incoming ? "flex-end" : "flex-start",
        textAlign: incoming ? "end" : "start",
        borderRadius: incoming ? "12px 12px 0px 12px" : "12px 12px 12px 0px",
        name: incoming ? "You" : "Saya",
        background: incoming ? "#F0F0F0" : "#f1e8ff",
        message: incoming
            ? "What's our clinic's revenue-to-expense ratio, especially in dialysis reimbursements and medication costs, compared to industry benchmarks?"
            : "Your current revenue-to-expense ratio stands at 2.5, outperforming the industry average by 0.3 points, with notable efficiency in dialysis reimbursements and medication costs demonstrating a 15% reduction compared to last quarter.",
    };
};
export const financialChartOptions = [
    {
        label: "6 months",
        value: "6 months",
    },
    {
        label: "3 months",
        value: "3 months",
    },
    {
        label: "1 months",
        value: "1 months",
    },
];
export const followUpOptions = [
    {value: "24 hours", label: "Within 24 hours"},
    {value: "1 week", label: "After 1 week"},
    {value: "2 week", label: "After 2 weeks"},
    {value: "3 week", label: "After 3 weeks"},
    {value: "4 week", label: "After 4 weeks"},
    {value: "5 week", label: "After 5 weeks"},
    {value: "1 month", label: "After 1 month"},
    {value: "2 month", label: "After 2 months"},
    {value: "3 month", label: "After 3 months"},
    {value: "4 month", label: "After 4 months"},
    {value: "5 month", label: "After 5 months"},
    {value: "6 month", label: "After 6 months"},
];

const revenueData = [
    234844, 348126, 325175, 398124, 299134, 334134, 365126, 310590, 375176,
    288389, 350351, 388964,
];
const expenseData = [
    248852, 327126, 284125, 374155, 287877, 305125, 298135, 285135, 325161,
    256147, 300805, 317015,
];

const minValue = Math.min(...revenueData, ...expenseData);
const maxValue = Math.max(...revenueData, ...expenseData);

const padding = 20;

const yaxisConfig = {
    min: Math.floor(minValue / 10) * 10,
    max: Math.ceil((maxValue + padding) / 10) * 10,
    tickAmount: 6,
    labels: {
        formatter: function (value) {
            const inThousands = value / 1000;
            return "$" + inThousands.toFixed(0) + "k";
        },
    },
};

const newPatientData = [86, 97, 104, 58, 67, 84, 38, 71, 47, 77, 34, 94];

const minValue1 = Math.min(...newPatientData);
const maxValue2 = Math.max(...newPatientData);

const padding1 = 10;

const yaxisConfig1 = {
    min: Math.floor(minValue1 / 10) * 10,
    max: Math.ceil((maxValue2 + padding1) / 10) * 10,
    tickAmount: 5,
    labels: {
        formatter: (val) => `${val}`,
    },
};

export let vitalsTrendsStaticData = [{
    "id": 7298,
    "patient_id": 947,
    "date_time": "2023-11-30T22:46:52.000000Z",
    "height": null,
    "height_unit": null,
    "weight": 185,
    "weight_unit": "[lb_av]",
    "systolic": 114,
    "diastolic": 74,
    "bmi": null,
    "systolic_unit": "mm[Hg]",
    "diastolic_unit": "mm[Hg]",
    "bmi_unit": null,
    "temperature_unit": null,
    "pulse_unit": "/min",
    "respiration_rate_unit": null,
    "spo2_unit": "%",
    "fiO2": 21,
    "fiO2_unit": "%",
    "bsa": null,
    "bsa_unit": null,
    "urine_output": null,
    "pain_assessment": null,
    "edema_assessment": null,
    "other_observations": null,
    "temperature": null,
    "pulse": 63,
    "respiration_rate": null,
    "spo2": 92,
    "account_id": 1
}]

export const TrendsChartData = [
    {
        title: "Total Revenue Posted in a year",
        subTitle: "Revenue Posted",
        series: [
            {
                name: "Revenue",
                data: [],
            },
            {
                name: "Expense",
                data: [],
            },
        ],
        options: {
            chart: {
                height: 350,
                type: "line",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                },
            },
            colors: ["#3475DC", "#F14425"],
            dataLabels: {
                enabled: true,
                formatter: function (value) {
                    const inThousands = value / 1000;
                    return "$" + inThousands.toFixed(0) + "k";
                },
            },
            stroke: {
                curve: "smooth",
            },
            markers: {
                size: 4,
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            yaxis: yaxisConfig,
            legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                floating: false,
                offsetY: 12,
                offsetX: -10,
                itemMargin: {
                    horizontal: 5,
                    vertical: 7,
                },
            },
            tooltip: {
                y: {
                    formatter: function (value) {
                        return "$" + value;
                    },
                },
            },
        },
    },
    {
        donutSeries: [0, 0],
        donutOptions: {
            chart: {
                type: "donut",
                width: "100%",
            },
            dataLabels: {
                enabled: false,
            },
            colors: ["#CC3E3E", "#36ad70"],
            plotOptions: {
                pie: {
                    customScale: 1,
                    donut: {
                        labels: {
                            show: false,
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                fontSize: "30px",
                                color: "#FF0000",
                                formatter: function (val) {
                                    return "0%";
                                },
                            },
                        },
                    },
                },
            },
            tooltip: {
                y: {
                    formatter: function (val, {seriesIndex}) {
                        if (seriesIndex === 0) {
                            return "0%";
                        } else {
                            return "0%";
                        }
                    },
                },
            },
            labels: ["Expense", "Net Profit"],
            legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                fontSize: "14px",
            },
            states: {
                hover: {
                    filter: {
                        type: "none",
                    },
                },
            },
        },
    },
    {
        title: "Total Encounters in a year",
        subTitle: "Encounters",
        revenueOptionSeries: [
            {
                name: "Total Encounters in a year",
                data: [],
            },
        ],
        revenueOptions: {
            chart: {
                height: 350,
                type: "line",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                },
                dataLabels: {
                    enabled: true,
                },
            },
            colors: ["#3475DC"],
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: "smooth",
            },
            markers: {
                size: 1,
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            yaxis: {
                min: 1500,
                max: 2100,
                labels: {
                    formatter: function (val) {
                        return val;
                    },
                },
            },
            labels: ["Total Encounters in a year"],
            legend: {
                show: true,
                showForSingleSeries: true,
                showForNullSeries: true,
                showForZeroSeries: true,
                position: "bottom",
                horizontalAlign: "center",
                offsetY: 12,
                offsetX: -10,
                itemMargin: {
                    horizontal: 5,
                    vertical: 7,
                },
            },
            fill: {
                type: "solid",
                colors: ["transparent"],
            },
        },
    },
    {
        title: "Trend",
        series: [75, 25],
        donutOptions: {
            chart: {
                type: "donut",
                width: "100%",
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: false,
            },
            colors: ["#3475DC", "#ccc"],
            plotOptions: {
                pie: {
                    customScale: 1,
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                fontSize: "30px",
                                color: "#3475DC",
                                formatter: function () {
                                    return "0%";
                                },
                            },
                        },
                    },
                },
            },
            labels: ["75% Active"],
            states: {
                hover: {
                    filter: {
                        type: "none",
                    },
                },
            },
        },
    },
    {
        title: "Total Patients Seen in a year",
        subTitle: "Patients Seen",
        pateintSeenSeries: [
            {
                name: "Total Patients Seen in a year",
                data: [],
            },
        ],
        pateintSeenOptions: {
            chart: {
                height: 350,
                type: "line",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                },
                dataLabels: {
                    enabled: false,
                },
            },
            colors: ["#3475DC", "#ee2400"],
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: "smooth",
            },
            markers: {
                size: 1,
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            yaxis: {
                min: 700,
                max: 1300,
                labels: {
                    formatter: function (val) {
                        return val;
                    },
                },
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                showForNullSeries: true,
                showForZeroSeries: true,
                position: "bottom",
                horizontalAlign: "center",
                floating: false,
                offsetY: 12,
                offsetX: -10,
                itemMargin: {
                    horizontal: 5,
                    vertical: 7,
                },
            },
            fill: {
                type: "solid",
                colors: ["transparent"],
            },
        },
    },
    {
        title: "Total New Patients in a year",
        subTitle: "New Patients",
        newPatientseries: [
            {
                name: "Total New Patients in a year",
                data: [],
            },
        ],
        newPatientOptions: {
            chart: {
                height: 350,
                type: "line",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                },
                toolbar: {
                    show: false,
                },
                dataLabels: {
                    enabled: false,
                },
            },
            colors: ["#3475DC"],
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: "smooth",
            },
            markers: {
                size: 1,
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            yaxis: yaxisConfig1,
            legend: {
                show: true,
                showForSingleSeries: true,
                showForNullSeries: true,
                showForZeroSeries: true,
                position: "bottom",
                horizontalAlign: "center",
                floating: false,
                offsetY: 12,
                offsetX: -10,
                itemMargin: {
                    horizontal: 5,
                    vertical: 7,
                },
            },
        },
    },
];

//Encounters Revenue
export const encounterRevenueChartData = [
    {
        donutSeriesTwo: [0, 0],
        donutOptionsTwo: {
            chart: {
                type: "donut",
                width: "100%",
            },
            dataLabels: {
                enabled: false,
            },
            colors: ["#36ad70", "#ccc"],
            plotOptions: {
                pie: {
                    customScale: 1,
                    donut: {
                        labels: {
                            show: false,
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                fontSize: "30px",
                                color: "#FF0000",
                                formatter: function (val) {
                                    return val + "%";
                                },
                            },
                        },
                    },
                },
            },
            tooltip: {
                y: {
                    formatter: function (val, {seriesIndex}) {
                        if (seriesIndex === 0) {
                            return "0%";
                        } else {
                            return "0%";
                        }
                    },
                },
            },
            labels: ["Growth ", "Previous Encounters"],
            legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                fontSize: "14px",
            },
            states: {
                hover: {
                    filter: {
                        type: "none",
                    },
                },
            },
        },
    },
];

export const totalPatientDonutChart = [
    {
        donutSeriesOne: [0, 0],
        donutOptionsOne: {
            chart: {
                type: "donut",
                width: "100%",
            },
            dataLabels: {
                enabled: false,
            },
            colors: ["#36ad70", "#ccc"],
            plotOptions: {
                pie: {
                    customScale: 1,
                    donut: {
                        labels: {
                            show: false,
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                fontSize: "30px",
                                color: "#3475DC",
                                formatter: function (val) {
                                    return val + "%";
                                },
                            },
                        },
                    },
                },
            },
            tooltip: {
                y: {
                    formatter: function (val, {seriesIndex}) {
                        if (seriesIndex === 0) {
                            return "0%";
                        } else {
                            return "0%";
                        }
                    },
                },
            },
            labels: ["Growth ", "Previous year patients"],
            legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                fontSize: "14px",
            },
            states: {
                hover: {
                    filter: {
                        type: "none",
                    },
                },
            },
        },
    },
];

export const totalNewPatientDonutChart = [
    {
        donutSeriesOne: [0, 0],
        donutOptionsOne: {
            chart: {
                type: "donut",
                width: "100%",
            },
            dataLabels: {
                enabled: false,
            },
            colors: ["#3475DC", "#ccc"],
            plotOptions: {
                pie: {
                    customScale: 1,
                    donut: {
                        labels: {
                            show: false,
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                fontSize: "30px",
                                color: "#3475DC",
                                formatter: function (val) {
                                    return val + "%";
                                },
                            },
                        },
                    },
                },
            },
            tooltip: {
                y: {
                    formatter: function (val, {seriesIndex}) {
                        if (seriesIndex === 0) {
                            return "0%";
                        } else {
                            return "0%";
                        }
                    },
                },
            },
            labels: ["Growth ", "Previous year patients"],
            legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                verticalAlign: "middle",
                fontSize: "14px",
            },
            states: {
                hover: {
                    filter: {
                        type: "none",
                    },
                },
            },
        },
    },
];

// SummaryRevenueArea Chart Static Data

export const revenuePerPatientSeriesData = [
    {
        name: "",
        data: [],
    },
    {
        name: "",
        data: [],
    },
];
export const revenuePerPatientOptions = {
    chart: {
        type: "area",
        height: 320,
        responsive: true,
        toolbar: {
            show: false,
        },
    },
    legend: {
        show: false,
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: "smooth",
        width: 1,
        colors: ["#3475DC", "#4FD1C5"],
    },
    xaxis: {
        categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
    },
    yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
        labels: {
            formatter: function (val) {
                return `$${val}k`;
            },
        },
    },
    width: "100%",
};

//
export const revenuePerEncounterSeriesData = [
    {
        name: "",
        data: [],
    },
    {
        name: "",
        data: [],
    },
];
export const revenuePerEncounterDataOptions = {
    chart: {
        type: "area",
        height: 320,
        responsive: true,
        toolbar: {
            show: false,
        },
    },
    legend: {
        show: false,
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: "smooth",
        width: 1,
        colors: ["#3475DC", "#4FD1C5"],
    },
    xaxis: {
        categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
    },
    yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
        labels: {
            formatter: function (val) {
                return `$${val}k`;
            },
        },
    },
    width: "100%",
};

// Total Encounters Chart Data
export const totalEncountersSeriesData = [
    {
        name: "",
        data: [],
    },
    {
        name: "",
        data: [],
    },
];
export const totalEncountersDataOptions = {
    chart: {
        type: "area",
        height: 320,
        responsive: true,
        toolbar: {
            show: false,
        },
    },
    legend: {
        show: false,
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: "smooth",
        width: 1,
        colors: ["#3475DC", "#4FD1C5"],
    },
    xaxis: {
        categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
    },
    yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
    },
    width: "100%",
};

// Encounters Per Patient Chart Data
export const encountersPerPatientSeriesData = [
    {
        name: "",
        data: [],
    },
    {
        name: "",
        data: [],
    },
];
export const encountersPerPatientDataOptions = {
    chart: {
        type: "area",
        height: 320,
        responsive: true,
        toolbar: {
            show: false,
        },
    },
    legend: {
        show: false,
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: "smooth",
        width: 1,
        colors: ["#3475DC", "#4FD1C5"],
    },
    xaxis: {
        categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
    },
    yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
    },
    width: "100%",
};
// Encounters Total Patient Chart Data
export const totalPatientSeriesData = [
    {
        name: "",
        data: [],
    },
    {
        name: "",
        data: [],
    },
];
export const totalPatientSeriesDataOptions = {
    chart: {
        type: "area",
        height: 320,
        responsive: true,
        toolbar: {
            show: false,
        },
    },
    legend: {
        show: false,
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: "smooth",
        width: 1,
        colors: ["#3475DC", "#4FD1C5"],
    },
    xaxis: {
        categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
    },
    yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
    },
    width: "100%",
};

// Encounters Total Patient Chart Data
export const newPatientSeriesData = [
    {
        name: "",
        data: [],
    },
    {
        name: "",
        data: [],
    },
];
export const newPatientOptionsData = {
    chart: {
        type: "area",
        height: 320,
        responsive: true,
        toolbar: {
            show: false,
        },
    },
    legend: {
        show: false,
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: "smooth",
        width: 1,
        colors: ["#3475DC", "#4FD1C5"],
    },
    xaxis: {
        categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
    },
    yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
    },
    width: "100%",
};

//RevenueAreaChart Chart Data

export const revnueAreaChart = [
    {
        title: "Revenue Per Patient",
        series: [
            {
                name: "",
                data: [],
            },
            {
                name: "",
                data: [],
            },
        ],
        options: {
            chart: {
                type: "area",
                height: 320,
                responsive: true,
                toolbar: {
                    show: false,
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                width: 1,
                colors: ["#3475DC", "#4FD1C5"],
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 4,
                labels: {
                    formatter: function (val) {
                        return `$${val}k`;
                    },
                },
            },

            width: "100%",
        },
    },
    //
    {
        title: "Total Revenue",
        series: [
            {
                name: "",
                data: [],
            },
            {
                name: "",
                data: [],
            },
        ],
        options: {
            chart: {
                type: "area",
                height: 320,
                responsive: true,
                toolbar: {
                    show: false,
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                colors: ["#3475DC", "#4FD1C5"],
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 4,
                labels: {
                    formatter: function (val) {
                        return `$${val}k`;
                    },
                },
            },

            width: "100%",
        },
    },
    {
        title: "Revenue Per Encounter",
        series: [
            {
                name: "",
                data: [],
            },
            {
                name: "",
                data: [],
            },
        ],
        options: {
            chart: {
                type: "area",
                height: 320,
                responsive: true,
                toolbar: {
                    show: false,
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                colors: ["#3475DC", "#4FD1C5"],
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 4,
                labels: {
                    formatter: function (val) {
                        return `$${val}k`;
                    },
                },
            },

            width: "100%",
        },
    },
    {
        title: "Total Encounters",
        series: [
            {
                name: "",
                data: [],
            },
            {
                name: "",
                data: [],
            },
        ],
        options: {
            chart: {
                type: "area",
                height: 320,
                responsive: true,
                toolbar: {
                    show: false,
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                colors: ["#3475DC", "#4FD1C5"],
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 4,
            },

            width: "100%",
        },
    },
    {
        title: "Encounters Per Patient",
        series: [
            {
                name: "",
                data: [],
            },
            {
                name: "",
                data: [],
            },
        ],
        options: {
            chart: {
                type: "area",
                height: 320,
                responsive: true,
                toolbar: {
                    show: false,
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                colors: ["#3475DC", "#4FD1C5"],
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 4,
            },

            width: "100%",
        },
    },
    {
        title: "Total Patients",
        series: [
            {
                name: "",
                data: [],
            },
            {
                name: "",
                data: [],
            },
        ],
        options: {
            chart: {
                type: "area",
                height: 320,
                responsive: true,
                toolbar: {
                    show: false,
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                colors: ["#3475DC", "#4FD1C5"],
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 4,
            },

            width: "100%",
        },
    },
    {
        title: "New Patients",
        series: [
            {
                name: "",
                data: [],
            },
            {
                name: "",
                data: [],
            },
        ],
        options: {
            chart: {
                type: "area",
                height: 320,
                responsive: true,
                toolbar: {
                    show: false,
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
                colors: ["#3475DC", "#4FD1C5"],
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            yaxis: {
                min: 0,
                max: 100,
                tickAmount: 4,
            },

            width: "100%",
        },
    },
];

export const barChartSeriesData = [
    {
        name: "",
        data: [],
    },
    {
        name: "",
        data: [],
    },
];
export const barChartOptionsData = {
    chart: {
        type: "bar",
        height: 300,
        toolbar: {
            show: false,
        },
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: "70%",
            borderRadius: [5],
        },
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
    },

    legend: {
        show: false,
    },

    xaxis: {
        categories: [
            "jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
        ],
    },
    yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
        labels: {
            formatter: function (val) {
                if (
                    val === 0 ||
                    val === 100 ||
                    val === 25 ||
                    val === 50 ||
                    val === 75
                ) {
                    return val + "k"; // Append 'k' to the values 0 and 100
                } else {
                    return val;
                }
            },
        },
    },
    fill: {
        opacity: 1,
        colors: ["#F39320", "#4FD1C5"],
    },
    tooltip: {
        custom: function ({series, seriesIndex, dataPointIndex, w}) {
            const currentDate = new Date().toLocaleDateString("en-US", {
                month: "long",
                day: "2-digit",
                year: "numeric",
            });
            const value = w.globals.series[seriesIndex][dataPointIndex];
            return `<div style="padding: 18px; border-radius:0px",background: red !important; color: black;"><p style="font-size: 16px; font-weight: 600;">${currentDate}</p></div>`;
        },
    },
};

export const getFinancialsSuggestionsAns = (url, question) => {
    const data = {
        "/financials": [
            {
                que: "Recommendations for optimizing physician productivity based on active patient count and revenue per physician?",
                ans: "Considering our active patient count of 129 per month and average revenue per physician of $3,300 per day, we could explore strategies to enhance patient engagement or streamline administrative tasks. For instance, implementing telemedicine options or optimizing appointment scheduling to reduce wait times and improve patient throughput could increase physician productivity by 15-20%.",
            },
            {
                que: "Are there any noticeable trends or patterns in revenue per service type over the last year?",
                ans: "Analyzing trends in revenue per service type reveals interesting insights. While revenue from Early CKD and ESRD services remained steady, we observed a 15-20% increase in revenue from Stage 5 CKD services. This trend indicates a growing demand for specialized care in advanced CKD stages, presenting opportunities for targeted service expansion and marketing efforts.",
            },
            {
                que: "Services to focus on expanding for revenue maximization?",
                ans: "Analyzing revenue per service type reveals opportunities for maximizing revenue potential. For instance, data shows a significant increase in revenue from specialized services like ESRD treatment. By focusing on expanding these high-margin services and promoting related ancillary services, such as nutritional counseling or home dialysis programs, we can capitalize on revenue opportunities. Additionally, targeting patient populations with specific healthcare needs, such as diabetic patients at risk for kidney disease, can drive demand for related services and enhance revenue growth.",
            },
            {
                que: "Analyze our revenue data and patient demographics for targeted marketing or outreach?",
                ans: "Analyzing revenue data alongside patient demographics uncovers opportunities for targeted marketing initiatives. For example, data suggests a growing demand for nephrology services among younger adults with risk factors for kidney disease. By tailoring marketing messages and outreach campaigns to resonate with this demographic, we can attract new patients and increase revenue. Implementing paid ads, such as social media advertising can effectively reach and engage our target audience.",
            },
        ],
        "/financials/revenue/summary": [
            {
                que: "Key factors driving the increase in total revenue compared to the previous year?",
                ans: "The increase in total revenue compared to the previous year can be attributed to several factors. For instance, there was a 15% increase in patient volume across all service types, resulting in higher revenue generation. Additionally, improvements in revenue cycle management processes led to a 10% reduction in outstanding balances, contributing to overall revenue growth.",
            },
            {
                que: "Trends in revenue generation month over month for the current year compared to the previous year?",
                ans: "Analyzing revenue generation month over month, we observe a consistent upward trend in the current year compared to the previous year. This indicates sustained growth in revenue generation over time, potentially reflecting increased patient volume or improved billing processes.",
            },
            {
                que: "Insights into revenue contribution from different payers like Medicare, private insurance, and self-pay patients, and its evolution over time?",
                ans: "Certainly. The revenue contribution from Medicare remains the largest, accounting for 60% of total revenue, followed by private insurance at 30%, and self-pay patients at 10%. Over time, we've observed a gradual increase in revenue from private insurance, indicating potential shifts in payer demographics or reimbursement rates.",
            },
            {
                que: "Correlation between patient acuity levels and revenue generation? Impact on resource allocation strategies?",
                ans: "Examining the correlation between patient acuity levels and revenue generation reveals notable trends. Patients with higher acuity levels, such as those with advanced CKD stages or comorbid conditions, tend to generate higher revenue due to increased service utilization and complexity of care. By allocating resources based on patient acuity levels, we can ensure efficient resource utilization and prioritize high-value care delivery. Additionally, implementing care coordination initiatives for complex patients can further enhance revenue generation and patient outcomes.",
            },
            {
                que: "Any trends in accounts receivable aging and bad debt write-offs? Strategies to improve cash flow management?",
                ans: "Analyzing trends in accounts receivable aging and bad debt write-offs reveals important insights into cash flow management. While our accounts receivable aging remains within industry norms, we've observed a slight increase in bad debt write-offs due to delayed patient payments. Implementing proactive patient financial counseling and payment plans has helped mitigate this trend, reducing bad debt write-offs by 10% compared to last year. By optimizing revenue cycle workflows and enhancing patient communication strategies, we can further improve cash flow management and minimize revenue loss.",
            },
        ],
        "/financials/revenue/PatientMonth": [
            {
                que: "Key insights from the Target vs. Actual patient visits comparison graph? Strategies for enhancing patient visit forecasting?",
                ans: "Analyzing the comparison graph, we notice that we generally meet or exceed expected patient visit targets. However, there are occasional discrepancies, particularly in certain months or CKD stages. For instance, in November, we fell short of the expected visit target for early-stage CKD patients by 15%. To improve forecasting accuracy, we'll refine our predictive models using historical visit data and implement proactive patient engagement strategies, such as appointment reminders and personalized outreach campaigns, to minimize no-show rates and optimize patient visit adherence.",
            },
            {
                que: "Insights on new patient trends and their impact on practice growth?",
                ans: "Examining new patient influx, we've noticed a consistent growth trend over the past six months, with an average of 100 new patients per month. This 10% increase correlates with targeted marketing efforts and expanded referral networks. This influx significantly contributes to practice growth, fostering a robust patient base and enhancing community outreach efforts.",
            },
            {
                que: "Average monthly patient volume per provider across clinic locations? Impact on operational efficiency?",
                ans: "Analyzing the average monthly patient volume per provider across clinic locations reveals variations in operational efficiency. For instance, our downtown clinic sees an average of 200 patients per provider per month, while suburban clinics average 150 patients. This discrepancy may indicate differences in patient demographics, appointment availability, or provider availability. By optimizing scheduling protocols and resource allocation strategies, we can enhance operational efficiency and ensure equitable patient access across all locations.",
            },
            {
                que: "Trends in patient visit volumes during peak vs. off-peak hours? Impact on clinic operations?",
                ans: "Analyzing trends in patient visit volumes during peak vs. off-peak hours reveals important considerations for clinic operations and staffing. We observe that peak hours, typically mid-morning and late afternoon, experience higher patient volumes, requiring additional staffing and resource allocation to maintain quality care delivery and minimize wait times. Conversely, off-peak hours, such as early morning or late evening, have lower patient volumes, allowing for resource optimization and staff training opportunities. Understanding these trends enables us to optimize clinic workflows and enhance patient access during high-demand periods.",
            },
        ],
    };
    const findPageData = data?.[url];
    return findPageData?.find(({que}) => que === question);
};
export const getFinancialsSuggestionsData = (url) => {
    const data = {
        "/financials": [
            {
                que: "Recommendations for optimizing physician productivity based on active patient count and revenue per physician?",
            },
            {
                que: "Are there any noticeable trends or patterns in revenue per service type over the last year?",
            },
            {
                que: "Services to focus on expanding for revenue maximization?",
            },
            {
                que: "Analyze our revenue data and patient demographics for targeted marketing or outreach?",
            },
        ],
        "/financials/revenue/summary": [
            {
                que: "Key factors driving the increase in total revenue compared to the previous year?",
            },
            {
                que: "Trends in revenue generation month over month for the current year compared to the previous year?",
            },
            {
                que: "Insights into revenue contribution from different payers like Medicare, private insurance, and self-pay patients, and its evolution over time?",
            },
            {
                que: "Correlation between patient acuity levels and revenue generation? Impact on resource allocation strategies?",
            },
            {
                que: "Any trends in accounts receivable aging and bad debt write-offs? Strategies to improve cash flow management?",
            },
        ],
        "/financials/revenue/PatientMonth": [
            {
                que: "Key insights from the Target vs. Actual patient visits comparison graph? Strategies for enhancing patient visit forecasting?",
            },
            {
                que: "Insights on new patient trends and their impact on practice growth?",
            },
            {
                que: "Average monthly patient volume per provider across clinic locations? Impact on operational efficiency?",
            },
            {
                que: "Trends in patient visit volumes during peak vs. off-peak hours? Impact on clinic operations?",
            },
        ],
    };
    return data?.[url] ?? [];
};

export let medicinesEncounterDataOptionsStatic = {
    id: 8279,
    patient_id: 647,
    prescribed_on: "2024-03-22T17:30:00.000000Z",
    discontinued_on: null,
    medicine_id: 26,
    drug_name: "Renvela 800mg",
    status_ind: "1",
    instructions: "3 times with each meal",
    dosage: "800mg after meal",
    dispense: "10",
    encounter_id: 967,
    account_id: 1,
    pharmacy_id: null,
    external_ids: null,
};
export let labsByEncounterDataStatic = [
    {
        id: 4539,
        datetime: "2024-02-29T13:09:56.686084Z",
        instructions: null,
        patient_id: 647,
        practitioner_id: 143,
        lab_location_id: 4,
        lab_provider_id: 1,
        test_id: 23,
        encounter_id: 967,
        url: "http://127.0.0.1:8000/api/laborders/4539/",
        account_id: 1,
        lab_location: {
            id: 4,
            lab_provider_id: 1,
            lab_location_name: "Pocatello",
            address: "444 Hospital Way, Building 100, Suite 224 Pocatello, Idaho",
            phone: "208-529-8330",
            account_id: 1,
        },
        test: {
            id: 23,
            group_test_name: "Lipid profile",
            test_name: "Lipid profile",
            test_code: "Lipid profile",
            test_description: "Lipid profile",
            group_test_order: 5,
            test_name_order: 1,
            account: "http://127.0.0.1:8000/api/accounts/1/",
            account_id: 1,
        },
        lab_provider: {
            id: 1,
            lab_provider_name: "Express Lab",
            address: "3910 Washington Parkway, Idaho Falls, Idaho",
            phone: "208-529-8330",
            url: "http://127.0.0.1:8000/api/labproviders/1/",
            account_id: 1,
        },
    },
];

export let labsDataStatic = [
    {label: "pill", valud: 1, instructions: "one at a time"},
];

export let medicineDataStatic = [
    {label: "pill", valud: 1, instructions: "one at a time"},
];

export const OptimalStartPercentageChartSeries = [
    {
        name: "Optimal Start",
        data: [
            1028, 887, 1191, 1022, 1222, 1079, 1021, 1201, 1082, 1003, 1135, 1052,
        ],
    },
];

export const OptimalStartPercentageChartOptions = {
    chart: {
        type: "area",
        height: 350,
        toolbar: {
            show: false,
        },
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: "smooth",
        width: 1,
        colors: ["#4E61A7"],
    },
    fill: {
        type: "gradient",
        gradient: {
            shadeIntensity: 0.8,
            type: "vertical",
            gradientToColors: ["#1E3EE0", "#566BC7", "#D9D9D9"],
            opacityFrom: 0.8,
            opacityTo: 0.1,
            stops: [0, 72, 100],
        },
    },
    xaxis: {
        categories: [
            "23-Jan",
            "23-Feb",
            "23-Mar",
            "23-Apr",
            "23-May",
            "23-Jun",
            "23-Jul",
            "23-Aug",
            "23-Sep",
            "23-Oct",
            "23-Nov",
            "23-Dec",
        ],
    },
    colors: ["#4E61A7"],
    yaxis: {min: 830, max: 1280, tickAmount: 5},
    legend: {
        show: false,
    },
    tooltip: {
        x: {
            format: "",
        },
    },
};
export const LateStageCkdVisitIntervalSeries = [
    {
        name: "Late-Stage CKD Visit Interval",
        data: [150, 138, 142, 143, 141, 120, 145, 134, 139, 126, 142, 151],
    },
];
export const HospitalToOfficeFollowUpTableColumns = [
    {label: "Hospital To Office Follow Up", align: "left", field: "name"},
    {label: "Current Year", align: "center", field: "currentYear"},
    {label: "Prior Year", align: "center", field: "priorYear"},
    {
        label: "Percentage change (Current year vs Last Year)",
        align: "center",
        field: "trend",
    },
];
export const HospitalToOfficeFollowUpTableRows = [
    {
        name: "Hospital To Office Follow Up",
        currentYear: "0.55",
        priorYear: "0.62",
        trend: "-7.84%",
    },
];
export const NewStartWithHospitalWith30daysPriorTableColumns = [
    {
        label: "New Start With Hosp. With 30-days Prior",
        align: "left",
        field: "name",
    },
    {label: "Current Year", align: "center", field: "currentYear"},
    {label: "Prior Year", align: "center", field: "priorYear"},
    {
        label: "Percentage change (Current year vs Last Year)",
        align: "center",
        field: "trend",
    },
];
export const NewStartWithHospitalWith30daysPriorTableRows = [
    {
        name: "New Start With Hospital With 30-days Prior",
        currentYear: "65.26%",
        priorYear: "70.81%",
        trend: "-7.84%",
    },
];
export const TimelyReferralsTableRows = [
    {
        name: "Timely Referrals",
        currentYear: "23.94%",
        priorYear: "29.19%",
        trend: "-17.99%",
    },
];
export const TimelyReferralsTableColumns = [
    {label: "Timely Referrals", align: "left", field: "name"},
    {label: "Current Year", align: "center", field: "currentYear"},
    {label: "Prior Year", align: "center", field: "priorYear"},
    {
        label: "Percentage change (Current year vs Last Year)",
        align: "center",
        field: "trend",
    },
];
export const LateStageCkdWaitTimeTableRows = [
    {
        name: "Late-Stage CKD Visit Interval",
        currentYear: "0.55",
        priorYear: "0.62",
        trend: "10.64%",
    },
];
export const LateStageCkdWaitTimeTableColumns = [
    {label: "Late-Stage CKD Wait Time", align: "left", field: "name"},
    {label: "Current Year", align: "center", field: "currentYear"},
    {label: "Prior Year", align: "center", field: "priorYear"},
    {
        label: "Percentage change (Current year vs Last Year)",
        align: "center",
        field: "trend",
    },
];
export const LateStageCkdVisitIntervalTableRows = [
    {
        name: "Late-Stage CKD Visit Interval",
        currentYear: "0.55",
        priorYear: "0.62",
        trend: "-11.29%",
    },
];
export const LateStageCkdVisitIntervalTableColumns = [
    {label: "Late-Stage CKD Visit Interval", field: "name", align: "left"},
    {label: "Current Year", field: "currentYear", align: "center"},
    {label: "Prior Year", field: "priorYear", align: "center"},
    {
        label: "Percentage Change (Current year vs Last Year)",
        field: "trend",
        align: "center",
    },
];
export const RIOCTableColumns = [
    {label: "Metric", field: "name", align: "left"},
    {label: "Basic RIOC", field: "basicRIOC", align: "center"},
    {label: "New RIOC", field: "newRIOC", align: "center"},
    {label: "Delta", field: "delta", align: "center"},
    {
        label: "RIOC Improvement Per5% Improvement in Matric",
        field: "rioc",
        align: "center",
    },
];
export const EsrdHomeDialysisTableColumns = [
    {label: "Home Dialysis", field: "name", align: "left"},
    {label: "Current Year", field: "currentYear", align: "center"},
    {label: "Prior Year", field: "priorYear", align: "center"},
    {
        label: "Trend",
        field: "trend",
        align: "center",
        render: (data) => <TrendTableChart data={data}/>,
    },
];
export const EsrdPatientRetentionTableColumns = [
    {label: "ESRD Patient Retention", field: "name", align: "left"},
    {label: "Current Year", field: "currentYear", align: "center"},
    {label: "Prior Year", field: "priorYear", align: "center"},
    {
        label: "Trend",
        field: "trend",
        align: "center",
        render: (value) => <TrendTableChart data={value}/>,
    },
];
export const HomeDialysisTableColumns = [
    {label: "New Dialysis Starts", field: "name", align: "left"},
    {label: "Current Year", field: "currentYear", align: "center"},
    {label: "Prior Year", field: "priorYear", align: "center"},
    {
        label: "Trend",
        field: "trend",
        align: "center",
        render: (value) => <TrendTableChart data={value}/>,
    },
];
export const ActiveEsrdPatientTableColumns = [
    {label: "Active ESRD Patients", align: "left", field: "name"},
    {label: "Current Year", align: "center", field: "currentYear"},
    {label: "Prior Year", align: "center", field: "priorYear"},
    {
        label: "Trend",
        align: "center",
        field: "trend",
        render: (data) => <TrendTableChart data={data}/>,
    },
];
export const ActiveESRDPatientsBillingColumns = [
    {label: "Active ESRD Patients (Billing)", field: "EarlyCkd", align: "left"},
    {label: "Base", field: "ESRD", align: "center"},
    {label: "Reactivated", field: "NonESRD", align: "center"},
    {label: "New", field: "Stage4CKD", align: "center"},
    {
        label: "Overall Total",
        field: "Stage5CKD",
        align: "center",
    },
];
export const PatientRosterColumns = [
    {label: "Stage Name", field: "EarlyCkd", align: "left"},
    {label: "Patient Number", field: "ESRD", align: "center"},
    {label: "Patient Name", field: "NonESRD", align: "left"},
    {label: "Zip Code", field: "Stage4CKD", align: "center"},
    {
        label: "Current Month Billed Activity",
        field: "Stage5CKD",
        align: "center",
    },
    {
        label: "Last Location",
        field: "location",
        align: "left",
    },
];
export const TotalPatientsByStageColumns = [
    {label: "Early CKD", field: "EarlyCkd", align: "left"},
    {label: "ESRD", field: "ESRD", align: "center"},
    {label: "Non-ESRD", field: "NonESRD", align: "center"},
    {label: "Stage 4-CKD", field: "Stage4CKD", align: "center"},
    {
        label: "Stage 5-CKD",
        field: "Stage5CKD",
        align: "center",
    },
];
export const CKDPatientsOutflowColumns = [
    {label: "CKD Patients Outflow", field: "EarlyCkd", align: "left"},
    {label: "Current Year", field: "ESRD", align: "center"},
    {label: "Prior Year", field: "NonESRD", align: "left"},
    {label: "Percentage Change", field: "Stage4CKD", align: "center"},
];

export const CKDPatientsInflowColumns = [
    {label: "CKD Patients Inflow", field: "EarlyCkd", align: "left"},
    {label: "Current Year", field: "ESRD", align: "center"},
    {label: "Prior Year", field: "NonESRD", align: "left"},
    {label: "Percentage Change", field: "Stage4CKD", align: "center"},
];

export const ListofPtwithAlbuminunderColumns = [
    {label: "Patient Id", field: "PatientId", align: "left"},
    {label: "Last Name", field: "LastName", align: "center"},
    {label: "DOB", field: "DOB", align: "left"},
    {label: "Office Location", field: "OfficeLocation", align: "center"},
    {label: "Physician Name", field: "PhysicianName", align: "center"},
    {label: "Insurance Type", field: "InsuranceType", align: "center"},
];

export const NewStarterRoasterTableColumns = [
    {label: "Patient Id", field: "PatientId", align: "left"},
    {label: "Patient Name", field: "LastName", align: "center"},
    {label: "Date of Birth", field: "DOB", align: "left"},
    {label: "Office Provider", field: "OfficeLocation", align: "center"},
    {label: "Location", field: "PhysicianName", align: "center"},
    {label: "Last Office Location", field: "Last", align: "center"},
    {label: "Day of First MCP", field: "Day", align: "center"},
    {label: "First Access", field: "First", align: "center"},
    {label: "Home Status", field: "Home", align: "center"},
    {label: "Optimal Start", field: "Optimal", align: "center"},
];

export const LateStageCkdRoasterTableColumns = [
    {label: "Patient Id", field: "PatientId", align: "left"},
    {label: "Patient Name", field: "LastName", align: "center"},
    {label: "Date of Birth", field: "DOB", align: "left"},
    {label: "Stage Name", field: "OfficeLocation", align: "center"},
    {label: "Location", field: "PhysicianName", align: "center"},
    {label: "Physician Name", field: "Last", align: "center"},
    {label: "Insurance Type", field: "Day", align: "center"},
    {label: "Date of Last Office Date", field: "First", align: "center"},
    {label: "Home Status", field: "Home", align: "center"},
    {label: "Days Since Last Visit", field: "Optimal", align: "center"},
];
export const PopulationTableColumns = [
    {label: "Population", field: "name", align: "left"},
    {label: "Current Year", field: "currentYear", align: "center"},
    {label: "Prior Year", field: "priorYear", align: "center"},
    {
        label: "Trend",
        field: "trend",
        align: "center",
        render: (data) => <TrendTableChart data={data}/>,
    },
];
export const NewDialysisStartsChartColumns = [
    {label: "New Patients", field: "name", align: "left"},
    {label: "Current Year", field: "currentYear", align: "center"},
    {label: "Prior Year", field: "priorYear", align: "center"},
    {
        label: "Trend",
        field: "trend",
        align: "center",
        render: (data) => <TrendTableChart data={data}/>,
    },
];
export const LateStageVisitPerformanceTableColumns = [
    {label: "Late Stage Visit Performance", field: "name", align: "left"},
    {label: "Current Year", field: "currentYear", align: "center"},
    {label: "Prior Year", field: "priorYear", align: "center"},
    {
        label: "Trend",
        field: "trend",
        align: "center",
        render: (data) => <TrendTableChart data={data}/>,
    },
];
export const RevanueDialysisTableColumns = [
    {label: "Population", field: "name", align: "left"},
    {label: "Current Year", field: "currentYear", align: "center"},
    {label: "Prior Year", field: "priorYear", align: "center"},
    {
        label: "Trend",
        field: "trend",
        align: "center",
        render: (data) => <TrendTableChart data={data}/>,
    },
];
export const OfficeTableColumns = [
    {label: "Office", field: "name", align: "left"},
    {label: "Current Month", field: "currentYear", align: "center"},
    {label: "Prior Month", field: "priorYear", align: "center"},
    {
        label: "Trend",
        field: "trend",
        align: "center",
        render: (data) => <TrendTableChart data={data}/>,
    },
];
export const HospitalTableColumns = [
    {label: "Hospital", field: "name", align: "left"},
    {label: "Current Month", field: "currentYear", align: "center"},
    {label: "Prior Month", field: "priorYear", align: "center"},
    {
        label: "Trend",
        field: "trend",
        align: "center",
        render: (data) => <TrendTableChart data={data}/>,
    },
];
export const DialysisTableColumns = [
    {label: "Dialysis", field: "name", align: "left"},
    {label: "Current Month", field: "currentYear", align: "center"},
    {label: "Prior Month", field: "priorYear", align: "center"},
    {
        label: "Trend",
        field: "trend",
        align: "center",
        render: (data) => <TrendTableChart data={data}/>,
    },
];
export const ProductivityTableColumns = [
    {label: "Productivity", field: "name", align: "left"},
    {label: "Current Year", field: "currentYear", align: "center"},
    {label: "Prior Year", field: "priorYear", align: "center"},
    {
        label: "Trend",
        field: "trend",
        align: "center",
        render: (data) => <TrendTableChart data={data}/>,
    },
];
export const TotalPatientsTableColumns = [
    {label: "Total Patients", field: "name", align: "left"},
    {label: "Current Month", field: "currentYear", align: "center"},
    {label: "Prior Month", field: "priorYear", align: "center"},
    {
        label: "Trend",
        field: "trend",
        align: "center",
        render: (data) => <TrendTableChart data={data}/>,
    },
];
export const CKDReAdmissionTCMTableColumns = [
    {label: "CKD Re-Admission & TCM", field: "name", align: "left"},
    {label: "Current Year", field: "currentYear", align: "center"},
    {label: "Prior Year", field: "priorYear", align: "center"},
    {
        label: "Trend",
        field: "trend",
        align: "center",
        render: (data) => <TrendTableChart data={data}/>,
    },
];
export const OptimalStartComponentsColumns = [
    {label: "Optimal Start Components", field: "name", align: "left"},
    {label: "Current Year", field: "currentYear", align: "center"},
    {label: "Prior Year", field: "priorYear", align: "center"},
    {
        label: "Trend",
        field: "trend",
        align: "center",
        render: (data) => <TrendTableChart data={data}/>,
    },
];

export const PatientRoasterLastDayTableColumns = [
    {label: "Stage Name", field: "PatientId", align: "left"},
    {label: "Patient Name", field: "LastName", align: "center"},
    {label: "Primary Phone", field: "DOB", align: "left"},
    {label: "Address", field: "OfficeLocation", align: "center"},
    {label: "City", field: "PhysicianName", align: "center"},
    {label: "Last Date of Service", field: "Last", align: "center"},
    {label: "Last Seen Provider", field: "Day", align: "center"},
    {label: "Office Location", field: "First", align: "center"},
];

export const LateStageCkdVisitIntervalTableDescription =
    "The visit interval of Late-stage CKD patients varies between 30 to 90 days. Stage 5 CKD patients visit every 30 days, while stage 4 CKD patients visit every 90 days.";
export const LateStageCkdWaitTimeTableDescription =
    " The time taken by patients or need to do the dialysis. It is mostly in the weeks. It is a estimated time.";
export const TimelyReferralsTableDescription =
    "The patients which are timely referred from other providers. It is percentage of patients with at least one encounter more than six month prior to the dialysis.";
export const NewStartWithHospitalWith30daysPriorTableDescription =
    "  The percentage of new start patients of ESRD that were seen in the CKD practice clinic, but they also had an hospital visit/event one month prior to dialysis.";
export const HospitalToOfficeFollowUpTableDescription =
    "The percentage of stage 4 and stage 5 CKD patients that are follow up (visit the office) in the office within next a month after the hospital discharge.";

//for column
export const noData1 = {
    chart: {
        type: "area",
        height: 350,
        toolbar: {
            show: false,
        },
    },
    stroke: {
        curve: "smooth",
        colors: ["#246EFD"],
        dropShadow: {
            enabled: false,
        },
    },
    zoom: {
        enabled: false,
    },
    toolbar: {
        show: false,
    },
    dataLabels: {
        enabled: true,
        style: {
            colors: ["#246EFD"],
        },
    },
    yaxis: {
        min: 120,
        max: 160,
        tickAmount: 4,
        labels: {
            offsetX: -10,
        },
    },
    legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "bottom",
        horizontalAlign: "center",
        floating: false,
        offsetY: 12,
        offsetX: -10,
        itemMargin: {
            horizontal: 5,
            vertical: 7,
        },
    },
    tooltip: {
        x: {
            format: "",
        },
    },
};
//for series
export const noData2 = [{}];
export const noDataDescription = "No Data Found";
export const noDataRow = [{}];
export const donutNoData = [
    {
        label: "Current Year Total Revenue",
        value: "",
    },
    {label: "Previous Year", value: ""},
    {label: "% Decreases", value: ""},
];
export const activeESRDPatientsSeries = [
    {
        name: "Active ESRD Patients",
        data: [],
    },
];

export const newDialysisStartSeries = [
    {
        name: "New Dialysis Start",
        data: [],
    },
];

export const homePenetrationSeries = [
    {
        name: "Home Penetration",
        data: [],
    },
];

export const activeLateStagePatientsSeries = [
    {
        name: "Active Late Stage Patients",
        data: [],
    },
];
export const newlyReferredLateStageCKDPatientsSeries = [
    {
        name: "Newly Referred Late Stage CKD Patients",
        data: [],
    },
];
export const hospitaltoOfficeFollowupLateCKDSeries = [
    {
        name: "Hospital to Office Follow-up Late CKD",
        data: [],
    },
];
export const revenuePostedperFTESeries = [
    {
        name: "Revenue Posted per FTE",
        data: [],
    },
];
export const officeNewEncountersSeries = [
    {
        name: "Office New Encounters",
        data: [],
    },
];
export const hospitalNewEncountersSeries = [
    {
        name: "Hospital New Encounters",
        data: [],
    },
];
export const totalRVUFTESeries = [
    {
        name: "Total RVU / FTE",
        data: [],
    },
];
export const totalPatientsSeries = [
    {
        name: "Total Patients",
        data: [],
    },
];
export const optimalStartPercentageSeries = [
    {
        name: "Optimal Start %",
        data: [],
    },
];
export const optimalStartSeries = [
    {
        name: "Optimal Start",
        data: [],
    },
];
export const inCenterNoCatherSeries = [
    {
        name: "InCenter No Cather",
        data: [],
    },
];
export const incidentHomeSeries = [
    {
        name: "Incident Home",
        data: [],
    },
];
export const totalNewStartsSeries = [
    {
        name: "Total New Starts",
        data: [],
    },
];
export const lateStageCKDVisitIntervalSeries = [
    {
        name: "Late-Stage CKD Visit Interval",
        data: [],
    },
];
export const lateStageCKDWaitTimeSeries = [
    {
        name: "Late-Stage CKD Wait Time",
        data: [],
    },
];
export const timelyReferralsSeries = [
    {
        name: "Timely Referrals",
        data: [],
    },
];
export const hospitalToOfficeFollowUpSeries = [
    {
        name: "Hospital To Office Follow Up",
        data: [],
    },
];
export const newStartWithHospitalWith30daysPriorSeries = [
    {
        name: "New Start With Hospital With 30-days Prior",
        data: [],
    },
];
export const homePatientsSeries = [
    {
        name: "Home Patients",
        data: [],
    },
];
export const newHomePatientsSeries = [
    {
        name: "New Home Patients",
        data: [],
    },
];
export const HomeVSHomeCountPerFTESeries = [
    {
        name: "Home% VS Home Count Per FTE",
        data: [],
    },
];
export const HomeVSNewHomePerFTESeries = [
    {
        name: "Home% VS New Home Per FTE",
        data: [],
    },
];
export const HomeVSIncidentHomePerFTESeries = [
    {
        name: "Home% VS Incident Home Per FTE",
        data: [],
    },
];
export const HomeVSHomePatientsSeries = [
    {
        name: "Home% VS Home Patients",
        data: [],
    },
];
export const HomePercentageSeries = [
    {
        name: "Home%",
        data: [],
    },
];
export const TotalRevenuePostedinAYearOfficeServiceSeries = [
    {
        name: "Total Revenue Posted in a Year (Office Service)",
        data: [],
    },
];
export const TotalEncountersinAYearOfficeServiceSeries = [
    {
        name: "Total Encounters in a Year (Office Service)",
        data: [],
    },
];
export const totalPatientsSeeninAYearOfficeServiceSeries = [
    {
        name: "Total Patients Seen in a Year (Office Service)",
        data: [],
    },
];
export const totalNewPatientsinAYearOfficeServiceSeries = [
    {
        name: "Total New Patients in a Year (Office Service)",
        data: [],
    },
];
export const revenuePerPatientSeries = [
    {
        name: "Revenue Per Patient",
        data: [],
    },
];
export const revenuePerEncounterSeries = [
    {
        name: "Revenue Per Encounter",
        data: [],
    },
];
export const totalRevenueSeries = [
    {
        name: "Total Revenue",
        data: [],
    },
];
export const encountersPerPatientsSeries = [
    {
        name: "Encounters Per Patients",
        data: [],
    },
];
export const totalEncountersSeries = [
    {
        name: "Total Encounters",
        data: [],
    },
];
export const newPatientsSeries = [
    {
        name: "New Patients",
        data: [],
    },
];
export const TotalRevenuePostedinAYearHospitalServiceSeries = [
    {
        name: "Total Revenue Posted in a Year (Hospital Service)",
        data: [],
    },
];
export const TotalEncountersinAYearHospitalServiceSeries = [
    {
        name: "Total Encounters in a Year (Hospital Service)",
        data: [],
    },
];
export const totalPatientsSeeninAYearHospitalServiceSeries = [
    {
        name: "Total Patients Seen in a Year (Hospital Service)",
        data: [],
    },
];
export const totalNewPatientsinAYearHospitalServiceSeries = [
    {
        name: "Total New Patients in a Year (Hospital Service)",
        data: [],
    },
];

export const TotalRevenuePostedinAYearMCPServiceSeries = [
    {
        name: "Total Revenue Posted in a Year (MCP Service)",
        data: [],
    },
];
export const TotalEncountersinAYearMCPServiceSeries = [
    {
        name: "Total Encounters in a Year (MCP Service)",
        data: [],
    },
];
export const totalPatientsSeeninAYearMCPServiceSeries = [
    {
        name: "Total Patients Seen in a Year (MCP Service)",
        data: [],
    },
];
export const totalNewPatientsinAYearMCPServiceSeries = [
    {
        name: "Total New Patients in a Year (MCP Service)",
        data: [],
    },
];
export const patientsInflowRateSeries = [
    {
        name: "Patients Inflow Rate",
        data: [],
    },
];
export const patientsOutflowRateSeries = [
    {
        name: "Patients Outflow Rate",
        data: [],
    },
];
export const percentageofPtswithAlbuminUnder20MonthWiseSeries = [
    {
        name: "Percentage of Pts with Albumin Under 2.0 (Month Wise)",
        data: [],
    },
];
export const countofPtswithAlbuminUnder20MonthWiseSeries = [
    {
        name: "Count of Pts with Albumin Under 2.0 (Month Wise)",
        data: [],
    },
];
export const percentageofPtswithGFRunder60MonthWiseSeries = [
    {
        name: "Percentage of Pts with GFR under 60 (Month Wise)",
        data: [],
    },
];
export const countofPtswithGFRunder60MonthWiseSeries = [
    {
        name: "Count of Pts with GFR under 60 (Month Wise)",
        data: [],
    },
];
export const CKDPTSBMISeries = [
    {
        name: "CKD PTS/BMI",
        data: [],
    },
];
export const ESRDCKDPTSBMISeries = [
    {
        name: "ESRD-CKD PTS/BMI",
        data: [],
    },
];
export const Stage4CKDPTSBMISeries = [
    {
        name: "Stage 4-CKD PTS/BMI",
        data: [],
    },
];
export const Stage5CKDPTSBMISeries = [
    {
        name: "Stage 5-CKD PTS/BMI",
        data: [],
    },
];
