import { Box, Grid, useTheme } from "@mui/material";
import { EditTeamFieldsData, titleOptions } from "assets/static";
import { useFormik } from "formik";
import * as toast from "hooks/notify";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminInfo, updateAdminInfo } from "redux/features/Admin/AdminSlice";
import { getUsersList } from "redux/features/MyTeam/myTeamSlice";
import { EditTeamMemberSchema } from "validations/Settings/EditTeamMember.schema";
import Button from "../Button/Button";
import ImageDropzone from "../FileUploader";
import InputField from "../FormComponents/InputField";
import SelectField from "../FormComponents/SelectField";
import CustomModal from "./CustomModal";
import Text from "../Typography/Text";

const EditTeamMemberModal = ({ open, setOpen, userId = null }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { account_id } = useSelector((state) => state.auth);
  const { isAdminUpdateLoading, adminData, adminUpdateSuccess } = useSelector(
    (state) => state.admin
  );

  const initializeFormValues = () => {
    return {
      title: adminData?.title
        ? titleOptions?.find(({ value }) => value === adminData?.title)?.label
        : titleOptions[0]?.label,
      first_name: adminData?.id ? adminData?.first_name : "",
      last_name: adminData?.id ? adminData?.last_name : "",
      username: adminData?.id ? adminData?.username : "",
      designation: adminData?.id ? adminData?.designation : "",
      birthdate: adminData?.birthdate
        ? moment(adminData?.birthdate).format("YYYY-MM-DD")
        : "",
      national_provider_identifier: adminData?.id
        ? adminData?.national_provider_identifier
        : "",
      email: adminData?.id ? adminData?.email : "",
      phone_number: adminData?.id ? adminData?.phone_number : "",
      photo: adminData?.id ? adminData?.photo : "",
    };
  };

  const formik = useFormik({
    initialValues: initializeFormValues(),
    validationSchema: EditTeamMemberSchema,
    onSubmit: (data) => {
      let formData = new FormData();
      formData.append("title", data?.title?.value ?? data?.title);
      formData.append("first_name", data?.first_name);
      formData.append("last_name", data?.last_name);
      formData.append("groups", adminData?.groups);
      formData.append("accounts", adminData?.accounts);
      formData.append("username", data?.username);
      formData.append("designation", data?.designation);
      formData.append("birthdate", data?.birthdate);
      formData.append("national_provider_identifier", data?.national_provider_identifier);
      formData.append("email", data?.email);
      formData.append("phone_number", data?.phone_number);
      typeof data?.photo !== "string" && formData.append("photo", data?.photo);
      dispatch(updateAdminInfo(userId, formData));
    },
  });

  useEffect(() => {
    if (userId) {
      dispatch(getAdminInfo(userId));
    }

    if (adminUpdateSuccess) {
      dispatch(getUsersList(account_id));
      toast.success("Profile Updated");
      setOpen && setOpen(false);
    }
  }, [dispatch, userId, adminUpdateSuccess, account_id, setOpen]);

  useEffect(() => {
    if (adminData) {
      const updatedValues = {
        title: adminData?.title
          ? titleOptions?.find(({ value }) => value === adminData?.title)?.label
          : titleOptions[0]?.label,
        first_name: adminData?.id ? adminData?.first_name : "",
        last_name: adminData?.id ? adminData?.last_name : "",
        username: adminData?.id ? adminData?.username : "",
        designation: adminData?.id ? adminData?.designation : "",
        birthdate: adminData?.birthdate
          ? moment(adminData?.birthdate).format("YYYY-MM-DD")
          : "",
        national_provider_identifier: adminData?.id
          ? adminData?.national_provider_identifier
          : "",
        email: adminData?.id ? adminData?.email : "",
        phone_number: adminData?.id ? adminData?.phone_number : "",
        photo: adminData?.id ? adminData?.photo : "",
      };
      formik.resetForm({ values: updatedValues });
    }
  }, [adminData]);

  useEffect(() => {
    // return () => dispatch(clearAdminStatuses());
  }, [dispatch]);

  return (
    <CustomModal
      setOpen={() => {
        formik.resetForm();
        setOpen && setOpen(false);
      }}
      open={open}
      title={"Edit Team Member"}
      width="auto"
      maxWidth={"md"}
      fullWidth={true}
    >
      <>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <ImageDropzone formik={formik} name="photo" />
        </Box>
        <Grid container spacing={2}>
          <SelectField
            key={adminData?.title}
            options={titleOptions}
            name="title"
            label={"Title"}
            defaultValue={
              adminData?.title
                ? titleOptions?.find(({ value }) => value === adminData?.title)
                : titleOptions[0]
            }
            formik={formik}
            cols={6}
            placeholder="Select One"
          />
          {EditTeamFieldsData?.map(
            ({ type, label, name, placeHolder, max }) => (
              <InputField
                key={name}
                label={label}
                disabled={name === "email"}
                type={type}
                formik={formik}
                {...(label === "DOB" && { max, required: true })}
                flex={1}
                name={name}
                cols={6}
                placeholder={placeHolder}
              />
            )
          )}
          <Grid item>
            <Text variant="h4" sx={{ marginTop: "10px" }}>Email :</Text>
            <Text variant="h4" sx={{ marginTop: "15px" }}>{adminData?.email ?? ""}</Text>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            padding: "25px 0px 0px 10px",
            justifyContent: "center",
          }}
        >
          <Button
            id="edit-team-member-id"
            text="Save"
            backgroundColor={theme.palette.common.blue}
            btnColor={theme.palette.common.white}
            fontWeight={"400"}
            borderRadius="10px"
            isLoading={isAdminUpdateLoading && true}
            onClick={formik.handleSubmit}
            sx={{ width: "150px", color: theme.palette.button }}
          />
          <Button
            id="edit-Cancel-btn"
            borderRadius="10px"
            border={"1px solid #555555"}
            onClick={() => {
              formik.resetForm();
              setOpen(false);
            }}
            text="Cancel"
            sx={{
              width: "150px",
              backgroundColor: theme.palette.common.white,
              marginLeft: "15px",
              color: theme.palette.common.black,
            }}
          />
        </Box>
      </>
    </CustomModal>
  );
};

export default EditTeamMemberModal;
