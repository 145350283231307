import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const GenerateBreadcrumbs = ({ id }) => {
  const location = useLocation();
  // Get patient data for header
  const { getSpecificPatientData } = useSelector(
    (state) => state.specificPatient
  );

  const findClosestMatch = (url) => {
    const keys = Object.keys(breadcrumbsData);
    const closestMatch = keys.reduce((acc, curr) => {
      // Escape special characters in the curr route and replace path parameters with regex
      const regex = new RegExp(
        `^${curr.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1").replace(/:[^\s/]+/g, "[^/]+")}(\\?.*)?$`
      );
      return url.match(regex) ? curr : acc;
    }, "/");

    return closestMatch;
  };

  const getBreadcrumbs = () => {
    const closestMatch = findClosestMatch(
      window.location.pathname + window.location.search
    );
    return breadcrumbsData[closestMatch] || [];
  };

  const breadcrumbsData = {
    "/": {
      current: "/",
      crumbsData: ["Nephrolytics", "All patients"],
    },
    "/schedule": {
      current: "/",
      crumbsData: ["Nephrolytics", "All patients"],
    },
    "/financials": {
      current: "",
      midUrl: "",
      crumbsData: ["Nephrolytics", "All patients", "Overview"],
    },
    "/settings?tab=1": {
      current: "",
      midUrl: "/settings?tab=1",
      crumbsData: ["Nephrolytics", "Profile Settings"],
    },

    "/settings?tab=2": {
      current: "",
      midUrl: "/settings?tab=2",
      crumbsData: ["Nephrolytics", "My Team"],
    },
    "/settings?tab=3": {
      current: "",
      midUrl: "/settings?tab=3",
      crumbsData: ["Nephrolytics", "Privacy and Security"],
    },

    "/financials/quickReports/lateStageCKDPatient": {
      current: "",
      midUrl: "/financials/quickReports/esrdPatients",
      crumbsData: ["Nephrolytics", "Quick Reports", "Late Stage CKD Patients"],
    },
    "/financials/quickReports/practiceRevenue": {
      current: "",
      midUrl: "/financials/quickReports/esrdPatients",
      crumbsData: ["Nephrolytics", "Quick Reports", "Practice Revenue"],
    },
    "/financials/quickReports/productivityOthers": {
      current: "",
      midUrl: "/financials/quickReports/esrdPatients",
      crumbsData: ["Nephrolytics", "Quick Reports", "Productivity/Others"],
    },
    "/financials/quickReports/esrdPatients": {
      current: "",
      midUrl: "/financials/quickReports/esrdPatients",
      crumbsData: ["Nephrolytics", "Quick Reports", "ESRD Patients"],
    },
    "/financials/optimalStart/summary": {
      current: "",
      midUrl: "/financials/optimalStart/summary",
      crumbsData: [
        "Nephrolytics",
        "Optimal Start Summary",
        "Optimal Start Summary",
      ],
    },
    "/financials/optimalStart/drivers": {
      current: "",
      midUrl: "/financials/optimalStart/summary",
      crumbsData: ["Nephrolytics", "Optimal Start Summary", "Drivers"],
    },
    "/financials/optimalStart/homeDialysis": {
      current: "",
      midUrl: "/financials/optimalStart/summary",
      crumbsData: ["Nephrolytics", "Optimal Start Summary", "Home Dialysis"],
    },
    "/financials/optimalStart/newStartRoaster": {
      current: "",
      midUrl: "/financials/optimalStart/summary",
      crumbsData: [
        "Nephrolytics",
        "Optimal Start Summary",
        "New Start Roaster",
      ],
    },
    "/financials/optimalStart/lateStageRoaster": {
      current: "",
      midUrl: "/financials/optimalStart/summary",
      crumbsData: [
        "Nephrolytics",
        "Optimal Start Summary",
        "Late Stage Roaster",
      ],
    },
    "/financials/revenue/summary": {
      current: "",
      midUrl: "/financials/revenue/summary",
      crumbsData: ["Nephrolytics", "Revenue", "Revenue Summary"],
    },

    "/financials/revenue/hospitalServices": {
      current: "",
      midUrl: "/financials/revenue/summary",
      crumbsData: ["Nephrolytics", "Revenue", "Hospital Services"],
    },
    "/financials/revenue/mcpService": {
      current: "",
      midUrl: "/financials/revenue/summary",
      crumbsData: ["Nephrolytics", "Revenue", "MCP Services"],
    },
    "/financials/revenue/officeService": {
      current: "",
      midUrl: "/financials/revenue/summary",
      crumbsData: ["Nephrolytics", "Revenue", "Office Service"],
    },
    "/financials/patients/roaster": {
      current: "",
      midUrl: "/financials/patients/roaster",
      crumbsData: ["Nephrolytics", "Roaster", "Roaster"],
    },
    "/financials/patients/ckdComparison": {
      current: "",
      midUrl: "/financials/patients/roaster",
      crumbsData: ["Nephrolytics", "Roaster", "CKD Comparison"],
    },
    "/financials/patients/PatientMonth": {
      current: "",
      midUrl: "/financials/patients/roaster",
      crumbsData: ["Nephrolytics", "Roaster", "Patient Per Month"],
    },
    "/financials/patients/abnormalLabs": {
      current: "",
      midUrl: "/financials/patients/roaster",
      crumbsData: ["Nephrolytics", "Roaster", "Abnormal Labs"],
    },
    "/financials/patients/ckdPatientsBMI": {
      current: "",
      midUrl: "/financials/patients/roaster",
      crumbsData: ["Nephrolytics", "Roaster", "CKD Patients/BMI"],
    },
    "/financials/patients/followUpRoster": {
      current: "",
      midUrl: "/financials/patients/roaster",
      crumbsData: ["Nephrolytics", "Roaster", "Follow-Up Roster"],
    },

    "/schedule/patient/:patientId/appointment/:appointmentId": {
      current: "",
      crumbsData: [
        "Nephrolytics",
        "All Patients",
        `${getSpecificPatientData?.first_name ?? ""} ${
          getSpecificPatientData?.last_name ?? ""
        }`,
      ],
    },
  };

  return (
    <Breadcrumbs
      data-testid={id}
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {getBreadcrumbs()?.crumbsData?.map((crumb, index) => (
        <Link
          key={index}
          underline="hover"
          color="inherit"
          style={{
            textDecoration: "none",
            color: index === 1 ? "#246EFD" : "#2D3748",
          }}
          to={
            index === 2
              ? window.location.pathname + window.location.search
              : index === 0
                ? "/"
                : getBreadcrumbs()?.midUrl
                  ? getBreadcrumbs()?.midUrl
                  : "/"
          }
        >
          <span
            style={{ borderBottom: "1px solid transparent" }}
            onMouseEnter={(e) =>
              (e.target.style.borderBottom = "1px solid #000")
            }
            onMouseLeave={(e) =>
              (e.target.style.borderBottom = "1px solid transparent")
            }
          >
            {crumb}
          </span>
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default GenerateBreadcrumbs;
