import { Box } from "@mui/material";
import CustomModal from "components/common/Modal/CustomModal";
import Text from "components/common/Typography/Text"
import { Fragment, useState } from "react";
import { checkNullOrUndefinedString } from "utils/helper";

const LabResultDetailCard = ({
  data,
  marginTop,
  pastVisit,
}) => {
  const [openOrignalFile, setOpenOrignalFile] = useState(false);
  return (
    <Box sx={{ marginTop: marginTop }}>
      {data !== null ? (
        <>
          {!pastVisit ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  data-testid="labresults-heading"
                  sx={{
                    color: "#2D3748",
                    fontSize: "20px",
                    lineHeight: "28.79px",
                    fontWeight: "700",
                  }}
                >
                  <span style={{ borderBottom: "2px solid  #1344F1" }}>
                    Lab Results
                  </span>
                </Box>
              </Box>
            </>
          ) : (
            ""
          )}
          <Box
            sx={{
              borderRadius: "10px",
              marginTop: "10px",
            }}
          >
            <Box
              data-testid="labresults-tests-details"
              sx={{ maxHeight: "490px", marginTop: "10px", overflowY: "auto" }}
            >
              <Box>
                {data?.lab_tests?.map((labTest, i) => (
                  <Fragment key={i}>
                    {labTest?.lab_observations?.map(
                      (
                        {
                          name,
                          value,
                          unit,
                          reference_range,
                          in_range
                        },
                        j
                      ) => (
                        <Box key={j} marginY={1}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Text
                              data-testid={`lab-test-name-${i}`}
                              variant="h3"
                              minWidth="160px"
                            >
                              {name ?? ""}
                            </Text>
                            <Text
                              variant="bodyXs"
                              marginLeft="20px"
                              data-testid={`lab-value-${i}`}
                              color={in_range === "normal" ? "" : "red"}
                            > {checkNullOrUndefinedString(
                              Array.isArray(value) && value.length > 1
                                ? `${value.join('-')}`
                                : value
                            )} {unit}
                            </Text>
                          </Box>

                          <Text variant="formLabel">
                            Ref:
                            <Text variant="formLabel" data-testid={`lab-range-${i}`}>
                              &nbsp; {reference_range ?? ""} {unit}
                            </Text>
                          </Text>
                        </Box>
                      )
                    )
                    }
                  </Fragment>
                ))}
              </Box>
            </Box>
            <CustomModal
              open={openOrignalFile}
              setOpen={setOpenOrignalFile}
              title={"Original File "}
              fullWidth
              maxWidth="lg"
            >
              <Box>
                {data?.original_file_url ? (
                  <iframe
                    src={data?.original_file_url}
                    width="100%"
                    height="500px"
                    title="PDF Viewer"
                  ></iframe>
                ) : (
                  "No File Found"
                )}
              </Box>
            </CustomModal>
          </Box>
        </>
      ) : (
        <Box marginY={4}>
          <Text variant="bodyS">No Data Found.</Text>
        </Box>
      )}
    </Box>
  );
};

export default LabResultDetailCard;
