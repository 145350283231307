import { Box, Grid } from "@mui/material";
import { initialStaticCptForAllEncounter } from "assets/static";
import CustomModal from "components/common/Modal/CustomModal";
import * as toast from "hooks/notify";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import HorizontalTabs from "components/common/Tabs/HorizontalTabs";
import HealthSummary from "./components/HealthSummary";
import Medications from "./components/Medications/Medications";
import { healthsummaryMain } from "styles/SCD/healthSummaryStyle";
import {
  clearAllHistoryData,
  getAllHistoryList,
} from "redux/features/AllHistories/AllHistorySlice";
import {
  clearAllergiesData,
  getAllergiesByPatientId,
} from "redux/features/Allergies/AllergiesSlice";
import SmartSummaryIcon from "../../../assets/svg/light/ai_sparkle.svg";
import {
  clearAppointmentData,
  getSpecificAppointmentDetails,
} from "redux/features/Appointments/AppointmentsSlice";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  clearEncounterByAppointmentData,
  clearEncounterByPatientData,
  clearEncounterData,
  createOrUpdateEncounter,
  getEncounterByAppointment,
  getEncounterByPatient,
} from "redux/features/Encounter/EncounterSlice";
import { getFamilyHistoryList } from "redux/features/FamilyHistory/FamilyHistorySlice";
import {
  clearHealthSummaryData,
  getHealthSummaryList,
} from "redux/features/HealthSummary/HealthSummarySlice";
import {
  clearLabsTestData,
  getLabRequestId,
  getLabTests,
  getLabsByEncounter,
} from "redux/features/Labs/labsSlice";
import {
  clearMedicationsData,
  getPatientMedications,
} from "redux/features/Medications/MedicationsSlice";
import {
  clearMedicinesData,
  clearMedicinesStates,
  getPrescriptionByPatient,
  updatePrescriptionsByPatientId,
} from "redux/features/Medications/MedicinesSlice";
import { getOrderMedicationsList } from "redux/features/Medications/OrderMedicationsSlice";
import {
  clearLabsResultsMessage,
  getLabsResults,
} from "redux/features/Patients/ViewLabResultsSlice";
import {
  clearSpecificPatientData,
  getSpecificPatientDetails,
} from "redux/features/Patients/getSpecificPatientsSlice";
import { getPharmacyList } from "redux/features/Pharmacy/PharmacySlice";
import { clearVitalsData } from "redux/features/Vitals/VitalsSlice";
import { debounce } from "utils/debouncer";
import EndVisit from "./components/EndVisit";
import Plan from "./components/AssesmentAndPlan/Plan";
import VitalLabMedWrapper from "./components/VitalLabMedWrapper";
import SCDHeader from "./components/SCDHeader";
import Button from "components/common/Button/Button";
import { getLabProvidersList } from "redux/features/Labs/LabProvidersSlice";
import rightArrow from "assets/svg/light/rightArrow.svg";
import ChatBotComponent from "components/common/ChatBotComponent/ChatBotComponent";
import PastVisitModal from "./components/PastVisit/PastVisitModal";
import {
  planBoxDescriptionStyling,
  planBoxStylingWrapper,
  planBoxTitleStyling,
} from "styles/SCD/planStyles";
import Text from "components/common/Typography/Text";
import AssesmentPlanNotes from "./components/AssesmentAndPlan/components/AssesmentPlanNotes";
import ChatBotDrawer from "components/common/ChatBotComponent/ChatBotDrawer";
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { fetchFeedbackAPI, submitFeedbackAPI } from "redux/features/Feedback/feedbackSlice.js";
import InputField from "components/common/FormComponents/InputField";

const PatientDetail = () => {
  const viewWidth = window.innerWidth;
  const dispatch = useDispatch();
  let { patientId, appointmentId } = useParams();
  const [helperMedication, setHelperMedication] = useState([]);
  const [isPostCalled, setIsPostCalled] = useState(null);
  const [startAppointment, setStartAppointment] = useState(false);
  const { isChatOpen } = useSelector((state) => state.globalStates);
  const { account_id, userId } = useSelector((state) => state.auth);
  const [enableAutoSaveIcds, setEnableAutoSaveIcds] = useState(false);
  const [feedbackNote, setFeedbackNote] = useState("")
  const [smartSummaryfeedback, setsmartSummaryFeedback] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [endNoteFeedback, setEndNoteFeedback] = useState("");
  const { updateLabsSuccess } = useSelector(
    (state) => state.labs
  );
  const { updateMedicineSuccess } = useSelector((state) => state.medicines);

  // Get patient data for header
  const { getSpecificPatientData, getSpecificPatientSuccess } = useSelector(
    (state) => state.specificPatient
  );

  // Get appointment data for future encounter request
  const { appointmentData } = useSelector((state) => state.specificAppointment);
  const scdRef = useRef();
  const markdownContentRef = useRef();
  // encounter request response
  const {
    encounterSuccess,
    encounterMessage,
    isEncounterLoading,
    encounterByPatientData,
    encounterByAppointmentData,
    encounterByAppointmentSuccess,
  } = useSelector((state) => state.encounter);

  //get query params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [open, setOpen] = useState(queryParams.get("review_later") ?? false);
  const [pastVisit, setPastVisit] = useState(false);
  const [planLabsData, setPlanLabsData] = useState([]);
  const [planMedicineData, setPlanMedicineData] = useState([]);
  const [feedbacksList, setFeedbacksList] = useState([]);
  const {
    medicationsActiveData,
    medicationsInActiveData,
  } = useSelector((state) => state.medications);
  const [activeMedications, setActiveMedications] = useState([])
  const [inActiveMedications, setInActiveMedications] = useState([])
  const [feedbackPageIndex, setFeedbackPageIndex] = useState(1)
  const { feedbackList, isNext } = useSelector((state) => state.Feedback)

  // State to fetch healthSummary only when Icd/Cpt is changed by user.
  const [fetchHealthSummaryTrigger, setFetchHealthSummaryTrigger] =
    useState(false);

  // States to not run debouncer when default data comer from the Api.
  const [enableAutoSaveNotes, setEnableAutoSaveNotes] = useState(false);

  // State for assesment components
  const [assesmentObj, setAssesmentObj] = useState({
    selectedIcdCodes: [],
    notes: "",
  });

  useEffect(() => {
    if (feedbackList?.length) {
      setFeedbacksList([...feedbacksList, ...feedbackList])
    }
    isNext && dispatch(fetchFeedbackAPI(feedbackPageIndex + 1))
    isNext && setFeedbackPageIndex(feedbackPageIndex + 1)
  }, [isNext, feedbackList])

  const findLatestFeedback = (feedbackArray, itemType) => {
    return feedbackArray
      .filter(
        (item) =>
          item.item_for_feedback === itemType &&
          item.item_id == encounterByAppointmentData?.id
      )
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];
  };

  useEffect(() => {
    if (feedbacksList?.length) {
      const findSmartSummaryFeedback = findLatestFeedback(feedbacksList, "smart.summary");
      const findEndNoteFeedback = findLatestFeedback(feedbacksList, "smart.endnote");
      setEndNoteFeedback(findEndNoteFeedback?.thumbs_up);
      setsmartSummaryFeedback(findSmartSummaryFeedback?.thumbs_up);
    }
  }, [feedbacksList])

  // Clear all encounter data
  function clearAllEncounterData() {
    // Clear encounter data from global state
    dispatch(clearEncounterData());

    // Clear encounter data by patient
    dispatch(clearEncounterByPatientData());

    // Clear encounter data by appointment
    dispatch(clearEncounterByAppointmentData());

    // Clear encounter data by appointment
    dispatch(clearAllergiesData());

    //clear labs data
    dispatch(clearLabsTestData());
  }

  function clearMedicationsStates() {
    setActiveMedications([])
    setInActiveMedications([])
  }

  // setting icds from previous encounter of patient
  useEffect(() => {
    // Api response order changes on updation. filtering data according to the appointment
    let filteredEncounterByAppointment = encounterByAppointmentData;
    if (filteredEncounterByAppointment?.id) {
      setAssesmentObj((prevState) => ({
        ...prevState,
        selectedIcdCodes: filteredEncounterByAppointment?.icds,
        selectedCptCodes:
          filteredEncounterByAppointment?.cpts?.length > 0
            ? filteredEncounterByAppointment?.cpts
            : [initialStaticCptForAllEncounter],
        notes: filteredEncounterByAppointment?.assessment_notes,
      }));
    } else {
      // if there is no encounter created before than icds of previous encounter
      setAssesmentObj((prevState) => ({
        ...prevState,
        selectedIcdCodes: encounterByPatientData?.icds,
      }));
    }
  }, [encounterByAppointmentData]);

  // Toast pop up on encounter response
  useEffect(() => {
    if (encounterSuccess && encounterSuccess) {
      updateTheExistingData();
      clearMedicationsStates()
      dispatch(getPatientMedications(patientId));
    }
    encounterSuccess === false && toast.error(encounterMessage);
  }, [encounterMessage]);

  // Medicines in plan will be updated when the encounter part is done
  useEffect(() => {
    if (encounterSuccess) {
      if (fetchHealthSummaryTrigger) {
        // Clearing out health summary data for refetching.
        dispatch(clearHealthSummaryData());
        dispatch(getHealthSummaryList(patientId, appointmentId));
      }
      setFetchHealthSummaryTrigger(false);
    }
  }, [encounterMessage]);

  useEffect(() => {
    if (updateMedicineSuccess) {
      clearMedicationsStates()
      // medicine for enocunter
      dispatch(getOrderMedicationsList(patientId));
      dispatch(getPatientMedications(patientId));
      dispatch(getPrescriptionByPatient(patientId));
    }
    return () => dispatch(clearMedicinesStates());
  }, [updateMedicineSuccess]);

  useEffect(() => {
    // Api hit for labs data
    dispatch(getLabsResults(patientId));

    // Api hit for encounter for specific patient
    dispatch(getEncounterByPatient(patientId));

    // Api hit for familyhistory for specific patient
    dispatch(getFamilyHistoryList(patientId));

    // Api hit for specific patient
    dispatch(getSpecificPatientDetails(patientId));

    // Api hit for allergies by patient id
    dispatch(getAllergiesByPatientId(patientId));

    dispatch(fetchFeedbackAPI(feedbackPageIndex));

    // Api hit for all histories by patient id
    dispatch(getAllHistoryList(patientId));
  }, [patientId]);

  // Api hit for specific appointment
  useEffect(() => {
    // get encounter on the base of appointment
    dispatch(getEncounterByAppointment(appointmentId));

    // sepecific appointment details
    dispatch(getSpecificAppointmentDetails(appointmentId));

    // precriptions for enocunter
    dispatch(getPrescriptionByPatient(patientId));

    // medicine for enocunter
    dispatch(getPatientMedications(patientId));

    // Per lab by encounter
    dispatch(getLabsByEncounter(appointmentId));
  }, [appointmentId, patientId]);

  useEffect(() => {
    encounterByAppointmentData?.id && dispatch(getLabTests(patientId, encounterByAppointmentData?.id));
    encounterByAppointmentData?.id && dispatch(getLabRequestId(patientId, encounterByAppointmentData?.id));
  }, [encounterByAppointmentSuccess])

  useEffect(() => {
    encounterByAppointmentData?.sign_close_ind === "c" &&
      setStartAppointment(false);
  }, [encounterByAppointmentData]);

  useEffect(() => {
    // Per lab by encounter
    if (updateLabsSuccess) {
      dispatch(getLabsByEncounter(appointmentId));
    }
  }, [updateLabsSuccess]);

  useEffect(() => {
    return () => {
      // Clear vitals data
      dispatch(clearVitalsData());

      // clear labs data
      dispatch(clearLabsResultsMessage());

      // Clear patient data from global state
      dispatch(clearSpecificPatientData());

      // Clear appointment data from global state
      dispatch(clearAppointmentData());

      // Clear all history data from global state
      dispatch(clearAllHistoryData());

      // Clear all medicines data
      dispatch(clearMedicinesData());

      // Clear all encounter data
      clearAllEncounterData();

      // Clear all medications data
      dispatch(clearMedicationsData())
    };
  }, []);

  // Triggers and restart the autosaving process when Icds get created or deleted
  useEffect(() => {
    if (enableAutoSaveIcds) {
      if (!fetchHealthSummaryTrigger) setFetchHealthSummaryTrigger(true);
    }

    const debouncedFunc = callAutoEncSaver(
      500,
      () => createOrUpdate("Soap Notes", "s"),
      enableAutoSaveIcds
    );
    debouncedFunc();

    return () => {
      // Clear the debounce timer on unmount or when notes change
      debouncedFunc.cancel();
    };
  }, [assesmentObj?.selectedIcdCodes?.length]);

  // Triggers and restart the autosaving process when notes changes
  useEffect(() => {
    const debouncedFunc = callAutoEncSaver(
      3000,
      () => createOrUpdate("Soap Notes", "s"),
      enableAutoSaveNotes
    );
    debouncedFunc();

    return () => {
      // Clear the debounce timer on unmount or when notes change
      debouncedFunc.cancel();
    };
  }, [assesmentObj?.notes]);

  useEffect(() => {
    // because of scroller (appending)
    setActiveMedications((prevData) => [...prevData, ...(medicationsActiveData || [])]);
  }, [medicationsActiveData])

  useEffect(() => {
    // because of scroller (appending)
    setInActiveMedications((prevData) => [...prevData, ...(medicationsInActiveData || [])]);
  }, [medicationsInActiveData])

  function updateTheExistingData() {
    // Api hit for encounter for specific patient
    dispatch(getEncounterByPatient(patientId));

    // get encounter on the base of appointment
    dispatch(getEncounterByAppointment(appointmentId));

    // sepecific appointment details
    dispatch(getSpecificAppointmentDetails(appointmentId));
  }

  // To send the encounter after validations
  function sendEncounter(plan_note, signCloseIn) {
    let encounterData = {
      plan_note: plan_note,
      patient_id: appointmentData?.patient_id,
      location_id: appointmentData?.location_id,
      practitioners: [appointmentData?.practitioner_id],
      user_id: userId,
      visit_type: "",
      outcome: "",
      assessment_notes: assesmentObj?.notes ?? ".",
      appointment_id: appointmentData?.id,
      icds: [],// we are not sure yet whether we need icds for the mvp or not...
      cpts: [],
      datetime: new Date(),
      followup_date: null,
      account_id: account_id,
      followup_selection: '',
      sign_close_ind: signCloseIn,
    };

    // adding id if encounter exists
    if (encounterByAppointmentData?.id) {
      encounterData.id = encounterByAppointmentData?.ids;
    }

    // Clear encounter data from global state
    dispatch(clearEncounterData());

    // create or update encounter on the basis of data and validations
    dispatch(
      createOrUpdateEncounter(
        encounterData,
        encounterByAppointmentData?.id ? "PUT" : "POST",
        encounterByAppointmentData?.id
      )
    );
  }

  const patchEncounter = () => {
    if (encounterByAppointmentData?.id) {
      dispatch(createOrUpdateEncounter(
        {
          id: encounterByAppointmentData?.id,
          sign_close_ind: "r"
        },
        "PATCH",
        encounterByAppointmentData?.id
      ));
    }
    setOpen(false)
    setStartAppointment(false)
  }

  useEffect(() => {
    [!encounterByAppointmentData?.id, Boolean(userId), encounterByAppointmentSuccess, Boolean(patientId)].every(Boolean)
      && dispatch(
        createOrUpdateEncounter(
          {
            datetime: new Date(),
            user_id: userId,
            patient_id: parseInt(patientId),
            appointment_id: parseInt(appointmentId),
            sign_close_ind: "s"
          },
          encounterByAppointmentData?.id ? "PUT" : "POST",
          encounterByAppointmentData?.id
        )
      );
  }, [encounterByAppointmentData?.id, encounterByAppointmentSuccess, userId, patientId])

  function createOrUpdate(soapNotes, signCloseIn) {
    if (encounterByAppointmentData?.sign_close_ind !== "c") {
      // For save no validations required.
      if (signCloseIn === "s" || signCloseIn === "r") {
        sendEncounter(soapNotes, signCloseIn);
      }
      // For cease check the validations.
      else if (signCloseIn === "c") {
        sendEncounter(soapNotes, signCloseIn);
      }
    }
  }

  function callAutoEncSaver(timeOut, func, condition) {
    return debounce(() => {
      if (condition) {
        func();
      }
    }, timeOut);
  }

  function createDeleteMedicine(data, prescriptionId) {
    dispatch(
      prescriptionId ? updatePrescriptionsByPatientId(data, patientId, prescriptionId) : updatePrescriptionsByPatientId(data, patientId)
    );
  }

  function removeMedicationFromtheState(id, isActive) {
    if (isActive) setActiveMedications((prevData) => prevData.filter((s) => s.id !== id));
    else setInActiveMedications((prevData) => prevData.filter((s) => s.id !== id));
  }

  // To have some default options in cpt dropdown.
  useEffect(() => {
    dispatch(getPharmacyList(patientId));
    dispatch(getOrderMedicationsList(patientId));
    dispatch(getLabProvidersList());
  }, []);

  const handleCancel = () => {
    setOpen(false);
    setPastVisit(false);
  };

  const healthMedTabsArray = [
    {
      title: "Smart Summary",
      tab: <HealthSummary
        smartSummaryfeedback={smartSummaryfeedback}
        setsmartSummaryFeedback={setsmartSummaryFeedback}
      />,
      tabIcon: true,
      tabIconSrc: SmartSummaryIcon,
      left: true,
    },
    {
      title: "Medications",
      tab: <Medications
        activeMedications={activeMedications}
        inActiveMedications={inActiveMedications}
        clearMedicationsStates={clearMedicationsStates}
        removeMedicationFromtheState={removeMedicationFromtheState}
      />,
    },
  ];

  const handleCloseEndVisit = () => {
    const planNotesInMarkDownFormat = markdownContentRef.current.getPlanNotesInMarkdownFormat();
    createOrUpdate(planNotesInMarkDownFormat, "c");
  };

  const handleFeedbackStatus = (feedback) => {
    setFeedbackNote("")
    setShowFeedbackModal(false)
    setEndNoteFeedback(feedback)
    const payload = {
      "feedback": feedbackNote ?? "",
      "item_for_feedback": "smart.endnote",
      "thumbs_up": feedback,
      "user_id": userId,
      "item_id": encounterByAppointmentData?.id ?? ""
    }
    dispatch(submitFeedbackAPI(payload));
  }
  return (
    <Box>
      <CustomModal
        fullWidth
        maxWidth="md"
        title="Smart Notes Feedback"
        open={showFeedbackModal}
        setOpen={() => {
          handleFeedbackStatus(false)
        }}
        actions={[
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              text={"Cancel"}
              onClick={() => handleFeedbackStatus(false)}
              sx={{ marginRight: "10px", width: "150px" }}
              border={"1px solid #272A40"}
              backgroundColor="#FFFFFF"
              borderRadius="10px"
              color="#272A40"
              btnColor="#272A40"
            />
            <Button
              onClick={() => handleFeedbackStatus(false)}
              text={"Save"}
              sx={{ width: "150px" }}
              backgroundColor="#315FFF"
              borderRadius="10px"
              color="#fff"
              btnColor="#fff"
            />
          </Box>
        ]}
      >
        <Box paddingY={2} paddingX={2}>
          <Text variant="h3" marginY={1}>Feedback</Text>
          <InputField
            rows={4}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            flex={1}
            multiline
            name={"scd-assesment-notes"}
            labelVariant="formLabel"
            value={feedbackNote}
            onChange={(e) => setFeedbackNote(e.target.value)}
          />
        </Box>
      </CustomModal>
      <CustomModal
        open={open}
        titleIcon={<CheckCircleIcon />}
        setOpen={setOpen}
        title={"Finalize Assessment and Plan Note"}
        fullWidth
        maxWidth="lg"
        actions={[
          <Box position={"relative"}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"end"} flex={1}>
              {
                encounterByAppointmentData?.sign_close_ind !== "c" ?
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", marginRight: "15px" }}>
                      <ThumbUpIcon onClick={() => handleFeedbackStatus(true)} sx={{ color: (endNoteFeedback === "" || endNoteFeedback == undefined) ? "#ccc" : (endNoteFeedback ? "#246EFD" : "#ccc"), cursor: "pointer" }} />
                      <ThumbDownIcon onClick={() => setShowFeedbackModal(true)} sx={{ color: (endNoteFeedback === "" || endNoteFeedback == undefined) ? "#ccc" : (endNoteFeedback ? "#ccc" : "#246EFD"), marginLeft: "14px", cursor: "pointer" }} />
                    </Box>
                    <Button
                      text="Review Later"
                      backgroundColor="transparent"
                      color="#246EFD"
                      btnColor="#246EFD"
                      border={"1px solid #246EFD"}
                      disabled={isEncounterLoading}
                      borderRadius="12px"
                      sx={{ padding: "1px 20px", marginRight: "20px" }}
                      onClick={patchEncounter}
                    />
                    <Button
                      text="End Visit"
                      backgroundColor="#246EFD"
                      color="#FFFFFF"
                      btnColor="#FFFFFF"
                      borderRadius="12px"
                      sx={{ padding: "1px 20px", marginRight: "20px" }}
                      rightSide
                      isLoading={isEncounterLoading}
                      iconDetails={{ allowIcon: true, icon: rightArrow }}
                      data-testid={"encounter-end-visit-button-id"}
                      onClick={() => {
                        handleCloseEndVisit()
                      }}
                    />
                  </>
                  :
                  <Button
                    text="Close"
                    color="#ffffff"
                    backgroundColor="#1344f1"
                    btnColor="#ffffff"
                    borderRadius="10px"
                    onClick={handleCancel}
                  />
              }
            </Box>
          </Box>,
        ]}
      >
        <EndVisit
          ref={markdownContentRef}
          isPostCalled={isPostCalled}
          planMedicineData={planMedicineData}
          setPlanMedicineData={setPlanMedicineData}
          encounterByAppointmentData={encounterByAppointmentData}
        />
      </CustomModal>
      <CustomModal
        open={pastVisit}
        setOpen={setPastVisit}
        title={"Past Visit"}
        fullWidth
        maxWidth="md"
        actions={[
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              text="Close"
              color="#ffffff"
              backgroundColor="#1344f1"
              btnColor="#ffffff"
              borderRadius="10px"
              onClick={handleCancel}
            />
          </Box>,
        ]}
      >
        <PastVisitModal setOpenEndVisit={setOpen} setPastVisit={setPastVisit} />
      </CustomModal>
      <Grid container spacing={2}>
        <Grid
          item
          lg={12}
          xl={12}
          xxl={12}
          md={12}
          sm={12}
          xs={12}
        >
          <SCDHeader
            ref={scdRef}
            setIsPostCalled={setIsPostCalled}
            patientId={patientId}
            isReviewLater={encounterByAppointmentData?.sign_close_ind === "s"}
            encounterClosed={encounterByAppointmentData?.sign_close_ind === "c"}
            setStartAppointment={setStartAppointment}
            startAppointment={startAppointment}
            getSpecificPatientData={getSpecificPatientData}
            getSpecificPatientSuccess={getSpecificPatientSuccess}
            setOpen={setOpen}
            appointmentId={appointmentId}
            setPastVisit={setPastVisit}
          />
          <Box sx={{ marginTop: "23px" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                className="healthsummary_wrapper"
                sx={{ flex: 1 }}
              >
                <Box
                  sx={{ ...healthsummaryMain(), height: "100%", zIndex: 1 }}
                  data-testid="scd-health-summary-box-id"
                >
                  <HorizontalTabs childrenArray={healthMedTabsArray} />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                data-testid="scd-vital-labs-box-id"
                className="vital-lab-med-wrapper"
                sx={{ height: "100%" }}
              >
                <Box sx={{
                  ...healthsummaryMain(),
                  height: "50vh",
                }}>
                  <VitalLabMedWrapper />
                </Box>
                <Box
                  sx={{ position: "relative", marginTop: "5px", }}
                >
                  <Box
                    sx={{
                      marginTop: "15px",
                      height: "50vh",
                    }}
                    data-testid="scd-plan-box-id"
                  >
                    <Box
                      sx={{
                        ...planBoxStylingWrapper,
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={planBoxTitleStyling(viewWidth)}
                        data-testid={"assesment-and-plan-heading-id"}
                      >
                        <Text variant="h3">Assessment and Plan</Text>
                      </Box>
                      <Box sx={planBoxDescriptionStyling(viewWidth)}>
                        {startAppointment ||
                          encounterByAppointmentData?.sign_close_ind === "c" ? (
                          <>
                            <Box>
                              <Text variant="h3">Notes</Text>
                              <AssesmentPlanNotes
                                setAssesmentObj={setAssesmentObj}
                                assesmentObj={assesmentObj}
                                setEnableAutoSaveNotes={setEnableAutoSaveNotes}
                                enableAutoSaveNotes={enableAutoSaveNotes}
                              />
                            </Box>
                            <Grid container sx={{ marginTop: "10px" }}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Plan
                                  clearMedicationsStates={clearMedicationsStates}
                                  helperMedication={helperMedication}
                                  setHelperMedication={setHelperMedication}
                                  planLabsData={planLabsData}
                                  encounterId={appointmentId}
                                  createOrUpdate={createOrUpdate}
                                  setPlanLabsData={setPlanLabsData}
                                  planMedicineData={planMedicineData}
                                  callAutoEncSaver={callAutoEncSaver}
                                  setPlanMedicineData={setPlanMedicineData}
                                  createDeleteMedicine={createDeleteMedicine}
                                />
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <Box
                            sx={{
                              minHeight: "40vh",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text variant="bodyS">
                              <span
                                style={{ cursor: "pointer", color: "#246EFD" }}
                                onClick={() => {
                                  encounterByAppointmentData?.sign_close_ind === "r" ? setPastVisit(true) : scdRef.current.startRecordingProcessFunc();
                                }}
                              >
                                {encounterByAppointmentData?.sign_close_ind === "r" ? "Review past visit" : "Start the visit"}
                              </span>{" "}
                              {encounterByAppointmentData?.sign_close_ind === "r" ? "" : " to begin filling out this section."}
                            </Text>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {encounterByAppointmentData?.sign_close_ind === "c" && (
                    <div
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgb(253 250 250 / 50%)",
                        zIndex: 1000,
                        pointerEvents: "auto",
                        cursor: "not-allowed",
                      }}
                    ></div>
                  )}
                </Box>
                {/* </Box> */}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <>
          {isChatOpen === true ? (
            <>
              {viewWidth < 1200 ? (
                <ChatBotDrawer isChatOpen={true} />
              ) : (
                <ChatBotComponent isChatOpen={true} />
              )}
            </>
          ) : (
            ""
          )}
        </>
      </Grid>
    </Box>
  );
};

export default PatientDetail;
