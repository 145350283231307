import Login from "pages/Auth/Login";
import CreateNewPassword from "pages/Auth/Login/components/CreateNewPassword";
import Dashboard from "pages/Dashboard";
import PatientDetail from "pages/Dashboard/PatientDetail";
import SettingsWrapper from "pages/Dashboard/Settings/Wrapper";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import ForgotPassword from "pages/Auth/Login/components/ForgotPassword";

const Router = ({ theme }) => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/createNewPassword/:hiddenToken"
        element={<CreateNewPassword />}
      />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route
        path="/createNewPassword/:hiddenToken/:email"
        element={<CreateNewPassword />}
      />
      <Route element={<PrivateRoute theme={theme} />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/schedule" element={<Dashboard />} />
        <Route
          path="/schedule/patient/:patientId/appointment/:appointmentId"
          element={<PatientDetail />}
        />
        <Route path="/settings" element={<SettingsWrapper />} />
        <Route path="/settings?tab=:tab" element={<SettingsWrapper />} />
      </Route>
    </Routes>
  );
};

export default Router;
