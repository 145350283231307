import { createSlice } from '@reduxjs/toolkit'

const name = "Encounter"

export const initialEncounterState = {
    encounterSuccess: null,
    encounterByPatientSuccess: null,
    encounterByAppointmentSuccess: null,
    encounterMessage: '',
    encounterByPatientData: [],
    encounterByAppointmentData: {},
    isEncounterLoading: false,
    isEncounterByPatientLoading: false,
    isEncounterByAppointmentLoading: false,
}

export const EncounterSlice = createSlice({
    name,
    initialState: initialEncounterState,
    reducers: {
        encounter: (state) => {
            state.isEncounterLoading = true
        },
        encounterByPatient: (state) => {
            state.isEncounterByPatientLoading = true
        },
        encounterByAppointment: (state) => {
            state.isEncounterByAppointmentLoading = true
        },
        encounterSuccess: (state) => {
            state.encounterSuccess = true
            state.isEncounterLoading = false
            state.encounterMessage = "Encounter created successfully"
        },
        encounterByPatientSuccess: (state, action) => {
            state.isEncounterByPatientLoading = false
            state.encounterByPatientSuccess = true
            state.encounterByPatientData = action.payload?.results
        },
        encounterByAppointmentSuccess: (state, action) => {
            state.isEncounterByAppointmentLoading = false
            state.encounterByAppointmentSuccess = true
            state.encounterByAppointmentData = action.payload
        },
        encounterFail: (state) => {
            state.encounterSuccess = false
            state.isEncounterLoading = false
            state.encounterMessage = "Unable to create encounter"
        },
        encounterByPatientFail: (state) => {
            state.isEncounterByPatientLoading = false
            state.encounterByPatientSuccess = false
            state.encounterByPatientData = []
        },
        encounterByAppointmentFail: (state) => {
            state.isEncounterByAppointmentLoading = false
            state.encounterByAppointmentSuccess = false
            state.encounterByAppointmentData = {}
        },
        clearEncounterData: (state) => {
            state.encounterMessage = ""
            state.encounterSuccess = null
            state.isEncounterLoading = false;
        },
        clearEncounterByPatientData: (state) => {
            state.encounterByPatientData = []
            state.encounterByPatientSuccess = null
            state.isEncounterByPatientLoading = false
        },
        clearEncounterByAppointmentData: (state) => {
            state.encounterByAppointmentData = {}
            state.encounterByAppointmentSuccess = null
            state.isEncounterByAppointmentLoading = false
        },
    },
})

export const {
    encounter,
    clearEncounterData,
    clearEncounterByPatientData,
    clearEncounterByAppointmentData
} = EncounterSlice.actions;

export default EncounterSlice.reducer

export const createOrUpdateEncounter = (data, method, encounterId) => ({
    payload: {
        apiName: name,
        data,
        method: method,
        formData: false,
        url: method !== "POST" ? `/api/encounters/${encounterId}/` : `/api/encounters/`,
    },
    type: "encounter",
});

export const getEncounterByPatient = (patientId) => ({
    payload: {
        apiName: name,
        method: "get",
        url: `/api/patients/${patientId}/encounters/`,
    },
    type: "encounterByPatient",

})

export const getEncounterByAppointment = (appointmentId) => ({
    payload: {
        apiName: name,
        method: "get",
        url: `/api/encounters/search_by_appointment_id/?appointment_id=${appointmentId}`,
    },
    type: "encounterByAppointment",

})