import { Box, Tabs } from "@mui/material";
import {
  graphStaticApiFullFormValue,
  graphStaticColors,
  vitalGraphStaticColors,
} from "assets/static";
import CustomTabs from "components/common/Tabs/CustomTabs";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { trendsMainVitalsTabBoxStyle } from "styles/SCD/trendsStyle";
import { toCapitalize } from "utils/toCapitalize";
import { getVariance } from "utils/variance";

const VitalTrends = () => {
  // in order to keep track of active properties data
  const [data, setData] = useState([]);
  const [date, setDate] = useState([]);
  const [value, setValue] = useState(0);
  const [graphColors, setGraphColors] = useState([]);

  // in order to keep track of active properties in graph
  const [areActives, setAreActives] = useState([]);

  // in order to keep track of all active units as well as variance in graphs
  const [areActiveUnits, setAreActiveUnits] = useState([]);

  const { vitalsData } = useSelector((state) => state.vitals);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* to remove active property from graph 
  it is designed in a way that it can remove all
  properties that are given in Array form*/
  function removeFromActiveGraph(value) {
    let findIndexesOfProperties = value.map((v) => areActives.indexOf(v));
    setAreActives((oldValues) => {
      return oldValues.filter((_, i) => !findIndexesOfProperties.includes(i));
    });
    setAreActiveUnits((oldValues) => {
      return oldValues.filter((_, i) => !findIndexesOfProperties.includes(i));
    });
    setData((oldValues) => {
      return oldValues.filter((_, i) => !findIndexesOfProperties.includes(i));
    });
    setGraphColors((oldValues) => {
      return oldValues.filter((_, i) => !findIndexesOfProperties.includes(i));
    });
  }

  /* to add an inactive property to graph 
  it is designed in a way that it can add all 
  properties that are given in Array form*/
  function addToGraph(value) {
    let result = value?.map((v) =>
      vitalsData?.map((s) => Number(s?.[graphStaticApiFullFormValue?.[v]]))
    );
    setData([...data, ...result]);
    setAreActives([...areActives, ...value]);
    setGraphColors([
      ...graphColors,
      ...value?.map((v) => graphStaticColors[v]),
    ]);

    // setting units & variance according to the values provided
    setAreActiveUnits([
      ...areActiveUnits,
      ...result?.map(
        (s, i) =>
          `${toCapitalize(graphStaticApiFullFormValue?.[value?.[i]])} Variance: ${getVariance(s?.slice(-4))}`
      ),
    ]);
  }

  // to invoke addition or removal process according to condition
  function setActiveGraphs(checked, value) {
    if (vitalsData?.length > 0) {
      if (checked)
        value === "BP" ? addToGraph(["BPS", "BPD"]) : addToGraph([value]);
      else removeFromActiveGraph(value === "BP" ? ["BPS", "BPD"] : [value]);
    }
  }

  // To set the date once data is recieved or changed
  useEffect(() => {
    if (vitalsData?.length > 0) {
      addToGraph(["BPS", "BPD", "HR", "Temp"]);
      // Map the date_time values and format them using moment
      const formattedDates = vitalsData?.map((s) =>
        moment(s?.date_time).format("YYYY/MM/DD")
      );

      // Sort the formatted dates from oldest to latest
      const sortedDates = formattedDates?.sort(
        (a, b) =>
          moment(a, "YYYY/MM/DD").valueOf() - moment(b, "YYYY/MM/DD").valueOf()
      );
      setDate(sortedDates);
    }
  }, [vitalsData]);

  let graphData = {
    series: data.map((s, i) => ({ name: areActiveUnits[i], data: data?.[i] })),
    options: {
      colors: graphColors,
      chart: {
        height: 250,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: date,
      },
      yaxis: {
        min: 0,
        max: Math.max(...data?.map((s) => Math.max(...s))) + 20,
        labels: {
          formatter: function (val) {
            return Math.round(val);
          },
        },
        tickAmount: 4,
      },
    },
  };

  return (
    vitalsData?.length > 0 ?
    <Box
      data-testid="vital-trends-id"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "10px",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        sx={trendsMainVitalsTabBoxStyle}
        indicatorColor="red"
        orientation="vertical"
      >

        <CustomTabs
          label="Temp"
          defaultChecked={true}
          setActiveGraphs={setActiveGraphs}
        />
        <CustomTabs
          label="BP"
          defaultChecked={true}
          setActiveGraphs={setActiveGraphs}
        />

        <CustomTabs
          label="HR"
          defaultChecked={true}
          setActiveGraphs={setActiveGraphs}
        />
        <CustomTabs
          label="RR"
          setActiveGraphs={setActiveGraphs}
        />

        <CustomTabs
          label="WT"
          setActiveGraphs={setActiveGraphs}
        />

        <CustomTabs
          label="Spo2"
          setActiveGraphs={setActiveGraphs}
        />
      </Tabs>
      <Box data-testid="vitals-trends-id" sx={{ width: "82%" }}>
        <Box id={"chart"}>
          <ReactApexChart
            options={graphData.options}
            series={graphData.series}
            type="line"
            height={300}
          />
        </Box>
      </Box>
    </Box>: 
    (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // adjust if needed for full-height centering
          minHeight: '250px', // optional for minimum height
        }}
      >
        No data found
      </Box>
  )
  );
};
export default memo(VitalTrends);
