import { Delete } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import InputField from "components/common/FormComponents/InputField";
import { Fragment, memo, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IntakeFormContext } from "./IntakeFormProvider";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import { RadioField } from "components/common/FormComponents/RadioField";
import SelectField from "components/common/FormComponents/SelectField";
import { useTheme } from "@emotion/react";
import Button from "components/common/Button/Button";

// This is a generic sub form that can be used to create a form with a list of inputs.
export const SubForm = memo(({ subSections = [], formKey = "", dataLoading = false, data = [] }) => {
    const theme = useTheme();
    const { patientId } = useContext(IntakeFormContext);
    const { account_id } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const defaultValue = subSections.reduce((acc, section) => {
        acc[section.key] = section.type === 'select' ? section.options[0].value : "";
        return acc;
    }, {
        account_id,
        patient_id: patientId,
    });

    let [formValue, setFormValue] = useState([]);

    useEffect(() => {
        if (data.length) {
            setFormValue(data);
        }
    }, [data]);

    useEffect(() => {
        setTimeout(() => {
            // Wait for one event loop to complete before toggling the loading state.
            // This will give large forms a chance to render before the loading state is toggled off.
            setIsLoading(dataLoading);
        });
    }, [dataLoading]);

    const onDelete = (val, index) => {
        if (!val.id) {
            // If the value does not have an ID, it means it is a new value not saved to the DB and we can just remove it from the form.
            setFormValue(formValue.filter((_, i) => i !== index));
            return;
        }

        setFormValue(formValue.map((val, i) =>
            i === index ? { ...val, delete: true } : val
        ));

    }

    // Get the value of a key from the form data. key example: "name[0][key]" or "section.name"
    const getValueFromData = (key, options) => {
        const keys = key.split(/[\[\]\.]+/).filter(Boolean); // Split by both [] and . and filter out empty values
        let current = formValue;
        for (let i = 0; i < keys.length; i++) {
            const keyPart = keys[i];
            if (formKey.includes(keyPart)) continue;
            if (current[keyPart] === undefined) return "";
            current = current[keyPart];
        }

        if (options) {
            return options.find((option) => option.value === current);
        }

        return current;
    }

    return <>
        {formValue?.map((val, index) => {
            // If the value has a delete key, then we still want to render some inputs but hide them. This so the HTML form can still submit the ID and delete key.
            if (val.delete) return <Fragment key={index}>
                <input type="hidden" name={`${formKey}[${index}][id]`} value={val.id} />
                <input type="hidden" name={`${formKey}[${index}][delete]`} value={true} />
            </Fragment >;
            return <Fragment key={index}>
                <Grid container sx={{ marginBottom: index === formValue.length -1 ? "12px" : "36px" }}>
                    <Grid item xs={11}>
                        <Grid container spacing={2}>
                            {subSections.map((subSection) => {
                                const formName = `${formKey}[${index}][${subSection.key}]`;
                                const renderKey = `${subSection.key}-${index}`;
                                if (subSection.customComponent) {
                                    return subSection.customComponent({ key: renderKey, name: formName, patientId, value: getValueFromData(formName) });
                                }
                                if (subSection.radio) {
                                    return <RadioField
                                        key={renderKey}
                                        name={formName}
                                        cols={subSection.cols ?? 6}
                                        options={subSection.radioOptions}
                                        defaultValue={getValueFromData(formName)}
                                    />
                                }

                                if (subSection.type === 'select') {
                                    return <SelectField
                                        key={renderKey}
                                        name={formName}
                                        inputLabel={subSection.label}
                                        freeSolo={subSection.freeSolo}
                                        cols={subSection.cols ?? 6}
                                        options={subSection.options}
                                        disableClearable={true}
                                        value={getValueFromData(formName, subSection.options)} />
                                }

                                if (subSection.type === 'spacer') {
                                    return <Grid item xs={subSection.cols} key={renderKey} />
                                }

                                return <InputField
                                    name={formName}
                                    inputLabel={subSection.label}
                                    cols={subSection.cols ?? 6}
                                    {...(subSection.date ? { type: "date" } : {})}
                                    key={renderKey}
                                    value={getValueFromData(formName)}
                                />
                            })}
                            <input type="hidden" name={`${formKey}[${index}][id]`} value={val.id} />
                        </Grid>
                    </Grid>

                    <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <IconButton onClick={() => onDelete(val, index)}>
                            <Delete />
                        </IconButton>
                    </Grid>
                </Grid>
            </Fragment>
        })}
        <SkeletonWrapper condition={!isLoading} props={[{ width: "100%", height: "40px" }]}>
            <Button
                text="+ Add"
                btnColor={theme.palette.common.blue}
                boxShadow={"none"}
                borderRadius="10px"
                sx={{ justifyContent: "flex-start" }}
                onClick={() => setFormValue((prev) => [...prev, defaultValue])}
            />
        </SkeletonWrapper>
    </>
});