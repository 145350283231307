import { CancelRounded } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import SelectField from "components/common/FormComponents/SelectField";
import CustomModal from "components/common/Modal/CustomModal";
import SkeletonWrapper from "components/common/Skeleton/SkeletonWrapper";
import Text from "components/common/Typography/Text";
import { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearLabsTestData, deleteLabTest, getLabTests, getLabsList } from "redux/features/Labs/labsSlice";
import { clearDeletePrescription, clearMedicinesListData, deletePrescriptionsByPatientId, getMedicinesList } from "redux/features/Medications/MedicinesSlice";
import { clearOrderMedicationsData, getOrderMedicationsList } from "redux/features/Medications/OrderMedicationsSlice";
import { flexRowCenterCenter, flexWrap } from "styles";
import { SkeletonPropsStyleGenerator } from "styles/Common/SkeletonStyle";
import { DEBOUNCE_DELAY, debounce } from "utils/debouncer";
import OrderLabModal from "../OrderLabModal";
import PlanMedicationModal from "../PlanMedicationModal";
import { stripStyling } from "./AssesmentAndPlanStyle";
import { useParams } from "react-router-dom";
import { getPatientMedications } from "redux/features/Medications/MedicationsSlice";

const Plan = ({
  setPlanLabsData,
  planMedicineData,
  setPlanMedicineData,
  clearMedicationsStates,
}) => {
  let dispatch = useDispatch();
  let { patientId } = useParams();
  const {
    labsData,
    getLabTestsIsLoading,
    labsTestData,
    labsIsLoading,
    deleteLabTestSuccess
  } = useSelector((state) => state.labs);

  const medicationsAbortControllerRef = useRef(null);

  const [showLabModal, setShowLabModal] = useState(false);
  const [showMedicationModal, setShowMedicationModal] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState("");
  const [toBeEditedLab, setToBeEditedLab] = useState({});
  const [selectedMedicineData, setSelectedMedicineData] = useState([]);

  const { medicinesData, medicinesIsLoading, deletedSuccess } = useSelector((state) => state.medicines);
  const { orderMedicationsList, orderMedicationsIsLoading } = useSelector((state) => state.orderMedications);
  const { encounterByAppointmentData } = useSelector((state) => state.encounter);

  const getLabs = debounce((value) => {
    if (value?.length > 0) {
      dispatch(getLabsList(value));
    }
  }, DEBOUNCE_DELAY);

  const onLabsSelect = (e, v) => {
    if (v?.label || v?.value) {
      setShowLabModal(true);
      setToBeEditedLab(v);
    }
  };

  function onLabClickModal(val) {
    setToBeEditedLab(val);
    setShowLabModal(true);
  }

  const excludeLabs = (val) => {
    dispatch(deleteLabTest(patientId, val));
  };

  function abortMedicationsCurrentRequest() {
    if (medicationsAbortControllerRef.current) {
      medicationsAbortControllerRef.current.abort();
    }
  }

  const getMedicines = debounce((value) => {
    if (value?.length > 0) {

      // Cancel previous request if it exists
      abortMedicationsCurrentRequest();

      // Create a new AbortController
      const medicationsAbortController = new AbortController();
      medicationsAbortControllerRef.current = medicationsAbortController;

      dispatch(getMedicinesList(value, patientId, medicationsAbortController.signal));
    }
  }, DEBOUNCE_DELAY);

  const onMedicineSelect = (e, v) => {
    /* This api is designed in such a way that previous meds should be
    passed in with the new one to add a new med.*/
    if (v?.label || v?.id) {
      setSelectedPrescription(v);
      setShowMedicationModal(true)
    }
  };

  const excludeMedicine = (_, prescId) => {
    let filteredMedicineArray = selectedMedicineData.filter((s) => s?.id !== prescId);
    let filteredPlanMedicineData = planMedicineData.filter(
      (s) => s?.id !== prescId
    );
    setSelectedMedicineData(filteredMedicineArray);
    setPlanMedicineData(filteredPlanMedicineData);
    dispatch(deletePrescriptionsByPatientId(patientId, prescId))
  };

  useEffect(() => {
    deleteLabTestSuccess && dispatch(getLabTests(patientId, encounterByAppointmentData?.id));
    deleteLabTestSuccess && dispatch(clearLabsTestData());
  }, [deleteLabTestSuccess]);

  useEffect(() => {
    deletedSuccess && clearMedicationsStates();
    deletedSuccess && dispatch(getOrderMedicationsList(patientId));
    deletedSuccess && dispatch(getPatientMedications(patientId));

    // clear the redux state for delete perscriptions
    dispatch(clearDeletePrescription())
  }, [deletedSuccess])

  useEffect(() => {
    setSelectedMedicineData(orderMedicationsList || []);
    setPlanMedicineData(orderMedicationsList || []);
  }, [orderMedicationsList]);

  useEffect(() => {
    if (labsTestData?.length) {
      setPlanLabsData(labsTestData);
    }
  }, [labsTestData?.length]);

  useEffect(() => {
    return () => {
      abortMedicationsCurrentRequest();
    };
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6}>
        <Box
          data-testid="labs-medications-box-id"
          sx={{
            borderRight: "1px solid #ccc",
            padding: "10px",
            paddingLeft: "15px",
            paddingRight: "15px",
            minHeight: "225px",
          }}
        >
          <Text id={"scd-plan-label-labs"} variant="h3">
            Labs
          </Text>
          <Box
            sx={{
              marginTop: "5px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <SelectField
                cols={12}
                name="labs"
                placeholder="Search"
                border="1px solid #000"
                openOnFocus={false}
                onSearch={getLabs}
                onChange={onLabsSelect}
                loading={labsIsLoading}
                options={labsData}
              />
              <Box
                sx={{
                  backgroundColor: "#315FFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "5px",
                  cursor: "pointer",
                  width: "42px",
                  borderRadius: "5px",
                  color: "#fff",
                }}
                data-testid="lab-add-button-id"
                onClick={() => {
                  setShowLabModal(true);
                  setToBeEditedLab({ label: "", value: "" });
                }}
              >
                +
              </Box>
              <CustomModal
                fullWidth
                maxWidth="md"
                title="Order Labs"
                open={showLabModal}
                setOpen={setShowLabModal}
              >
                <OrderLabModal
                  getLabs={getLabs}
                  setOpen={setShowLabModal}
                  toBeEditedLab={toBeEditedLab}
                  setToBeEditedLab={setToBeEditedLab}
                  labsIsLoading={labsIsLoading}
                  options={labsData}
                />
              </CustomModal>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap,
                padding: "10px 0px",
                minHeight: "38px",
              }}
            >
              <SkeletonWrapper
                multipleCount={2}
                id={"patient-detail-header-age"}
                condition={!getLabTestsIsLoading}
                props={[SkeletonPropsStyleGenerator("100%", "44px", "150px")]}
              >
                <>
                  {labsTestData?.length ? (
                    labsTestData?.map((val, index) => (
                      <Box
                        key={index}
                        onClick={() => onLabClickModal(val, index)}
                        sx={{
                          backgroundColor: "#E2E2E2",
                          cursor: "pointer",
                          color: "#272A40",
                          minWidth: "100%",
                          height: "auto",
                          maxHeight: "max-content",
                          fontSize: "11px",
                          lineHeight: "13px",
                          fontWeight: "700",
                          borderRadius: "30px",
                          display: "flex",
                          padding: "10px",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "6px",
                        }}
                        data-testid={`lab-strips-box-${index}-id`}
                      >
                        {val?.name}{" "}
                        {true ? (
                          <CancelRounded
                            onClick={(e) => {
                              e.stopPropagation();
                              excludeLabs(val?.id);
                            }}
                            data-testid={`strip-lab-cancel-icon-${index}`}
                            style={{
                              width: "15px",
                              height: "15px",
                              marginLeft: "4px",
                            }}
                          />
                        ) : null}
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        ...flexRowCenterCenter,
                        width: "100%",
                        marginTop: "15px",
                      }}
                    >
                      Search labs
                    </Box>
                  )}
                </>
              </SkeletonWrapper>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box
          sx={{
            padding: "10px",
            minHeight: "225px",
          }}
        >
          <Text id={"scd-plan-label-meds"} variant="h3">
            Prescriptions
          </Text>
          <Box
            sx={{
              marginTop: "5px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <SelectField
                cols={12}
                name="medicines"
                openOnFocus={false}
                placeholder="Search"
                border="1px solid #000"
                onClear={() =>
                  medicinesData?.length
                    ? (dispatch(clearMedicinesListData()))
                    : null
                }
                onSearch={getMedicines}
                onChange={onMedicineSelect}
                extendedSearchOptions={["description"]}
                showMoreInLabel={{ show: true, value: "description" }}
                loading={medicinesIsLoading}
                options={medicinesData}
              />
              <Box
                sx={{
                  backgroundColor: "#315FFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "5px",
                  cursor: "pointer",
                  width: "42px",
                  borderRadius: "5px",
                  color: "#fff",
                }}
                data-testid={"medication-add-button-id"}
                onClick={() => {
                  setShowMedicationModal(true);
                }}
              >
                +
              </Box>
              <CustomModal
                setOpen={() => {
                  setSelectedPrescription(null);
                  setShowMedicationModal(false);
                  dispatch(clearOrderMedicationsData());
                }}
                open={showMedicationModal}
                title="Add Prescription"
                fullWidth
                maxWidth="md"
              >
                <PlanMedicationModal
                  abortMedicationsCurrentRequest={abortMedicationsCurrentRequest}
                  clearMedicationsStates={clearMedicationsStates}
                  setSelectedPrescription={setSelectedPrescription}
                  selectedPrescription={selectedPrescription}
                  setShowMedicationModal={setShowMedicationModal}
                  medicinesData={medicinesData}
                  medicinesIsLoading={medicinesIsLoading}
                  getMedicines={getMedicines}
                />
              </CustomModal>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap,
                padding: "10px 0px",
                gap: "10",
                minHeight: "38px",
              }}
            >
              {!orderMedicationsIsLoading ? (
                <>
                  {/* using planMedicineData state because it is globally used while selectedMedicineData is used within this component */}
                  {planMedicineData?.length ? (
                    planMedicineData?.map((prescription, index) => (
                      <Box
                        key={index}
                        sx={stripStyling}
                        data-testid={`medications-strips-box-${index}-id`}
                        onClick={() => onMedicineSelect("", prescription)}
                      >
                        <Box sx={{ flex: 1 }} key={index}>
                          {prescription?.drug_name}
                        </Box>
                        {true ? (
                          <CancelRounded
                            onClick={(e) => {
                              e.stopPropagation();
                              excludeMedicine(prescription?.drug_name, prescription?.id);
                            }}
                            data-testid={`strip-medications-cancel-icon-${index}`}
                            style={{
                              width: "15px",
                              height: "15px",
                              marginLeft: "4px",
                              position: "po",
                              zIndex: "1000",
                            }}
                          />
                        ) : null}
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        ...flexRowCenterCenter,
                        width: "100%",
                        marginTop: "15px",
                      }}
                    >
                      Search prescriptions
                    </Box>
                  )}
                </>
              ) : (
                <SkeletonWrapper
                  multipleCount={2}
                  condition={false}
                  props={[SkeletonPropsStyleGenerator("100%", "44px", "150px")]}
                >
                </SkeletonWrapper>
              )}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default memo(Plan);
