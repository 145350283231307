import { Divider, Grid, TextField } from "@mui/material";
import InputField from "components/common/FormComponents/InputField";
import Text from "components/common/Typography/Text";
import { memo } from "react";


export const Consent = memo(() => {
    return <>
        <Text variant="h1">Consent</Text>

        <Divider sx={{ my: 2 }} />

        <Grid container spacing={1}>
            <Grid item lg={12}>
                <TextField multiline rows={4} fullWidth label="Consent placeholder" />
            </Grid>
            <InputField inputLabel="Date" lg={12} type="date" />
            <Grid item lg={12}>
                <TextField multiline rows={4} fullWidth label="HIPAA placeholder" />
            </Grid>
            <InputField inputLabel="Date" lg={12} type="date" />
            <Grid item lg={12}>
                <TextField multiline rows={4} fullWidth label="Nephrolytics placeholder" />
            </Grid>
            <InputField inputLabel="Date" lg={12} type="date" />
        </Grid >
    </>
});